import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFetchApi, usePostApi } from './useApi';
import { URLS } from "../constants/urls";

interface UseDashboardData {
  isLoading: boolean;
  documentCount: Api.DocumentCount | undefined;
  companyData: Api.Company | undefined
}

export const useDashboard = (): UseDashboardData => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  const { data: documentCount, isLoading, mutate } = useFetchApi<
    Api.DocumentCount
  >(
    user?.workspace_id
      ? `${URLS.document_count}?workspace_id=${user?.workspace_id}`
      : undefined,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    data: companyData,
    error,
  } = useFetchApi<Api.Company>(
    user?.company_id ? `${URLS.companies}/${user?.company_id}` : undefined,
    undefined,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    isLoading,
    documentCount,
    companyData
  };
};