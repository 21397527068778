import Joi from "joi";
import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";

import { Controller, useForm } from "react-hook-form";
import {
  useFetchApi,
  usePatchApi,
  usePostApi,
} from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import { useErrorContext } from "../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../context/SuccessContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const schemaWorkspaceForm = Joi.object({
  id: Joi.optional(),
  name: Joi.string().required(),
  description: Joi.string().required(),
  type: Joi.string().required(),
  market: Joi.string().required(),
}).unknown(true);

export const WorkspaceEditForm: FC = () => {
  const { id: workspaceId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: workspaceData,
    mutate,
    isLoading: isLoadingWorkspace,
  } = useFetchApi<Api.Workspace>(
    `${URLS.workspaces}/${workspaceId}`,
    undefined,
    { revalidateOnFocus: false }
  );
  const {
    patchData,
    isLoading: isLoadingPatch,
    error,
  } = usePatchApi(`${URLS.workspaces}/${workspaceId}`);
  const { data: workspaceTypes } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.workspace_types,
    undefined,
    { revalidateOnFocus: false }
  );
  const { data: markets } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.workspace_markets,
    undefined,
    { revalidateOnFocus: false }
  );
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.Workspace>({
    mode: "onSubmit",
    defaultValues: workspaceData,
    resolver: joiResolver(schemaWorkspaceForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (workspaceData) {
      reset(workspaceData);
    }
  }, [workspaceData]);

  const onSubmit = async (data: Api.Workspace) => {
    const response = await patchData(data);
    if (response) {
      mutate();
      showSuccess("Workspace updated successfully");
      navigate('/admin/workspaces');
    }
  };

  const handleCancel = () => {
    mutate();
    navigate('/admin/workspaces');
  };

  if (isLoadingWorkspace || isLoadingPatch)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingWorkspace || isLoadingPatch}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <Box
      component="form"
      noValidate
      sx={{ mt: 1 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="name"
                  fullWidth
                  label={t('Workspace Name')}
                  autoComplete="name"
                  autoFocus
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="description"
                  fullWidth
                  label={t('Workspace Description')}
                  autoComplete="description"
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="market"
            control={control}
            defaultValue={workspaceData?.market}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  select
                  size="small"
                  id="market"
                  fullWidth
                  label={t('Market')}
                  autoComplete="market"
                  error={Boolean(errors.market)}
                  helperText={errors.market?.message}
                  {...field}
                >
                  {markets?.map((market) => {
                    return (
                      <MenuItem value={market.value} key={market.value}>
                        {market.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="type"
            control={control}
            defaultValue={workspaceData?.type}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  select
                  size="small"
                  id="type"
                  fullWidth
                  label={t('Workspace Type')}
                  autoComplete="type"
                  error={Boolean(errors.type)}
                  helperText={errors.type?.message}
                  {...field}
                >
                  {workspaceTypes?.map((type) => {
                    return (
                      <MenuItem value={type.value} key={type.value}>
                        {type.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
        <Box>
          <Button onClick={handleCancel} sx={{ mt: 1, mr: 1 }}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 1 }}
            disabled={isLoadingPatch}
          >
            {t('Update')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
