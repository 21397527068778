import React, { ReactNode } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { Layout } from "../../components/Layout";

export const PrivateRoute: React.FC<{
  component: React.ReactNode;
  hasLayout?: boolean;
  isContainer?: boolean;
}> = ({ component, hasLayout = true, isContainer = true }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Token kontrolü
  const isAuthenticated = !!localStorage.getItem("access_token");

  return searchParams.get("access_token") || isAuthenticated ? (
    hasLayout ? (
      <Layout isContainer={isContainer} children={component} />
    ) : (
      <>{component}</>
    )
  ) : (
    <Navigate
      to="/sign-in"
      replace
      state={{ from: window.location.pathname }}
    />
  );
};