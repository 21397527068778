import React, { useCallback, useEffect, useMemo } from "react";
import {
  DataGridPro,
  GridRowParams,
  GridActionsCellItem,
  GridSortItem,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useDeleteApi, useFetchApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorContext } from "../../context/ErrorContext";
import { useSuccessContext } from "../../context/SuccessContext";
import AddIcon from "@mui/icons-material/Add";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useAuth } from "../../context/AuthContext";
import { DeleteItemDialog } from "./components/DeleteItemDialog";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { AddEditFolderNameModal } from "./components/AddEditFolderNameModal";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { blue, green } from "@mui/material/colors";
import { UploadDocumentModal } from "./components/UploadDocumentModal";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useTranslation } from "react-i18next";

const PAGE_SIZE_OPTIONS = [5, 10, 15];

type FileList = {
  total_count: number;
  items: Array<Api.TemplateList>;
};

export const Templates = () => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [modifiedSortModel, setModifiedSortModel] = React.useState<
    GridSortItem[]
  >([]);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [deleteDialogText, setDeleteDialogText] = React.useState("");
  const [rowId, setRowId] = React.useState<number | undefined>();
  const [anchorElAdd, setAnchorElAdd] = React.useState<null | HTMLElement>(
    null
  );
  const [isAddModal, setIsAddModal] = React.useState(false);
  const [isUploadModal, setIsUploadModal] = React.useState(false);
  const [isBlank, setIsBlank] = React.useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();

  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { id: folderIdParam } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const openAdd = Boolean(anchorElAdd);

  const handleCloseAdd = () => {
    setAnchorElAdd(null);
  };

  const { data: rows, mutate } = useFetchApi<FileList>(
    user?.workspace_id ? URLS.document_search : undefined,
    {
      ...(!folderIdParam && { workspace_id: String(user?.workspace_id) }),
      ...(folderIdParam && { folder_id: folderIdParam ?? "" }),
      page: String(page),
      page_size: String(pageSize),
      search: searchValue,
      sort_field_list: JSON.stringify(modifiedSortModel),
      type: "template"
    },
    { revalidateOnFocus: false }
  );

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  
  const rowsMemo = useMemo(() => {
    return (rows?.items ?? []).map(row => ({
      ...row,
      status: capitalizeFirstLetter(row.status)
    }));
  }, [rows]);

  const rowsCount = useMemo(() => {
    return rows?.total_count ?? 0
  }, [rows]);

  useEffect(() => {
    if (user && (user.workspace_id || folderIdParam)) {
      mutate();
    }
  }, [user, folderIdParam]);

  const {
    deleteData: deleteItemData,
    isLoading: isLoadingDeleteItem,
    error: errorDeleteItem,
  } = useDeleteApi<any>(
    deleteDialogText === "Document" ? URLS.document : URLS.folder
  );

  useEffect(() => {
    if (errorDeleteItem) {
      showError(errorDeleteItem?.message);
    }
  }, [errorDeleteItem]);

  const fetchData = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    mutate();
  }, [page, pageSize, searchValue, modifiedSortModel])

  useEffect(() => {
    fetchData();
  }, []);

  const renameClick = (id: number) => {
    setRowId(id);
    setIsAddModal(true);
  };

  const deleteClick = (id: number, type: "document" | "folder") => {
    setDeleteDialog(true);
    type === "document"
      ? setDeleteDialogText("Document")
      : setDeleteDialogText("Folder");
    setRowId(id);
  };

  const deleteItem = async () => {
    if (rowId) {
      const response = await deleteItemData(String(rowId));

      if (response) {
        mutate();
        setDeleteDialog(false);
        setDeleteDialogText("");
        showSuccess(`${deleteDialogText} deleted successfully`);
      }
    }
  };

  const columns = React.useMemo<GridColDef<Api.TemplateList>[]>(
    () => [
      {
        field: "name",
        headerName: t('Name'),
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                params.row.type === "folder"
                  ? navigate(`/files/${params.row.id}`)
                  : navigate(`/document/${params.row.id}`);
              }}
            >
              {params.row.type === "folder" ? (
                <Avatar sx={{ bgcolor: green[300], width: 30, height: 30 }}>
                  <FolderIcon fontSize="inherit" />
                </Avatar>
              ) : (
                <Avatar sx={{ bgcolor: blue[200], width: 30, height: 30 }}>
                  <InsertDriveFileIcon fontSize="inherit" />
                </Avatar>
              )}
              <Typography variant="body1">{params.value}</Typography>
              {params.row.type === "folder" && (
                <ChevronRightIcon fontSize="small" />
              )}
            </Box>
          );
        },
      },
      {
        field: "description",
        headerName: t('Description'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "status",
        headerName: t('Status'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "updated_at",
        headerName: t('Updated On'),
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          `${
            params.row.updated_at
              ? params.row.updated_at.split("T")[0]
              : params.row.created_at.split("T")[0]
          }`,
      },
      {
        field: "created_by_name",
        headerName: t('Created By'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) =>
          params.row.type === "folder"
            ? [
                <GridActionsCellItem
                  icon={<DriveFileRenameOutlineIcon />}
                  onClick={() => renameClick(Number(params.id))}
                  label={t('Rename')}
                  showInMenu
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  onClick={() =>
                    deleteClick(Number(params.id), params.row.type)
                  }
                  label={t('Delete')}
                  showInMenu
                />,
              ]
            : [
                <GridActionsCellItem
                  icon={<DriveFileRenameOutlineIcon />}
                  onClick={() => navigate(`/document/${params.row.id}`)}
                  label={t('Edit')}
                  showInMenu
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  onClick={() =>
                    deleteClick(Number(params.id), params.row.type)
                  }
                  label={t('Delete')}
                  showInMenu
                />,
              ],
      },
    ],
    [renameClick, deleteClick]
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <TextField
            id="input-with-icon-textfield"
            label={t('Search')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2, maxWidth: 300 }}
            fullWidth
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorElAdd}
            open={openAdd}
            onClose={handleCloseAdd}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => setIsAddModal(true)}>
              <CreateNewFolderIcon sx={{ marginRight: 1 }} />
              <Typography variant="body1">{t('New Folder')}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsUploadModal(true);
                setIsBlank(true);
              }}
            >
              <PostAddIcon sx={{ marginRight: 1 }} />
              <Typography variant="body1">{t('New Document')}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsUploadModal(true);
                setIsBlank(false);
              }}
            >
              <FileUploadIcon sx={{ marginRight: 1 }} />
              <Typography variant="body1">{t('Upload Document')}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <DataGridPro
        rows={rowsMemo}
        rowCount={rowsCount}
        columns={columns}
        autoHeight
        rowSelection={false}
        initialState={{
          pagination: {
            paginationModel: { pageSize: pageSize, page: page },
          },
        }}
        paginationModel={{ pageSize: pageSize, page: page }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        paginationMode="server"
        pagination
        onSortModelChange={(model) => {
          setModifiedSortModel(model);
        }}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
      <DeleteItemDialog
        text={deleteDialogText}
        open={deleteDialog}
        setOpen={() => setDeleteDialog(false)}
        deleteItem={deleteItem}
      />
      <AddEditFolderNameModal
        open={isAddModal}
        onClose={() => setIsAddModal(false)}
        mutate={mutate}
        folderId={rowId}
      />
      <UploadDocumentModal
        open={isUploadModal}
        onClose={() => setIsUploadModal(false)}
        mutate={mutate}
        isBlank={isBlank}
      />
    </Box>
  );
};