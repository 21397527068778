import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useFetchApi, usePostApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { useErrorContext } from "../../context/ErrorContext";
import { KeyedMutator } from "swr";
import { useTranslation } from "react-i18next";

type Props = {
  setStep: (step: number) => void;
  mutate: KeyedMutator<any>;
};

const schemaWorkspaceForm = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().required(),
  type: Joi.string().required(),
  market: Joi.string().required(),
});

export const WorkspaceForm: FC<Props> = ({ setStep, mutate }) => {
  const { postData, isLoading, error } = usePostApi(URLS.workspaces);
  const { data: workspaceTypes } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.workspace_types
  );
  const { data: markets } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.workspace_markets
  );
  const { showError } = useErrorContext();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.Workspace>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
      type: "",
      market: "",
    },

    resolver: joiResolver(schemaWorkspaceForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: Api.Workspace) => {
    const response = await postData(data);
    if (response) {
      localStorage.setItem("checkedCompanyWorkspace", "true");
      mutate();
      setStep(2);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{ mt: 1 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="name"
                  fullWidth
                  label={t('Workspace Name')}
                  autoComplete="name"
                  autoFocus
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="description"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="description"
                  fullWidth
                  label={t('Workspace Description')}
                  autoComplete="description"
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="market"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  select
                  size="small"
                  id="market"
                  fullWidth
                  label={t('Market')}
                  autoComplete="market"
                  error={Boolean(errors.market)}
                  helperText={errors.market?.message}
                  {...field}
                >
                  {markets?.map((market) => {
                    return (
                      <MenuItem value={market.value} key={market.value}>
                        {market.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="type"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  select
                  size="small"
                  id="type"
                  fullWidth
                  label={t('Workspace Type')}
                  autoComplete="type"
                  error={Boolean(errors.type)}
                  helperText={errors.type?.message}
                  {...field}
                >
                  {workspaceTypes?.map((type) => {
                    return (
                      <MenuItem value={type.value} key={type.value}>
                        {type.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <div>
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 1, mr: 1 }}
            disabled={isLoading}
          >
            {t('Finish')}
          </Button>
          <Button
            onClick={() => setStep(0)}
            sx={{ mt: 1, mr: 1 }}
            disabled={isLoading}
          >
            Back
          </Button>
        </div>
      </Box>
    </Box>
  );
};
