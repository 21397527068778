import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { CompanyForm } from "./CompanyForm";
import { WorkspaceForm } from "./WorkspaceForm";
import { KeyedMutator } from "swr";
import { useTranslation } from "react-i18next";

type Props = {
  mutate: KeyedMutator<any>;
};

export const Walkthrough: React.FC<Props> = ({ mutate }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const steps = [
    {
      label: t('Create a company'),
      key: "company",
    },
    {
      label: t('Create a workspace'),
      key: "workspace",
    },
  ];

  return (
    <Container
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Box sx={{ margin: "auto" }}>
          <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
            <img
              src={require("../../images/logo.png")}
              width={160}
              height="auto"
            />
          </Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === 1 ? (
                      <Typography variant="caption">{t('Last step')}</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent sx={{ mt: theme.spacing(1) }}>
                  {activeStep === 0 ? (
                    <CompanyForm setStep={setActiveStep} />
                  ) : (
                    <WorkspaceForm setStep={setActiveStep} mutate={mutate} />
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>{t('All steps completed - you\'re finished')}</Typography>
            <Button
              onClick={() => {
                mutate();
                navigate("/dashboard");
              }}
              sx={{ mt: 1, mr: 1 }}
            >
              {t('Go to Dashboard')}
            </Button>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default Walkthrough;
