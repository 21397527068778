import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export type DocumentSettings = {
  amount: string;
  currency: string;
  termination_date: string;
  renewal_date: string;
  deletion_date: string;
  name: string | null;
  description: string | null;
  type: string | null;
  market: string | null;
  tags: string [] | null,
};

export type DocumentState = {
  status: string | undefined;
};

export type DocumentMutators = {
  setMutateDocument: (status: string) => void;
};

const documentSettings = atom<DocumentSettings>({
  key: 'documentSettings',
  default: {
    name: "",
    description: "",
    type: "",
    market: "",
    tags: null,
    amount: "",
    currency: "",
    termination_date: "",
    renewal_date: "",
    deletion_date: "",
  },
});

const documentState = atom<DocumentState>({
  key: 'documentState',
  default: { status: undefined },
});

// Hook to use document settings
export const useDocumentSettings = (): DocumentSettings => {
  return useRecoilValue(documentSettings);
};

// Hook to use document state
export const useDocumentState = (): DocumentState => {
  return useRecoilValue(documentState);
};

// Hook to use document settings mutators
export const useDocumentSettingsMutators = () => {
  const setState = useSetRecoilState(documentSettings);

  const setDocumentSettings = useCallback(
    (settings: Partial<DocumentSettings>) =>
      setState((previousState: DocumentSettings) => {
        return {
          ...previousState,
          ...settings,
        };
      }),
    [setState],
  );

  return {
    setDocumentSettings,
  };
};

// Hook to use document state mutators
export const useDocumentStateMutators = (): DocumentMutators => {
  const setState = useSetRecoilState(documentState);

  const setMutateDocument = useCallback(
    (status: string) =>
      setState((previousState: DocumentState) => {
        return {
          ...previousState,
          status,
        };
      }),
    [setState],
  );

  return {
    setMutateDocument,
  };
};
