import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import theme from "../../../theme";
import React from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { NewDocumentModal } from "./components/NewDocumentModal";
import { useTranslation } from "react-i18next";

export const CreateDocumentModal = () => {
  const [isNewDocument, setIsNewDocument] = React.useState(false);
  const [isBlank, setIsBlank] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle id="responsive-dialog-title" sx={{ textAlign: "center", position: "relative" }}>
          {t('Create a new document')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            {t('Create your custom document from scratch or upload a PDF')}
          </Typography>
          <Box
            sx={{
              mt: 2,
              mb: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Card
              sx={{
                p: 3,
                cursor: "pointer",
                width: "175px",
                "&:hover": {
                  boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                },
              }}
              elevation={2}
              onClick={() => {
                setIsBlank(true);
                setIsNewDocument(true);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <PostAddIcon fontSize="large" />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {t('Blank document')}
                </Typography>
              </Box>
            </Card>
            <Card
              sx={{
                p: 3,
                cursor: "pointer",
                width: "175px",
                backgroundColor: "#E5E4E2",
                "&:hover": {
                  boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                },
                border: "1px dashed gray",
              }}
              elevation={3}
              onClick={() => {
                setIsBlank(false);
                setIsNewDocument(true);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <UploadFileIcon fontSize="large" />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {t('Upload file')}
                </Typography>
              </Box>
            </Card>
          </Box>
          <Typography variant="caption">
            {t('To reuse a document')}
          </Typography>
        </DialogContent>
      </Dialog>
      {isNewDocument && (
        <NewDocumentModal
          onClose={() => {
            setIsNewDocument(false);
            setIsBlank(false);
          }}
          open={isNewDocument}
          isBlank={isBlank}
        />
      )}
    </>
  );
};
