import { joiResolver } from "@hookform/resolvers/joi";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFetchApi, usePatchApi } from "../../../hooks/useApi";
import { URLS } from "../../../constants/urls";
import { useErrorContext } from "../../../context/ErrorContext";
import { useSuccessContext } from "../../../context/SuccessContext";
import theme from "../../../theme";
import { ChangePasswordModal } from "./components/ChangePasswordModal";
import { useTranslation } from "react-i18next";

const schemaProfileUserForm = Joi.object({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  job_title: Joi.string().required(),
  phone_number: Joi.string().required(),
}).unknown(true);

export const Detail = () => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false);
  const {
    data: userData,
    mutate,
    isLoading: isLoadingUser,
  } = useFetchApi<Api.User>(URLS.auth, undefined, { revalidateOnFocus: false });

  const {
    patchData,
    isLoading: isLoadingPatch,
    error,
  } = usePatchApi(URLS.auth);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.ProfileUserForm>({
    mode: "onSubmit",
    defaultValues: {
      first_name: "",
      last_name: "",
      job_title: "",
      phone_number: "",
    },
    resolver: joiResolver(schemaProfileUserForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (userData) {
      reset({
        first_name: userData.first_name,
        last_name: userData.last_name,
        job_title: userData.job_title,
        phone_number: userData.phone_number,
      });
    }
  }, [userData]);

  const onSubmit = async (data: Api.ProfileUserForm) => {
    const response = await patchData(data);
    if (response) {
      mutate();
      showSuccess(t('User updated successfully'));
    }
  };

  if (isLoadingUser || isLoadingPatch)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingUser || isLoadingPatch}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <Box sx={{ mt: 3, mb: 1 }}>
      <Grid container sm={6} xs={12}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color={theme.palette.action.active}
            >
              {t('User ID')} :
            </Typography>
            <Typography variant="subtitle2" gutterBottom component="div">
              {userData?.id}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color={theme.palette.action.active}
            >
              {t('Role')} :
            </Typography>
            <Typography variant="subtitle2" gutterBottom component="div">
              {userData?.role}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 3, mt: 1 }} />
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          spacing={1}
          rowSpacing={3}
          columnSpacing={3}
          sm={6}
          xs={12}
        >
          <Grid item xs={12}>
            <Controller
              name="first_name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="first_name"
                    fullWidth
                    label={t('First Name')}
                    autoComplete="first_name"
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="last_name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="last_name"
                    fullWidth
                    label={t('Last Name')}
                    autoComplete="last_name"
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phone_number"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="phone_number"
                    fullWidth
                    label={t('Phone Number')}
                    autoComplete="phone_number"
                    error={Boolean(errors.phone_number)}
                    helperText={errors.phone_number?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="job_title"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="job_title"
                    fullWidth
                    label={t('Job Title')}
                    autoComplete="job_title"
                    error={Boolean(errors.job_title)}
                    helperText={errors.job_title?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 1, mb: 1 }}
            >
              <Box>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setIsChangePasswordModal(true)}
                >
                  {t('Change Password')}
                </Button>
              </Box>
              <Box display="flex" flexDirection="row">
                <Button sx={{ mr: 1 }} onClick={() => reset()}>
                  {t('Cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoadingPatch}
                >
                  {t('Update')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ChangePasswordModal
        open={isChangePasswordModal}
        onClose={() => setIsChangePasswordModal(false)}
        mutate={mutate}
      />
    </Box>
  );
};
