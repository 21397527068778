import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Tabs, Tab, Card } from "@mui/material";
import { Workspaces } from "./Workspaces";
import { WorkspaceDetail } from "./Workspaces/Detail";
import { AccountOverview } from "./AccountOverview";
import { useAuth } from "../../context/AuthContext";
import { useFetchApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { CompanyUsers } from "./CompanyUsers";
import { InvitedUsers } from "./InvitedUsers";
import { useTranslation } from "react-i18next";

export const Admin = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [selectedTab, setSelectedTab] = useState("/admin/account-overview");
  const { t } = useTranslation();


  const { data: userData, error } = useFetchApi<Api.User>(
    URLS.auth,
    undefined,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (pathname === "/admin") {
      navigate("/admin/account-overview");
    }
  }, [pathname, navigate]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab
          label={t('Account overview')}
          value="/admin/account-overview"
          to="/admin/account-overview"
          component={Link}
        />
        <Tab
          label={t('Workspace')}
          value="/admin/workspaces"
          to="/admin/workspaces"
          component={Link}
        />
        <Tab
          label={t('Users')}
          value="/admin/company-users"
          to="/admin/company-users"
          component={Link}
        />
        <Tab
          label={t('Invited Users')}
          value="/admin/invited-users"
          to="/admin/invited-users"
          component={Link}
        />
      </Tabs>
      <Routes>
        <Route
          path={`/account-overview`}
          element={
            <Card sx={{ p: 3, mt: 2 }} elevation={3}>
              <AccountOverview />
            </Card>
          }
        />
        <Route
          path={`/workspaces`}
          element={
            <Card sx={{ p: 3, mt: 2 }} elevation={3}>
              <Workspaces />
            </Card>
          }
        />
        <Route path={`/workspaces/:id`} element={<WorkspaceDetail />} />
        <Route
          path={`/company-users`}
          element={
            <Card sx={{ p: 3, mt: 2 }} elevation={3}>
              <CompanyUsers />
            </Card>
          }
        />
        <Route
          path={`/invited-users`}
          element={
            <Card sx={{ p: 3, mt: 2 }} elevation={3}>
              <InvitedUsers />
            </Card>
          }
        />
      </Routes>
    </>
  );
};
