import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { KeyedMutator } from "swr";
import { useErrorContext } from "../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../context/SuccessContext";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import Joi from "joi";
import { useFetchApi, usePatchApi, usePostApi } from "../../../../hooks/useApi";
import { URLS } from "../../../../constants/urls";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  mutate: KeyedMutator<any>;
  folderId?: number;
};

type FormTypes = {
  name: string;
  description: string;
};

const schemaAddEditFolderForm = Joi.object({
  description: Joi.optional(),
  name: Joi.string().required(),
}).unknown(true);

export const AddEditFolderNameModal: FC<Props> = ({
  open,
  onClose,
  mutate,
  folderId,
}) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { id: fileId } = useParams<{ id: string }>();
  const { user } = useAuth();
  const { t } = useTranslation();

  const { data: folderData } = useFetchApi<Api.FilesList>(
    folderId ? `${URLS.folder}/${folderId}` : undefined,
    undefined,
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );

  const { postData, isLoading: isLoadingPost, error } = usePostApi(URLS.folder);
  const {
    patchData,
    isLoading: isLoadingPatch,
    error: errorPatch,
  } = usePatchApi(`${URLS.folder}/${folderId}`);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: joiResolver(schemaAddEditFolderForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (errorPatch) {
      showError(errorPatch.message);
    }
  }, [errorPatch]);

  useEffect(() => {
    if (folderData) {
      reset(folderData);
    }
  }, [reset, folderData]);

  const onSubmit = async (data: FormTypes) => {
    const response = folderId
      ? await patchData({ ...data, workspace_id: user?.workspace_id })
      : await postData({
          ...data,
          workspace_id: user?.workspace_id,
          parent_folder_id: Number(fileId),
        });
    if (response) {
      reset();
      mutate();
      showSuccess(
        folderId ? t('Folder renamed successfully') : t('Folder added successfully')
      );
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {folderId ? t('Edit Folder') : t('Add Folder')}
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <Controller
              name="name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="name"
                    fullWidth
                    label={t('Folder Name')}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                </div>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    size="small"
                    id="description"
                    fullWidth
                    label={t('Folder Description')}
                    {...field}
                  />
                </div>
              )}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Box>
              <Button onClick={onClose} sx={{ mt: 1, mr: 1 }}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 1 }}
                disabled={isLoadingPost || isLoadingPatch}
              >
                {t('Add')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
