import React, { FC, useEffect } from "react";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Chip
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CollectionsBookmarkRoundedIcon from '@mui/icons-material/CollectionsBookmarkRounded';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SettingsIcon from "@mui/icons-material/Settings";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useLocation } from "react-router-dom";
import { EditDocumentInformationsModal } from "../components/EditDocumentInformationsModal";
import { useDeleteApi, usePostApi } from "../../../hooks/useApi";
import { URLS } from "../../../constants/urls";
import { useNavigate } from "react-router-dom";
import { useSuccessContext } from "../../../context/SuccessContext";
import { useErrorContext } from "../../../context/ErrorContext";
import { DeleteDocumentDialog } from "./components/DeleteDocumentDialog";
import { DuplicateDocumentDialog } from "./components/DuplicateDocumentDialog";
import { capitalizeFirstLetter } from "../../../utils";
import { useTranslation } from "react-i18next";

type Props = {
  isOpenDrawer: boolean;
  setIsOpenDrawer: (value: boolean) => void;
  documentData?: Api.Document;
  mutate: () => void;
  saveDocumentAsTemplate: () => Promise<void>;
  download: () => Promise<void>;
};

export const DocumentInformations: FC<Props> = ({
  setIsOpenDrawer,
  isOpenDrawer,
  documentData,
  mutate,
  saveDocumentAsTemplate,
  download
}) => {
  const location = useLocation();
  const { isFromCreateNewDocument } = location.state || {};
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpenEditModal, setIsOpenEditModal] = React.useState(false);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [isDuplicateModal, setIsDuplicateModal] = React.useState(false);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { showSuccess } = useSuccessContext();
  const { showError } = useErrorContext();
  const { t } = useTranslation();

  const {
    deleteData: deleteItemData,
    isLoading: isLoadingDeleteItem,
    error: errorDeleteItem,
  } = useDeleteApi<any>(URLS.document);

  const {
    postData: duplicateDocumentData,
    isLoading: isLoadingDuplicateDocumentData,
    error: errorDuplicateDocumentData,
  } = usePostApi<any>(`${URLS.document}/${documentData?.id}/duplicate`);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (errorDeleteItem || errorDuplicateDocumentData) {
      showError(
        errorDeleteItem?.message ?? errorDuplicateDocumentData?.message ?? ""
      );
    }
  }, [errorDeleteItem, errorDuplicateDocumentData]);

  const deleteDocument = async () => {
    try {
      await deleteItemData(String(documentData?.id));
      showSuccess(t('Document deleted successfully'));
      navigate("/files");
    } catch (e) {
      console.error(e);
    }
  };

  const duplicateDocument = async () => {
    try {
      await duplicateDocumentData(undefined);
      showSuccess("t('Document duplicated successfully')");
      navigate("/files");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box>
          <IconButton onClick={() => window.history.go(isFromCreateNewDocument ? -2 :-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="h6">
            {documentData?.name ?? t('Untitled Document')}
          </Typography>
          <Typography variant="caption">
            {documentData?.description ?? t('Undescriptioned Document')}
          </Typography>
        </Box>
        <Box sx={{alignSelf: 'flex-start'}}>
          <Chip icon={<DescriptionOutlinedIcon sx={{fontSize: '16px', marginLeft: '15px'}}/>} label={capitalizeFirstLetter(documentData?.status || '')} variant="outlined" />       
        </Box>
      </Box>
      <Box>
        <IconButton onClick={() => setIsOpenDrawer(!isOpenDrawer)}>
          <SettingsIcon />
        </IconButton>
        <IconButton
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => setIsOpenEditModal(true)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('Edit Informations')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setIsDuplicateModal(true)}>
            <ListItemIcon>
              <FileCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('Duplicate Document')}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => setIsDeleteModal(true)}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('Delete Document')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => saveDocumentAsTemplate()}>
            <ListItemIcon>
              <CollectionsBookmarkRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('Save as Template')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => download()}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      <EditDocumentInformationsModal
        documentData={
          documentData
            ? { name: documentData.name, description: documentData.description }
            : undefined
        }
        mutate={mutate}
        isOpen={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
      />
      <DeleteDocumentDialog
        open={isDeleteModal}
        setOpen={setIsDeleteModal}
        deleteDocument={deleteDocument}
        isDeleteButtonDisable={isLoadingDeleteItem}
      />
      <DuplicateDocumentDialog
        open={isDuplicateModal}
        setOpen={setIsDuplicateModal}
        duplicateDocument={duplicateDocument}
        isDeleteButtonDisable={isLoadingDuplicateDocumentData}
      />
    </Box>
  );
};