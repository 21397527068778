export enum URLS {
  //auth
  auth = "/wa/api/auth/me",
  sign_in = "/wa/api/auth/login",
  sign_up = "/wa/api/auth/register",
  refresh_token = "/wa/api/auth/refresh-token",
  confirmation = "/wa/api/auth/confirm",
  forgot_password = "/wa/api/auth/forgot-password",
  forgot_password_reset = "/wa/api/auth/forgot-password-reset",
  change_password = "/wa/api/auth/change-password",
  //ai-chat
  ai_chat = "/wa/api/v1/ai-chat",
  //companies
  companies = "/wa/api/v1/companies",
  companies_users = "/wa/api/v1/companies/users",
  company_user_dropwdown = "/wa/api/v1/companies/users-dropdown",
  //workspaces
  workspaces = "/wa/api/v1/workspaces",
  check_company_workspace = "/wa/api/v1/workspaces/check-company-workspace",
  workspace_types = "/wa/api/fields/workspace-types",
  workspace_markets = "/wa/api/fields/markets",
  workspace_list = "/wa/api/v1/workspaces/search",
  workspace_user_list = "/wa/api/v1/workspace-users/users",
  user_workspace_list = "/wa/api/v1/workspace-users/workspaces",
  workspace_user = "/wa/api/v1/workspace-users",
  workspace_user_mentions = "/wa/api/v1/workspace-users/mention-users",
  workspace_users_dropdown = "/wa/api/v1/workspace-users/users-dropdown",
  //fields
  user_roles = "/wa/api/fields/user-roles",
  workspace_user_roles = "/wa/api/fields/workspace-user-roles",
  document_user_sign_types = "/wa/api/fields/document-sign-types",
  document_user_roles = "/wa/api/fields/document-user-role-types",
  delivery_channel_types = "/wa/api/fields/document-delivery-channel-types",
  //users
  delete_users = "/wa/api/v1/users",
  change_role = "/wa/api/v1/users/change-role",
  //invite-users
  invite_user = "/wa/api/v1/invite-users",
  invited_user_list = "/wa/api/v1/invite-users/search",
  delete_invite_user = "/wa/api/v1/invite-users",
  //files
  file_list = "/wa/api/v1/files/search",
  //folders
  folder = "/wa/api/v1/folders",
  //documents
  document = "/wa/api/v1/documents",
  document_search = "/wa/api/v1/documents/search",
  //document-users
  document_users = "/wa/api/v1/document-users",
  document_users_dropdown = "/wa/api/v1/document-users/dropdown",
  document_users_companies = "/wa/api/v1/document-users/party-companies",
  //party-company
  party_company = "/wa/api/v1/party-companies",
  party_company_dropdown = "/wa/api/v1/party-companies/companies-dropdown",
  party_company_parties_dropdown = "/wa/api/v1/party-companies/parties-dropdown",
  //party
  party = "/wa/api/v1/parties",
  parties_dropdown = "/wa/api/v1/parties/parties-dropdown",
  //comments
  document_comments = "/wa/api/v1/document-comments",
  //mentions
  document_mentions = "/wa/api/v1/document-mentions",
  //document count
  document_count = "/wa/api/v1/dashboards/document-counts",
  document_insights = "/wa/api/v1/dashboards/document-insights",
  document_currencies = "/wa/api/fields/document-currencies",
  //contract review
  contract_review = "/wa/api/v1/contracts/review-pdf-contract",
}
