// SuccessContext.tsx dosyası
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

interface SuccessContextType {
  successMessage: string;
  showSuccess: (message: string) => void;
  clearSuccess: () => void;
}

const SuccessContext = createContext<SuccessContextType | undefined>(undefined);

export const useSuccessContext = () => {
  const context = useContext(SuccessContext);
  if (!context) {
    throw new Error("useSuccessContext must be used within an SuccessProvider");
  }
  return context;
};

export const SuccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState<string>("");

  const showSuccess = (message: string) => {
    setSuccessMessage(message);
  };

  const clearSuccess = () => {
    setSuccessMessage("");
  };

  return (
    <SuccessContext.Provider
      value={{ successMessage, showSuccess, clearSuccess }}
    >
      {children}
    </SuccessContext.Provider>
  );
};
