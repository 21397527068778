import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { FC } from "react";
import theme from "../../../../../../../theme";
import { useParams } from "react-router-dom";
import { UserBox } from "../UserBox";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

type Props = {
  rowClick: (value: boolean, id: number, documentUserId: number) => void;
  addParticipantButtonClick: (companyId: number) => void;
  rowCompanyClick: (companyId: number) => void;
  documentCompaniesList?: Api.DocumentUserCompanies[];
};

export const CounterPartyCard: FC<Props> = ({
  rowClick,
  addParticipantButtonClick,
  rowCompanyClick,
  documentCompaniesList,
}) => {
  const { id: documentId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  return (
    <>
      {documentCompaniesList?.map?.((company) => {
        return (
          <Card
            sx={{
              border: "0.5px solid #E5E4E2",
              borderRadius: 2,
              mt: 4,
            }}
            key={company.id}
            elevation={0}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    background: "#F5F5F5",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ padding: 2 }}
                    color={theme.palette.primary.main}
                  >
                    {company.name}
                  </Typography>
                  <Box sx={{ padding: 2 }}>
                    <IconButton
                      size="small"
                      onClick={() => rowCompanyClick(company.id)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>

                <Box>
                  {company.parties?.map((user) => {
                    return (
                      <UserBox
                        key={user.id}
                        id={user.id}
                        name={`${user?.first_name} ${user?.last_name}`}
                        role={user.role}
                        documentUserId={user.document_user_id}
                        setOpenModal={rowClick}
                        signOrder={user.sign_order}
                        declined_time={user.declined_time}
                        is_signed={user.is_signed}
                        signed_time={user.signed_time}
                      />
                    );
                  })}
                </Box>
              </Box>

              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    borderRadius: 2,
                    borderTopLeftRadius: "0 !important",
                    borderTopRightRadius: "0 !important",
                  }}
                  onClick={() => addParticipantButtonClick(company.id)}
                >
                  <AddIcon fontSize="small" />
                  {t('Add Participant')}
                </Button>
              </Box>
            </Box>
          </Card>
        );
      })}
    </>
  );
};
