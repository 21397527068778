import React from 'react';
import { Grid, Typography, Button, Box, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import { useTranslation } from 'react-i18next';

const HelpPage = () => {
  const { t } = useTranslation();
  return (
    <Grid sx={{marginTop: '150px'}} container direction="column" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 2,
          }}
        >
          <img src={logo} alt="logo" width={130} height="auto" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('Welcome to our AI-powered legal contract creation tool!')}
        </Typography>
        <Typography sx={{marginBottom: '40px'}} variant="body1">
          {t('This help page')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          {[
            { title: 'Getting Started', link: '/getting-started' },
            { title: 'Features', link: '/features' },
            { title: 'Pricing', link: '/pricing' },
          ].map((card, index) => (
            <Grid item xs={4} key={index}>
              <Card
                sx={{
                  minWidth: 300,
                  height: 150,
                  backgroundColor: 'white',
                  padding: 2,
                }}
              >
                <CardActionArea component={Link} to={card.link}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography variant="body1">
                      {card.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          component={Link}
          to="/dashboard"
          variant="contained"
          size="large"
          color="primary"
          sx={{ mt: 4 }}
        >
          {t('Go to Dashboard')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default HelpPage;