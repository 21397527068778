import React, { useState, useEffect } from 'react';
import { Container, Tab, Tabs, Box, TextField, IconButton, List, ListItem, ListItemText, Paper, CircularProgress, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import { styled } from '@mui/system';
import ChatsTab from './components/ChatsTab';
import CommentsTab from './components/CommentsTab';
import { useCollaboration } from '../../../../../hooks/useCollaboration';
import { useTranslation } from 'react-i18next';

const InputContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  marginTop: 'auto',
  padding: '10px 0',
  gap: '10px'
});

const MessagesContainer = styled(Box)({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '10px',
});

const SuggestionBox = styled(Paper)({
  position: 'absolute',
  top: '-100px',
  width: '100%',
  zIndex: 1,
  minHeight: '103px',
  maxHeight: '200px',
  overflowY: 'auto',
  padding: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

interface UserSuggestionsProps {
  suggestions: Array<{ id: number; name: string; email: string }>;
  onSelect: (user: { id: number; name: string; email: string }) => void;
  loading: boolean;
}

const UserSuggestions: React.FC<UserSuggestionsProps> = ({ suggestions, onSelect, loading }) => {
  const { t } = useTranslation();
  return (
    <SuggestionBox>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <List sx={{ width: '100%' }}>
          {
            suggestions.length === 0
            ?(
              <ListItem>
                <ListItemText primary={t('No results found')} />
              </ListItem>
            ) : suggestions.map((user) => (
              <ListItem button key={user.id} onClick={() => onSelect(user)}>
                <ListItemText sx={{ width: '100%' }} primary={user.name} secondary={user.email} />
              </ListItem>
            ))
          }
        </List>
      )}
    </SuggestionBox>
  );
};

const Collaboration: React.FC = () => {
  const {
    tabIndex,
    messages,
    newMessage,
    handleTabChange,
    handleNewMessageChange,
    handleSendMessage,
    handleKeyDown,
    suggestions,
    suggestionsLoading,
    handleSelectUser,
    showSuggestions
  } = useCollaboration();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
        <Typography variant="h6" sx={{mt: 2}} gutterBottom component="div">
          {t('Chats')}
        </Typography>
      <Box sx={{ height: '100%', overflowY: 'scroll', display: 'flex', justifyContent: `${messages.length === 0 ? 'center' : 'flex-start'}` }} hidden={tabIndex !== 0}>
        <MessagesContainer>
          {messages.length === 0 ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
              <ChatIcon fontSize="large" sx={{ color: "primary.main" }} />
              <Typography variant="h6" color="primary.main">{t('Start a conversation')}</Typography>
              <Typography variant="body2" color="primary.main" sx={{fontSize: '12px', textAlign: 'center'}}>{t('Ask a question or share an update instantly')}</Typography>
            </Box>
          ) : (
            <ChatsTab messages={messages} />
          )}
        </MessagesContainer>
      </Box>
      <InputContainer>
        <TextField
          fullWidth
          sx={{
            height: '100%',
            '& .MuiInputBase-input': {
              color: 'black',
            },
          }}
          size='small'
          variant="outlined"
          placeholder={`${t('Message')}...`}
          value={newMessage}
          onChange={(e) => handleNewMessageChange(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {showSuggestions && <UserSuggestions suggestions={suggestions} onSelect={handleSelectUser} loading={suggestionsLoading} />}
        <IconButton
          color="primary"
          sx={{
            border: '1px solid #c3c3c3',
            borderRadius: '4px',
            height: '40px',
            '&:hover': {
              borderColor: 'black',
            },
          }}
          onClick={handleSendMessage}
        >
          <SendIcon />
        </IconButton>
      </InputContainer>

    </Box>
  );
};

export default Collaboration;
