import React, { useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Avatar from "@mui/material/Avatar";
import { ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { Card, Divider } from "@mui/material";
import { usePostApi } from "../../hooks/useApi";
import { useErrorContext } from "../../context/ErrorContext";
import { URLS } from "../../constants/urls";
import signInBg from "../../images/signin.jpg"
import { useTranslation } from "react-i18next";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
});

export default function SignIn() {
  const navigate = useNavigate();
  const { showError } = useErrorContext();
  const { t } = useTranslation();
  const { postData: signInPost, isLoading, error } = usePostApi(URLS.sign_in);
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (error) {
      showError(error.message ?? "");
    }
  }, [error]);

  const onSubmit = useCallback(
    async (e: any) => {
      const response = await signInPost(e);

      if (response) {
        localStorage.setItem("access_token", response.access_token);
        localStorage.setItem("refresh_token", response.refresh_token);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      }
    },
    [error, signInPost, navigate]
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", backgroundImage: `url('${signInBg}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPositionX: 'left', backgroundPositionY: 'bottom' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            // backgroundColor: "primary.main",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box></Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundRepeat: "no-repeat",
            // backgroundColor: "primary.main",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Card
            sx={{
              borderRadius: 3,
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              m: 2,
            }}
          >
            <Box>
              <img
                src={require("../../images/logo.png")}
                width={130}
                height="auto"
              />
            </Box>
            <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              {t('Sign in')}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Divider sx={{ mb: 2, mt: 2 }} />
            </Box>
            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="email"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={t('Email Address')}
                      autoComplete="email"
                      autoFocus
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      {...field}
                    />
                  </div>
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      type="password"
                      id="password"
                      label={t('Password')}
                      autoComplete="password"
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      {...field}
                    />
                  </div>
                )}
              />
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "end", display: "flex" }}
                >
                  <Link href="/forgot-password" variant="body2">
                    {t('Forgot password?')}
                  </Link>
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ mt: 3, mb: 3 }}
                >
                  {t('Sign in')}
                </Button>
                <Grid item xs={12}>
                  <Divider sx={{ mb: 2 }} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "end", display: "flex" }}
                >
                  <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="outlined"
                    sx={{ mt: 3, mb: 3 }}
                    onClick={() => navigate("/register")}
                  >
                    {t('Dont have an account? Sign Up')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
