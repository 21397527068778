import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Container from "@mui/material/Container";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useFetchApi, usePostApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { useErrorContext } from "../../context/ErrorContext";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const schemaWorkspaceForm = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().required(),
  type: Joi.string().required(),
  market: Joi.string().required(),
});

export const CreateWorkspace = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { postData, isLoading, error } = usePostApi(URLS.workspaces);
  const { data: workspaceTypes } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.workspace_types
  );
  const { data: markets } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.workspace_markets
  );
  const { showError } = useErrorContext();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.Workspace>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
      type: "",
      market: "",
    },

    resolver: joiResolver(schemaWorkspaceForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: Api.Workspace) => {
    const response = await postData(data);
    if (response) {
      navigate("/admin/workspaces");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          height: "60px",
          boxShadow: "none",
          borderBottom: "0.5px solid #e0e0e0",
        }}
      >
        <Box>
          <IconButton
            onClick={() => navigate("/admin/workspaces")}
            color="inherit"
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box>
          <img src={logo} alt="logo" width={140} height="auto" />
        </Box>
        <Box></Box>
      </AppBar>
      <Container sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Box
          component="form"
          noValidate
          sx={{ mt: 5, maxWidth: 500 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={1} rowSpacing={3}>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "flex-start" }}>
              <Typography variant="h5" align="left">
                {t('Create a workspace')}
              </Typography>
              <Typography variant="caption" align="left" color="gray">
                {t('Please enter the requested information below.')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="name"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      id="name"
                      fullWidth
                      label={t('Workspace Name')}
                      autoComplete="name"
                      autoFocus
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                      {...field}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="description"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      id="description"
                      fullWidth
                      label={t('Workspace Description')}
                      autoComplete="description"
                      error={Boolean(errors.description)}
                      helperText={errors.description?.message}
                      {...field}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="market"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      select
                      id="market"
                      fullWidth
                      label={t('Market')}
                      autoComplete="market"
                      error={Boolean(errors.market)}
                      helperText={errors.market?.message}
                      {...field}
                    >
                      {markets?.map((market) => {
                        return (
                          <MenuItem value={market.value} key={market.value}>
                            {market.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="type"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      select
                      id="type"
                      fullWidth
                      label={t('Workspace Type')}
                      autoComplete="type"
                      error={Boolean(errors.type)}
                      helperText={errors.type?.message}
                      {...field}
                    >
                      {workspaceTypes?.map((type) => {
                        return (
                          <MenuItem value={type.value} key={type.value}>
                            {type.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, justifyContent: "end", display: "flex" }}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              disabled={isLoading}
            >
              {t('Create Workspace')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
