import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Avatar from "@mui/material/Avatar";
import { ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { Card, Divider } from "@mui/material";
import { usePostApi } from "../../hooks/useApi";
import { useErrorContext } from "../../context/ErrorContext";
import { URLS } from "../../constants/urls";
import PasswordRequirementsChecklist from "../../components/PasswordCheckList";
import { useSuccessContext } from "../../context/SuccessContext";
import { useTranslation } from "react-i18next";

const schema = Joi.object({
  password: Joi.string()
    .min(8)
    .max(25)
    .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "password"),
});

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();
  const {
    postData: signInPost,
    isLoading,
    error,
  } = usePostApi(URLS.forgot_password_reset);
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    defaultValues: {
      password: "",
    },
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (error) {
      showError(error.message ?? "");
    }
  }, [error]);

  const onSubmit = useCallback(
    async (e: any) => {
      const params = new URLSearchParams(window.location.search);
      const accessToken = params.get("access_token");
      const email = params.get("email");
      if (!accessToken || !email) {
        // Handle missing parameters, for example redirect to another page
        navigate("/forgot-password"); // Redirect to an error page
        return;
      }
      const data = {
        access_token: accessToken,
        email: email,
        password: e.password,
      };
      const response = await signInPost(data);

      if (response) {
        showSuccess("Password reset successfully");
        navigate("/sign-in");
      }
    },
    [error, signInPost, navigate]
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            backgroundColor: "black",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box></Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Card
            sx={{
              my: 8,
              mx: 4,
              borderRadius: 3,
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "10%",
            }}
          >
            <Box>
              <img
                src={require("../../images/logo.png")}
                width={130}
                height="auto"
              />
            </Box>
            <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              {t('Reset Password')}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Divider sx={{ mb: 2, mt: 2 }} />
            </Box>
            <Typography component="h1" variant="h6">
              {t('Please enter your new password')}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 1 }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, formState: { errors } }) => (
                      <div>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="password"
                            label={t('Password')}
                            autoComplete="password"
                            autoFocus
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                            {...field}
                          />
                        </Box>
                        {field.value && (
                          <PasswordRequirementsChecklist
                            password={field.value}
                          />
                        )}
                      </div>
                    )}
                  />

                  <Button
                    type="submit"
                    size="large"
                    disabled={isLoading}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 3 }}
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
