import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Joi from "joi";
import React, { FC, useEffect } from "react";
import { KeyedMutator } from "swr";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useErrorContext } from "../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../context/SuccessContext";
import { usePostApi } from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import PasswordRequirementsChecklist from "../../../../../components/PasswordCheckList";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  onClose: () => void;
  mutate: KeyedMutator<any>;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const schemaChangePasswordForm = Joi.object({
  old_password: Joi.string().required(),
  password: Joi.string()
    .min(8)
    .max(25)
    .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "password"),
}).unknown(true);

export const ChangePasswordModal: FC<Props> = ({ open, onClose, mutate }) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { postData, isLoading, error } = usePostApi(URLS.change_password);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.ChangePasswordForm>({
    mode: "onSubmit",
    defaultValues: {
      old_password: "",
      password: "",
    },
    resolver: joiResolver(schemaChangePasswordForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: Api.ChangePasswordForm) => {
    const response = await postData(data);
    if (response) {
      reset();
      mutate();
      showSuccess("Password changed successfully");
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('Change Password')}
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <Controller
              name="old_password"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    type="password"
                    size="small"
                    id="old_password"
                    fullWidth
                    label="Old Password"
                    autoComplete="old_password"
                    error={Boolean(errors.old_password)}
                    helperText={errors.old_password?.message}
                    {...field}
                  />
                </div>
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      size="small"
                      required
                      fullWidth
                      type="password"
                      id="password"
                      label="New Password"
                      autoComplete="password"
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      {...field}
                    />
                  </Box>
                  {field.value && (
                    <PasswordRequirementsChecklist password={field.value} />
                  )}
                </div>
              )}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Box>
              <Button onClick={onClose} sx={{ mt: 1, mr: 1 }}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 1 }}
                disabled={isLoading}
              >
                {t('Save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
