import React from "react";
import { Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ErrorPageProps {
  resetErrorBoundary: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ resetErrorBoundary }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "16px",
        textAlign: "center",
      }}
    >
      <Typography variant="h3" gutterBottom>
        {t('Oops! Something went wrong')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('An error occurred while rendering this page. Please try again later')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          resetErrorBoundary();
          navigate("/dashboard");
        }}
        style={{ marginTop: "16px" }}
      >
        {t('Go to Home')}
      </Button>
    </div>
  );
};

export default ErrorPage;
