import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";
import ProcessCard from "./ProcessCard";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Process = () => {
  const navigate = useNavigate();
 const { t } = useTranslation();
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
        <Box display="flex" flexDirection="column" alignItems="baseline">
          <Typography variant="h4" color="primary.main">Start New Process</Typography>
        </Box>
      </Box>

      <Grid container spacing={theme.spacing(3)} sx={{ mt: theme.spacing(2) }}>
        <Grid item xs={6}>
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap', gap: '15px', justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <ProcessCard
              leftLabel={t('Consult')}
              leftIcon={<QuestionAnswerOutlinedIcon sx={{ color: "white", fontSize: '30px', marginRight: '30px' }} />}
              hasRightIcon
              onClick={() => navigate('/ai-chat')}
            />
            <ProcessCard
              leftLabel={t('Review')}
              leftIcon={<ContentPasteSearchOutlinedIcon sx={{ color: "white", fontSize: '30px', marginRight: '30px' }} />}
              hasRightIcon
              onClick={() => navigate('/review-agreement')}
            />
            <ProcessCard
              leftLabel={t('Negotiate')}
              leftIcon={<InfoOutlinedIcon sx={{ color: "white", fontSize: '30px', marginRight: '30px' }} />}
              hasRightIcon={false}
              onClick={() => navigate('/document')}
            />
            <ProcessCard
              leftLabel={t('Collaborate')}
              leftIcon={<HandshakeOutlinedIcon sx={{ color: "white", fontSize: '30px', marginRight: '30px' }} />}
              hasRightIcon={false}
              onClick={() => navigate('/document')}
            />
            <ProcessCard
              leftLabel={t('E-sign')}
              leftIcon={<Groups3OutlinedIcon sx={{ color: "white", fontSize: '30px', marginRight: '30px' }} />}
              hasRightIcon={false}
              onClick={() => navigate('')}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
