import { useSuccessContext } from '../context/SuccessContext';
import { useErrorContext } from '../context/ErrorContext';
import { useFetchApi, usePostApi } from '../hooks/useApi';
import { URLS } from '../constants/urls';
import { KeyedMutator } from 'swr';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useParticipantState } from '../stores/participant';
import { useTranslation } from 'react-i18next';

type UseSignDocumentData = {
  handleSignDocument: (data: any) => Promise<void>;
  isLoading: boolean;
  error: Error | null;
  companyData: Api.Company | undefined;
};

export const useSignDocument = (mutate: KeyedMutator<any>, onClose: () => void): UseSignDocumentData => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { mutateParticipants } = useParticipantState();
  const { showSuccess } = useSuccessContext();
  const { showError } = useErrorContext();
  const { id: documentId } = useParams<{ id: string }>();

  const { data: companyData, error } = useFetchApi<Api.Company>(
    user?.company_id ? `${URLS.companies}/${ user?.company_id}` : undefined,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    postData: postDocumentSign,
    isLoading: isLoadingPost,
    error: errorDocumentSign,
  } = usePostApi(`${URLS.document}/${documentId}/sign`);

  const handleSignDocument = async (data: any) => {
    const response = await postDocumentSign(null);

    if (response) {
      showSuccess("Document signed successfully");
      mutateParticipants();
      mutate();
      onClose();
    } else {
      showError(errorDocumentSign?.message ?? t('An error occurred'));
    }
  };

  return {
    handleSignDocument,
    isLoading: isLoadingPost,
    error,
    companyData,
  };
};
