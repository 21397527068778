import Joi from "joi";
import React, { FC, useEffect } from "react";
import { KeyedMutator } from "swr";
import { useErrorContext } from "../../../../../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../../../../../context/SuccessContext";
import { useAuth } from "../../../../../../../../../context/AuthContext";
import { URLS } from "../../../../../../../../../constants/urls";
import {
  useDeleteApi,
  useFetchApi,
  usePatchApi,
  usePostApi,
} from "../../../../../../../../../hooks/useApi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  id?: number;
  participantId?: number;
  onClose: () => void;
  mutate: KeyedMutator<any>;
  documentCompaniesMutate: KeyedMutator<any>;
  documentUserId?: number;
};

const schemaAddSignDetailsParty = Joi.object({
  id: Joi.number().optional(),
  role: Joi.string().required().label("Role"),
  sign_method: Joi.string().required().label("Sign Method"),
  sign_order: Joi.number().required().label("Sign Order"),
  deliver_channel: Joi.string().required().label("Deliver Channel"),
}).unknown(true);

type FormTypes = {
  id?: number;
  role: string;
  sign_method: string;
  sign_order: number | string;
  deliver_channel: string;
};

export const AddUpdateSignDetailsForm: FC<Props> = ({
  id,
  participantId,
  onClose,
  mutate,
  documentCompaniesMutate,
  documentUserId,
}) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { id: documentId } = useParams<{ id: string }>();

  const { data: documentData } = useFetchApi<FormTypes>(
    documentUserId ? `${URLS.document_users}/${documentUserId}` : undefined,
    undefined
  );

  const {
    postData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(`${URLS.document_users}`);

  const {
    patchData,
    isLoading: isLoadingPatch,
    error: patchError,
  } = usePatchApi(`${URLS.document_users}/${documentUserId}`);

  const { data: roles, isLoading: isLoadingRoles } = useFetchApi<
    Api.GenericDropdownResponse[]
  >(URLS.document_user_roles, undefined, { revalidateOnFocus: false });
  const { data: signTypes, isLoading: isLoadingSignTypes } = useFetchApi<
    Api.GenericDropdownResponse[]
  >(URLS.document_user_sign_types, undefined, { revalidateOnFocus: false });
  const { data: deliveryChannelTypes, isLoading: isLoadingChannelTypes } =
    useFetchApi<Api.GenericDropdownResponse[]>(
      URLS.delivery_channel_types,
      undefined,
      { revalidateOnFocus: false }
    );

  const { handleSubmit, control, reset } = useForm<FormTypes>({
    defaultValues: {
      id: documentUserId,
      sign_method: "",
      sign_order: "",
      deliver_channel: "",
      role: "",
    },
    resolver: joiResolver(schemaAddSignDetailsParty),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (error || patchError)
      showError(error?.message || patchError?.message || t('An error occurred'));
  }, [error, patchError]);

  useEffect(() => {
    if (documentData) {
      reset(documentData);
    }
  }, [documentData]);

  const onSubmit = async (data: FormTypes) => {
    const newData = {
      ...(id && { id: Number(documentUserId) }),
      ...data,
      party_id: participantId,
      document_id: documentId,
    };

    const response = await (documentUserId ? patchData : postData)(newData);

    if (response) {
      reset();
      showSuccess(
        !id
          ? t('Sign details added successfully')
          : t('Sign details updated successfully')
      );
      mutate();
      documentCompaniesMutate();
      onClose();
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ mb: 2 }}
      >
        {id ? t('Update Sign Details') : "{t('Add Sign Details')}"}
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="role"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    select
                    size="small"
                    id="role"
                    fullWidth
                    label={t('Role')}
                    autoComplete="role"
                    error={Boolean(errors.role)}
                    helperText={errors.role?.message}
                    {...field}
                  >
                    {roles?.map((role) => {
                      return (
                        <MenuItem value={role.value} key={role.value}>
                          {role.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="sign_method"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    select
                    size="small"
                    id="sign_method"
                    fullWidth
                    label={t('Sign Method')}
                    autoComplete="sign_method"
                    error={Boolean(errors.sign_method)}
                    helperText={errors.sign_method?.message}
                    {...field}
                  >
                    {signTypes?.map((signType) => {
                      return (
                        <MenuItem value={signType.value} key={signType.value}>
                          {signType.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="deliver_channel"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    select
                    size="small"
                    id="deliver_channel"
                    fullWidth
                    label={t('Delivery Channel')}
                    autoComplete="deliver_channel"
                    error={Boolean(errors.deliver_channel)}
                    helperText={errors.deliver_channel?.message}
                    {...field}
                  >
                    {deliveryChannelTypes?.map((channelType) => {
                      return (
                        <MenuItem
                          value={channelType.value}
                          key={channelType.value}
                        >
                          {channelType.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="sign_order"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    {...field}
                    size="small"
                    id="sign_order"
                    fullWidth
                    type="number"
                    label={t('Sign Order')}
                    InputLabelProps={{
                      shrink: field?.value ? true : false,
                    }}
                  />
                </div>
              )}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: id ? "space-between" : "end",
            alignItems: "center",
          }}
        >
          <Box>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1 }}
              disabled={isLoadingPost || isLoadingPatch}
            >
              {id ? t('Update Sign Details') : t('Finish')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
