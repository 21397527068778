import { useState } from "react";
import useSWR, { SWRConfiguration, mutate } from "swr";
import { URLS } from "../constants/urls";
import { useParams, useSearchParams } from "react-router-dom";

interface FetcherOptions {
  method?: string;
  body?: any;
  headers?: Record<string, string>;
}

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";
const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

const fetcher = async (url: string, options: FetcherOptions = {}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const externalAccessToken = urlParams.get("access_token");

  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  const response = await fetch(`${API_URL}${url}`, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      ...(externalAccessToken && {
        "X-External-Access-Token": externalAccessToken,
      }),
    },
  });

  if (response.status === 401) {
    await handle401Error(url, options);
  }

  if (!response.ok) {
    const errorMessage = await response.json();
    throw new Error(errorMessage.error_message);
  }

  if (response.status === 201) {
    return response.json();
  }

  if (response.status === 201 || response.status === 202) {
    return true;
  }

  return response.json();
};

const handle401Error = async (url: string, options: FetcherOptions) => {
  await refreshToken();

  const refreshedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (refreshedToken) {
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${refreshedToken}`,
      },
    });
  } else {
    redirectToSignIn();
  }
};

const refreshToken = async () => {
  const refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY);
  const response = await fetch(`${API_URL}${URLS.refresh_token}`, {
    method: "POST",
    body: JSON.stringify({ refresh_token }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    },
  });

  if (!response.ok) {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem("checkedCompanyWorkspace");
    redirectToSignIn();
    return;
  }

  const data = await response.json();
  localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token);
};

const redirectToSignIn = () => {
  window.location.href = "/sign-in";
};

const useFetchApi = <T>(
  url?: string,
  queryParameters?: Record<string, string>,
  swrConfigs?: SWRConfiguration
) => {
  const { data, error, mutate } = useSWR<T>(
    url ? url : null,
    (url) =>
      fetcher(
        queryParameters
          ? `${url}?${new URLSearchParams(queryParameters).toString()} `
          : url
      ),
    swrConfigs
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

const usePostApi = <T>(url: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const postData = async (postData: any) => {
    setIsLoading(true);
    try {
      const response = await fetcher(url, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      mutate(url);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
  };

  const postFormData = async (postData: any) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      Object.keys(postData).forEach((key) => {
        formData.append(key, postData[key]);
      });
      const response = await fetcher(url, {
        method: "POST",
        body: formData,
      });
      mutate(url);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
  };

  return { postData, isLoading, error, postFormData };
};

const useDeleteApi = <T>(url: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const deleteData = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await fetcher(`${url}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      mutate(url);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
  };

  return { deleteData, isLoading, error };
};

const usePatchApi = <T>(url: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const patchData = async (patchData: any) => {
    setIsLoading(true);
    try {
      const response = await fetcher(url, {
        method: "PATCH",
        body: JSON.stringify(patchData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      mutate(url);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
  };

  const patchFormData = async (postData: any) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      Object.keys(postData).forEach((key) => {
        formData.append(key, postData[key]);
      });
      const response = await fetcher(url, {
        method: "PATCH",
        body: formData,
      });
      mutate(url);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
  };

  return { patchData, isLoading, error, patchFormData };
};

export { useFetchApi, usePostApi, useDeleteApi, usePatchApi };