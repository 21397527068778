import React from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';

interface DashboardCardProps {
    leftLabel: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    documentCount: {
        amount: number;
        count: number;
        currency: string | null
    } | undefined;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ leftLabel, leftIcon, rightIcon, documentCount }) => {
    return (
        <Card>
            <CardContent>
                <Grid container alignItems="center" style={{ marginTop: 8 }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography sx={{marginRight: '10px'}} variant="h3" color="#22272A">{documentCount?.count ?? 0}</Typography>
                    </Box>
                </Grid>
                <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" color="#434E54" sx={{marginRight: '5px'}}>
                            {leftLabel}
                        </Typography>
                        <Box sx={{marginTop: '-5px'}}>{leftIcon}</Box>
                    </Box>
                    <Box textAlign="right" sx={{verticalAlign: 'middle'}}>
                    <Typography variant="body2" color="#434E54">
                        {`${documentCount?.currency ?? 'USD'} ${documentCount?.amount ?? 0}`}
                    </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

export default DashboardCard;