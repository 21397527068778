import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  Badge,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import chatAgreemind from "../../images/logo.png";
import theme from "../../theme";
import styled from "@emotion/styled";
import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { Editor } from "@tinymce/tinymce-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveIcon from "@mui/icons-material/Save";
import ChatIcon from "@mui/icons-material/Chat";
import AssistantIcon from "@mui/icons-material/Assistant";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { usePostApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { useErrorContext } from "../../context/ErrorContext";
import { PDFDocumentProxy } from 'pdfjs-dist';
import { CreateDocumentModal } from "./CreateDocumentModal";
import { NewDocumentModal } from "./CreateDocumentModal/components/NewDocumentModal";
import { jsPDF } from 'jspdf';
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";

var mammoth = require("mammoth/mammoth.browser");

export const ReviewAgreements = () => {
  const [file, setFile] = useState<File | undefined>();
  const [editorHtml, setEditorHtml] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoadingPdfConverter, setIsLoadingPdfConverter] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isEditorChanged, setIsEditorChanged] = React.useState<boolean>(true);
  const [isNewDocument, setIsNewDocument] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const editorRef = useRef<any>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { showError } = useErrorContext();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [chatMessages, setChatMessages] = useState([
    {
      message: t('Hello, I am Maya. How can i help you?'),
      sender: "ChatGPT",
      direction: "incoming",
      position: "single",
    },
  ]);

  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState(false);

  const { postFormData: postContractReview, isLoading: isLoadingContractReview, error: errorContractReview } =
    usePostApi(`${URLS.contract_review}`);

  const { postData: postAiChat, isLoading, error } = usePostApi(URLS.ai_chat);

  const convertDocxToHtml = async (file: File): Promise<string> => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.convertToHtml({ arrayBuffer });
    return result.value;
  };

  const onFileUpload = useCallback((file: File) => {
    setIsLoadingPdfConverter(true);
    const reader = new FileReader();
  
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = async () => {
      if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const htmlContent = await convertDocxToHtml(file);
        setEditorHtml(htmlContent);
      } else {
        console.log("Unsupported file type");
      }
  
      setFile(file);
      setIsLoadingPdfConverter(false);
    };
    reader.readAsArrayBuffer(file);
  }, []);

  // const onFileUpload = useCallback((file: File) => {
  //   setIsLoadingPdfConverter(true);
  //   const reader = new FileReader();

  //   reader.onabort = () => console.log("file reading was aborted");
  //   reader.onerror = () => console.log("file reading has failed");
  //   reader.onload = async () => {
  //     const pdfJS = await import("pdfjs-dist");

  //     pdfJS.GlobalWorkerOptions.workerSrc =
  //       window.location.origin + "/pdf.worker.min.mjs";

  //     const typedarray = new Uint8Array(reader.result as ArrayBuffer);
  //     const pdf = await pdfJS.getDocument({ data: typedarray }).promise;
  //     const htmlContent = await convertPdfToHtml(pdf);
  //     setEditorHtml(htmlContent);

  //     setFile(file);
  //     setIsLoadingPdfConverter(false);
  //   };
  //   reader.readAsArrayBuffer(file);
  // }, []);

  const handleDownloadDocx = async () => {
    const htmlContent = editorRef.current.getContent();
  
    // Convert HTML to plain text
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;
    const textContent = tempElement.innerText;

    const doc = new Document({
      sections: [{
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun(textContent),
            ],
          }),
        ],
      }],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, 'document.docx');
  };
  
  const reviewContract = useCallback(async (pdfFile: File) => {
    if (pdfFile) {
      const formData = new FormData();
      formData.append('pdf', pdfFile);
      const res = await postContractReview({file: pdfFile});
      setSuggestions(res?.suggestions);
    }
  }, [postContractReview]);

  useEffect(() => {
    if(file) {
      generatePdf(editorRef.current.getContent());
    }
  }, [file])

  useEffect(() => {
    if (error) {
      showError(error?.message ?? "");
    }
  }, [error]);

  const filteredSuggestions = useMemo(() => {
    return suggestions
      .filter(suggestion => suggestion.trim().length > 0)
      .map(suggestion => {
        const match = suggestion.match(/\$(.*?)\$/);
        const title = match ? match[1] : "No Title";
        const content = suggestion.replace(/\$(.*?)\$/g, '');
        return { title, content };
      });
  }, [suggestions]);

  const convertPdfToHtml = async (pdf: PDFDocumentProxy): Promise<string> => {
    let html = '';
  
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber);
      const textContent = await page.getTextContent();
      const viewport = page.getViewport({ scale: 1.0 });
  
      const pageHtml = textContent.items.map(item => {
        if ('str' in item) {
          const transform = item.transform || [1, 0, 0, 1, 0, 0];
          const fontSize = Math.sqrt(transform[0] ** 2 + transform[1] ** 2);
          const left = transform[4];
          const top = transform[5] - fontSize;
  
          return `<span style="position: absolute; left: ${left}px; top: ${viewport.height - top}px; font-size: ${fontSize}px;">${item.str}</span>`;
        }
        return '';
      }).join('');
  
      html += `<div style="position: relative; width: ${viewport.width}px; height: ${viewport.height}px; overflow: scroll">${pageHtml}</div>`;
    }
  
    setIsLoadingPdfConverter(false);
    return html;
  };

  const generatePdf = (text: string) => {
    const doc = new jsPDF();
    doc.text(text, 10, 10);

    const pdfBlob = doc.output('blob');

    const pdfFile = new File([pdfBlob], 'sample.pdf', { type: 'application/pdf' });

    reviewContract(pdfFile);
  };

  const handleUserMessage = async (userMessage: string) => {
    // Get the chat history
    var chatHistory = [];
    for (const element of chatMessages) {
      if (element.sender === "user") {
        chatHistory.push({ role: "user", message: element.message });
      } else if (element.sender === "ChatGPT") {
        chatHistory.push({ role: "assistant", message: element.message });
      }
    }

    const sendData = { 
      message: userMessage + editorRef.current.getContent(),
      chat_history: chatHistory,
    };
    // Create a new user message object
    const newUserMessage = {
      message: userMessage,
      sender: "user",
      direction: "outgoing",
      position: "single",
    };

    // Update chat messages state with the new user message
    const updatedChatMessages = [...chatMessages, newUserMessage];
    setChatMessages(updatedChatMessages);

    const response = await postAiChat(sendData);
    if (response) {
      setChatMessages([
        ...updatedChatMessages,
        {
          message: response.reply,
          sender: "ChatGPT",
          direction: "incoming",
          position: "single",
        },
      ]);
    }
  };

  useEffect(() => {
    const message = 'Error: Generating a response failed </br><br/>Oops! It seems that something went wrong while attempting to generate a response. This could be due to a variety of reasons, such as technical issues, unexpected input, or a temporary problem with the system.<br/><br/>Please try your request again in a moment, ensuring the your input is clear and concise. If the issue persists, you may want to check your internet connection or contact our support team for further assistance.'
    if(error) setChatMessages([...chatMessages, {
      message: message,
      sender: "ChatGPT",
      direction: "incoming",
      position: "single",
    }, ])
  }, [error])

  if (isLoadingPdfConverter) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingPdfConverter}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        px: theme.spacing(1),
      }}
    >
      {
        !file && <CreateDocumentModal onFileUpload={onFileUpload}/>
      }
      {
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              overflow: "hidden",
              p: theme.spacing(2),
              width: "100%",
            }}
          >
            <Box
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ChatIcon />}
                  onClick={() => setIsChatDrawerOpen(true)}
                >
                  {t('Ask a Question')}
                </Button>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<MenuIcon />}
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {t('Actions')}
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={() => setIsNewDocument(true)}>
                    <ListItemIcon>
                      <SaveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('Save')}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleDownloadDocx}>
                    <ListItemIcon>
                      <PictureAsPdfIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('Download as DOCX')}</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>

            <Box sx={{ mt: theme.spacing(2), height: '100%' }}>
              <StyledEditor>
                <Editor
                  apiKey={process.env.REACT_APP_TINY_LICENSE_KEY}
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={editorHtml ?? ""}
                  onEditorChange={(newValue, editor) => {
                    if(editorHtml !== newValue) {
                      setIsEditorChanged(false);
                    } else {
                      setIsEditorChanged(true);
                    }
                  }}
                  init={{
                    width: "100%",
                    height: "100%",
                    plugins:
                      "preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker editimage help formatpainter permanentpen pageembed charmap mentions quickbars linkchecker emoticons advtable footnotes mergetags autocorrect typography advtemplate markdown export",
                    tinydrive_token_provider: "URL_TO_YOUR_TOKEN_PROVIDER",
                    tinydrive_dropbox_app_key: "YOUR_DROPBOX_APP_KEY",
                    tinydrive_google_drive_key: "YOUR_GOOGLE_DRIVE_KEY",
                    tinydrive_google_drive_client_id:
                      "YOUR_GOOGLE_DRIVE_CLIENT_ID",
                    mobile: {
                      plugins:
                        "preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable footnotes mergetags autocorrect typography advtemplate export",
                    },        
                    menubar:
                      "file edit view insert format tools table tc help",
                    toolbar:
                      "undo redo  | aidialog aishortcuts | blocks fontsizeinput | bold italic | align numlist bullist | link image | table media pageembed | lineheight  outdent indent | strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | code fullscreen preview | save print | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | ltr rtl casechange | spellcheckdialog a11ycheck | export ", // Note: if a toolbar item requires a plugin, the item will not present in the toolbar if the plugin is not also loaded.
                    autosave_ask_before_unload: true,
                    autosave_interval: "30s",
                    autosave_prefix: "{path}{query}-{id}-",
                    autosave_restore_when_empty: false,
                    autosave_retention: "2m",
                    image_advtab: true,
                    typography_rules: [
                      "common/punctuation/quote",
                      "en-US/dash/main",
                      "common/nbsp/afterParagraphMark",
                      "common/nbsp/afterSectionMark",
                      "common/nbsp/afterShortWord",
                      "common/nbsp/beforeShortLastNumber",
                      "common/nbsp/beforeShortLastWord",
                      "common/nbsp/dpi",
                      "common/punctuation/apostrophe",
                      "common/space/delBeforePunctuation",
                      "common/space/afterComma",
                      "common/space/afterColon",
                      "common/space/afterExclamationMark",
                      "common/space/afterQuestionMark",
                      "common/space/afterSemicolon",
                      "common/space/beforeBracket",
                      "common/space/bracket",
                      "common/space/delBeforeDot",
                      "common/space/squareBracket",
                      "common/number/mathSigns",
                      "common/number/times",
                      "common/number/fraction",
                      "common/symbols/arrow",
                      "common/symbols/cf",
                      "common/symbols/copy",
                      "common/punctuation/delDoublePunctuation",
                      "common/punctuation/hellip",
                    ],
                    typography_ignore: ["code"],
                    advtemplate_list: () => {
                      return Promise.resolve([
                        {
                          id: "1",
                          title: "Resolving tickets",
                          content:
                            "<p>As we have not heard back from you in over a week, we have gone ahead and resolved your ticket.</p>",
                        },
                        {
                          id: "2",
                          title: "Quick replies",
                          items: [
                            {
                              id: "3",
                              title: "Message received",
                              content:
                                "<p>Just a quick note to say we have received your message, and will get back to you within 48 hours.</p>",
                            },
                            {
                              id: "4",
                              title: "Progress update",
                              content:
                                "</p>Just a quick note to let you know we are still working on your case</p>",
                            },
                          ],
                        },
                      ]);
                    },
                    link_list: [
                      { title: "My page 1", value: "https://www.tiny.cloud" },
                      {
                        title: "My page 2",
                        value: "http://www.moxiecode.com",
                      },
                    ],
                    image_list: [
                      { title: "My page 1", value: "https://www.tiny.cloud" },
                      {
                        title: "My page 2",
                        value: "http://www.moxiecode.com",
                      },
                    ],
                    image_class_list: [
                      { title: "None", value: "" },
                      { title: "Some class", value: "class-name" },
                    ],
                    importcss_append: true,
                    image_caption: true,
                    quickbars_selection_toolbar:
                      "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                    noneditable_class: "mceNonEditable",
                    toolbar_mode: "sliding",
                    spellchecker_ignore_list: [
                      "Ephox",
                      "Moxiecode",
                      "tinymce",
                      "TinyMCE",
                    ],
                    contextmenu:
                      "link image editimage table configurepermanentpen",
                    a11y_advanced_options: true,
                    skin: "snow",
                    icons: "thin",
                    content_style: "body { margin: 2rem 10%; }",
                    mentions_selector: "span.mymention",
                    autocorrect_capitalize: true,
                    mergetags_list: [
                      {
                        title: "Client",
                        menu: [
                          {
                            value: "Client.LastCallDate",
                            title: "Call date",
                          },
                          {
                            value: "Client.Name",
                            title: "Client name",
                          },
                        ],
                      },
                      {
                        title: "Proposal",
                        menu: [
                          {
                            value: "Proposal.SubmissionDate",
                            title: "Submission date",
                          },
                        ],
                      },
                      {
                        value: "Consultant",
                        title: "Consultant",
                      },
                      {
                        value: "Salutation",
                        title: "Salutation",
                      },
                    ],
                  }}
                />
              </StyledEditor>
            </Box>
          </Box>

          <Divider orientation="vertical" />
          <Box p={theme.spacing(2)} sx={{ width: "25%"}}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: 2,
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="primary">
                  {t('Suggestions')}
                </Typography>
                <Badge badgeContent={filteredSuggestions.length} color="primary" sx={{ ml: 1 }}>
                  <AssistantIcon />
                </Badge>
              </Box>
              <Box sx={{ height: 'calc(100% - 100px)', overflow: 'scroll' }}>
              {!isLoadingContractReview ? (
                filteredSuggestions.length !== 0 ? (
                  filteredSuggestions.map((suggestion, index) => (
                    <Accordion
                      key={index}
                      sx={{
                        margin: "0 0 10px 0 !important",
                        borderRadius: "5px",
                        '&::before': {
                          display: 'none',
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography variant="body1" color="primary.main">{suggestion.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2">
                          {suggestion.content}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
                    {t('No suggestions available')}
                  </Typography>
                )
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress/>
                </Box>
              )}
            </Box>
            <Box>
              <Button 
                variant="contained"
                color="primary"
                disabled={isEditorChanged}
                onClick={() => {
                    setIsEditorChanged(true);
                    generatePdf(editorRef.current.getContent())
                  }}
                fullWidth
              >
                {t('Review')}
              </Button>
            </Box>
          </Box>
         
          <Drawer
            anchor="right"
            open={isChatDrawerOpen}
            onClose={() => setIsChatDrawerOpen(false)}
            sx={{
              "& .MuiDrawer-paper": { width: "500px !important" },
              zIndex: 2000,
            }}
          >
            <ChatContainer style={{ overflow: "auto" }}>
              <ConversationHeader>
                <Avatar 
                    src={chatAgreemind}
                    name="Agreemind"
                    style={{
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      objectFit: 'contain'
                    }}
                  >
                    <img
                      src={chatAgreemind}
                      alt="Agreemind"
                      style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    />
                  </Avatar>
                <ConversationHeader.Content userName="Emily" info="Active" />
              </ConversationHeader>

              <MessageList
                typingIndicator={
                  isLoading ? <TypingIndicator content="Maya is typing" /> : null
                }
              >
                {chatMessages.map((message, i) => {
                  return (
                    <Message
                      key={i}
                      model={message as any}
                      style={
                        message.sender === "ChatGPT"
                          ? { textAlign: "left" }
                          : {}
                      }
                    >
                      {message.sender === "ChatGPT" ? (
                        <Avatar 
                          src={chatAgreemind}
                          name="Agreemind"
                          style={{
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            objectFit: 'contain'
                          }}
                        >
                          <img
                            src={chatAgreemind}
                            alt="Agreemind"
                            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                          />
                        </Avatar>
                      ) : (
                        ""
                      )}
                    </Message>
                  );
                })}
              </MessageList>
              <MessageInput attachButton={false} onSend={handleUserMessage} />
            </ChatContainer>
          </Drawer>
          {isNewDocument && (
            <NewDocumentModal
              onClose={() => {
                setIsNewDocument(false);
              }}
              open={isNewDocument}
              document={editorRef.current.getContent()}
            />
          )}
        </>
      }
    </Box>
  );
};

const StyledEditor = styled("div")({
  height: "100%",
  "& .tox-tinymce": {
    border: "0.5px solid lightgray !important",
    borderRadius: "5px !important",
    padding: "7px",
    background: "rgb(243, 243, 245)",
  },
  "& .tox-toolbar__primary": {
    justifyContent: "space-between",
  },
  "& .tox-sidebar-wrap": {
    width: "80%",
    background: "white",
    margin: "10px auto",
    borderRadius: "5px",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.15)",
  },
  "& .tox .tox-content": {
    border: "none",
    width: "75%",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "10px",
    "&:focus": {
      borderRadius: "5px",
    },
    "& p": {
      margin: "12px 0",
      lineHeight: "1.5",
    }
  },
  "& .tox .tox-focused": { borderRadius: "5px !important" },
});

const StyledFileUploader = styled("div")({
  width: "50%",
  height: "50%",
  maxHeight: "%100",
  maxWidth: "%100 !important",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: 'auto',
  borderStyle: "dashed",
  borderColor: "lightgray",
  boxShadow: "none",
  borderRadius: "5px",
  backgroundColor: "rgb(243, 243, 245)",
});