import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useFetchApi,
  usePatchApi,
  usePostApi,
} from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useErrorContext } from "../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../context/SuccessContext";
import { useTranslation } from "react-i18next";

type Props = {
  mutate: () => void;
  open: boolean;
  onClose: () => void;
  userId: number;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const schemaWorkspaceUserForm = Joi.object({
  role: Joi.string().required(),
}).unknown(true);

export const ChangeRoleUserDialog: FC<Props> = ({
  mutate,
  open,
  onClose,
  userId,
}) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();
  const { data: userRoles } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.user_roles,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    postData,
    isLoading: isLoadingPatch,
    error,
  } = usePostApi(URLS.change_role);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      role: "",
    },
    resolver: joiResolver(schemaWorkspaceUserForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: { role: string }) => {
    const response = await postData({ ...data, user_id: userId });
    if (response) {
      reset();
      mutate();
      showSuccess(t('User role changed successfully'));
      onClose();
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('Change Role')}
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="caption"
          sx={{ mt: 2 }}
        >
          Change Role to this user.
          {t('Change Role to this user')}

        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <Controller
              name="role"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    select
                    size="small"
                    id="role"
                    fullWidth
                    label={t('Role')}
                    autoComplete="role"
                    error={Boolean(errors.role)}
                    helperText={errors.role?.message}
                    {...field}
                  >
                    {userRoles?.map((role) => {
                      return (
                        <MenuItem value={role.value} key={role.value}>
                          {role.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Box>
              <Button onClick={onClose} sx={{ mt: 1, mr: 1 }}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 1 }}
                disabled={isLoadingPatch}
              >
                Change
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
