import React, { FC, useEffect, useMemo } from "react";
import { KeyedMutator } from "swr";
import { useErrorContext } from "../../../../../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../../../../../context/SuccessContext";
import { useAuth } from "../../../../../../../../../context/AuthContext";
import { URLS } from "../../../../../../../../../constants/urls";
import {
  useDeleteApi,
  useFetchApi,
  usePatchApi,
  usePostApi,
} from "../../../../../../../../../hooks/useApi";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { countries } from "../../../../../../../../../constants/countries";
import DeleteIcon from "@mui/icons-material/Delete";
import { MuiTelInput } from "mui-tel-input";
import { useTranslation } from "react-i18next";

type Props = {
  setParticipantId: (id: number) => void;
  setStep?: (step: number) => void;
  id?: number;
  partyCompanyId?: number;
  onClose?: () => void;
  mutate?: KeyedMutator<any>;
  documentCompaniesMutate?: KeyedMutator<any>;
  documentUserId?: number;
};

const schemaAddParty = Joi.object({
  id: Joi.number().optional(),
  first_name: Joi.string().required().label("First Name"),
  last_name: Joi.string().required().label("Last Name"),
  phone_number: Joi.string()
    .required()
    .regex(
      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "phone_number"
    ),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  job_title: Joi.string().optional().label("Job Title"),
  personal_identification: Joi.string()
    .optional()
    .label("Personal Identification"),
  country: Joi.string().optional().label("Country"),
}).unknown(true);

type FormTypes = {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  job_title: string;
  personal_identification: string;
  country: string;
};

export const AddUpdateParticipantForm: FC<Props> = ({
  setParticipantId,
  setStep,
  id,
  partyCompanyId,
  documentUserId,
  onClose,
  mutate,
  documentCompaniesMutate,
}) => {
  const [selectedParty, setSelectedParty] = React.useState<any>(null);

  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { user } = useAuth();

  const { data: participantData } = useFetchApi<FormTypes>(
    id || selectedParty
      ? `${URLS.party}/${id ? id : selectedParty?.value}`
      : undefined,
    undefined
  );

  const { data: addressBookCompanyParties } = useFetchApi<
    { id: number; name: string }[]
  >(
    partyCompanyId
      ? `${URLS.party_company_parties_dropdown}?party_company_id=${partyCompanyId}`
      : `${URLS.parties_dropdown}?workspace_id=${user?.workspace_id}`,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    postData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(`${URLS.party}`);

  const {
    patchData,
    isLoading: isLoadingPatch,
    error: patchError,
  } = usePatchApi(`${URLS.party}/${id}`);

  const {
    deleteData: deleteItemData,
    isLoading: isLoadingDeleteItem,
    error: errorDeleteItem,
  } = useDeleteApi<any>(URLS.document_users);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: {
      id: id,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      job_title: "",
      personal_identification: "",
      country: "Albania",
    },
    resolver: joiResolver(schemaAddParty),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (error || patchError || errorDeleteItem)
      showError(
        error?.message ||
          patchError?.message ||
          errorDeleteItem?.message ||
          t('An error occurred')
      );
  }, [error, patchError, errorDeleteItem]);

  useEffect(() => {
    if (participantData) {
      reset(participantData);
      setValue("country", participantData.country);
    }
  }, [participantData]);

  const onSubmit = async (data: FormTypes) => {
    if (!selectedParty) {
      const newData = {
        ...(id && { id: Number(data.id) }),
        ...(partyCompanyId && { party_company_id: Number(partyCompanyId) }),
        ...data,
        workspace_id: user?.workspace_id,
      };

      const response = await (id ? patchData : postData)(newData);

      if (response) {
        reset();
        showSuccess(
          !id
            ? t('Participant added successfully')
            : t('Participant updated successfully')
        );

        setStep?.(2);
        setParticipantId(response.id);
        mutate?.();
        documentCompaniesMutate?.();
      }
    } else {
      setParticipantId(selectedParty.value);
      setStep?.(2);
      mutate?.();
      documentCompaniesMutate?.();
    }
  };

  const deleteItem = async () => {
    const response = await deleteItemData(String(documentUserId));
    if (response) {
      onClose?.();
      mutate?.();
      documentCompaniesMutate?.();
      showSuccess(t('Participant deleted successfully'));
    }
  };

  const defaultCountryValue = countries.find(
    (item) => item.label === participantData?.country
  );

  return (
    <Box sx={{ mt: 3 }}>
      {!id && (
        <Box sx={{ mb: 1 }}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={
              addressBookCompanyParties?.map((item) => {
                return { label: item.name, value: item.id };
              }) ?? []
            }
            onChange={(e, newValue) => setSelectedParty(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Select a participant')}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Box>
      )}
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ mb: 2 }}
      >
        {id ? t('Update Participant') : t('Add Participant')}
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item xs={6}>
            <Controller
              name="first_name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="first_name"
                    label={t('First Name')}
                    autoComplete="first_name"
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="last_name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="last_name"
                    label={t('Last Name')}
                    autoComplete="last_name"
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="job_title"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="job_title"
                    label={t('Job Title')}
                    autoComplete="job_title"
                    error={Boolean(errors.job_title)}
                    helperText={errors.job_title?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phone_number"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <MuiTelInput
                    {...field}
                    margin="normal"
                    required
                    defaultCountry="SE"
                    fullWidth
                    size="small"
                    error={Boolean(errors.phone_number)}
                    helperText={errors.phone_number?.message}
                    onChange={(e) => {
                      field.onChange(e.replace(/\s/g, ""));
                    }}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="email"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    id="email"
                    label={t('Email Address')}
                    autoComplete="email"
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="personal_identification"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                    id="personal_identification"
                    label={t('Personal ID')}
                    autoComplete="personal_identification"
                    error={Boolean(errors.personal_identification)}
                    helperText={errors.personal_identification?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="country"
              control={control}
              defaultValue={defaultCountryValue?.label ?? ""}
              render={({ field, formState: { errors } }) => (
                <div>
                  <Autocomplete
                    id="country-select-demo"
                    sx={{ marginTop: "16px" }}
                    options={countries}
                    defaultValue={null}
                    autoHighlight
                    fullWidth
                    inputValue={field.value ?? defaultCountryValue?.label}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, data) => {
                      field.onChange(data?.label);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label={t('Choose a country')}
                        id="country"
                        name="country"
                        fullWidth
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        error={Boolean(errors.country)}
                        helperText={errors.country?.message}
                      />
                    )}
                  />
                </div>
              )}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: id ? "space-between" : "end",
            alignItems: "center",
          }}
        >
          {id && (
            <Box>
              <Button onClick={deleteItem} variant="outlined" color="error">
                <DeleteIcon />
                {t('Remove Participant')}
              </Button>
            </Box>
          )}
          <Box>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1 }}
              disabled={isLoadingPost || isLoadingPatch}
            >
              {id ? t('Update Participant') : t('Next')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
