import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Avatar from "@mui/material/Avatar";
import { ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { Card, Divider } from "@mui/material";
import { usePostApi } from "../../hooks/useApi";
import { useErrorContext } from "../../context/ErrorContext";
import { URLS } from "../../constants/urls";
import signInBg from "../../images/signin.jpg"
import { useSuccessContext } from "../../context/SuccessContext";
import { useTranslation } from "react-i18next";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

export const ForgotPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const navigate = useNavigate();
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();
  const {
    postData: signInPost,
    isLoading,
    error,
  } = usePostApi(URLS.forgot_password);
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (error) {
      showError(error.message ?? "");
    }
  }, [error]);

  const onSubmit = useCallback(
    async (e: any) => {
      const response = await signInPost(e);

      if (response) {
        showSuccess(t('Link sent to your email. Please check your email.'));
        setIsEmailSent(true);
      }
    },
    [error, signInPost, navigate]
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", backgroundImage: `url('${signInBg}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPositionX: 'left', backgroundPositionY: 'bottom' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            // backgroundColor: "black",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box></Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundRepeat: "no-repeat",
            // backgroundColor: "black",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Card
            sx={{
              my: 8,
              mx: 4,
              borderRadius: 3,
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "10%",
            }}
          >
            <Box>
              <img
                src={require("../../images/logo.png")}
                width={130}
                height="auto"
              />
            </Box>
            <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              {t('Forgot Password')}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Divider sx={{ mb: 2, mt: 2 }} />
            </Box>
            <Typography component="body" variant="body2">
              {isEmailSent
                ? t('An email has been sent to your email address. Please check your email')
                : t('Please enter your email address to reset your password.')}
            </Typography>
            {!isEmailSent && (
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component="form"
                    noValidate
                    sx={{ mt: 1 }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <div>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('Email Address')}
                            autoComplete="email"
                            autoFocus
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            {...field}
                          />
                        </div>
                      )}
                    />

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        size="large"
                        disabled={isLoading}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 3 }}
                      >
                        {t('Reset')}
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
