import React from "react";
import { Typography, Button, Theme, Box } from "@mui/material";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: theme.spacing(10),
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          marginBottom: theme.spacing(2),
        }}
      >
        <img src={logo} alt="logo" width={160} height="auto" />
      </Box>
      <Typography variant="h1" sx={{ mb: theme.spacing(4) }}>
        404 - {t('Page Not Found')}
      </Typography>
      <Typography variant="body1">
        {t('The page you are looking')}
      </Typography>
      <Button
        component={Link}
        to="/dashboard"
        variant="contained"
        size="large"
        color="primary"
        sx={{ mt: theme.spacing(4) }}
      >
        {t('Go to Home')}
      </Button>
    </div>
  );
};

export default NotFoundPage;
