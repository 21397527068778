import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./containers/App";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./containers/ErrorBoundary";
import { ErrorProvider } from "./context/ErrorContext";
import { SuccessProvider } from "./context/SuccessContext";
import ErrorSnackbar from "./components/ErrorSnackbar";
import SuccessSnackbar from "./components/SuccessSnackbar";
import { AuthProvider } from "./context/AuthContext";
import { GlobalStyles } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RecoilEnv, RecoilRoot } from 'recoil';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';


const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalStyles
          styles={{
            "@keyframes mui-auto-fill": { from: { display: "block" } },
            "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
          }}
        />
        {/* CssBaseline kickstarts an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ErrorBoundary>
          <RecoilRoot>
            <ErrorProvider>
              <SuccessProvider>
                <ErrorSnackbar />
                <SuccessSnackbar />
                <AuthProvider>
                  <I18nextProvider i18n={i18n}>
                    <App />
                  </I18nextProvider>,
                </AuthProvider>
              </SuccessProvider>
            </ErrorProvider>
          </RecoilRoot>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  </BrowserRouter>
);
