import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import i18n from 'i18next';

export type Language = 'en' | 'tr';

const LOCAL_STORAGE_KEY = 'appLanguage';

const getInitialLanguage = (): Language => {
  const storedLanguage = localStorage.getItem(LOCAL_STORAGE_KEY);
  return (storedLanguage === 'en' || storedLanguage === 'tr') ? storedLanguage : 'en';
};

const languageState = atom<Language>({
  key: 'languageState',
  default: getInitialLanguage(),
});

export const useLanguage = (): Language => {
  return useRecoilValue(languageState);
};

export const useSetLanguage = (): ((language: Language) => void) => {
  const setState = useSetRecoilState(languageState);

  return useCallback((language: Language) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, language);
    setState(language);
    i18n.changeLanguage(language); // Update the language in i18next
  }, [setState]);
};
