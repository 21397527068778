import { Backdrop, Box, Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import React, { useEffect, useRef, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetchApi, usePatchApi, usePostApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { DocumentInformations } from "./DocumentInformations";
import { Editor } from "@tinymce/tinymce-react";
import styled from "@emotion/styled";
import { DocumentSettingsTabs } from "./DocumentSettingsTabs";
import { CreateDocumentModal } from "./CreateDocumentModal";
import { useSuccessContext } from "../../context/SuccessContext";
import { useErrorContext } from "../../context/ErrorContext";
import { useAuth } from "../../context/AuthContext";
import SendIcon from "@mui/icons-material/Send";
import { Send } from "@mui/icons-material";
import { SendToSignModal } from "./DocumentSettingsTabs/Tabs/ParticipantSettings/components/SendToSignModal";
import { SignDocumentModal } from "./SignDocumentModal";
import { useParticipantState } from "../../stores/participant";
import { useDocumentStateMutators, useDocumentSettingsMutators } from "../../stores/document";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";

var mammoth = require("mammoth/mammoth.browser");

export const DocumentDetail = () => {
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [openSendtoSignModal, setOpenSendtoSignModal] = React.useState(false);
  const [openSignModal, setOpenSignModal] = React.useState(false);
  const [docHtml, setDocHtml] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isEditorChanged, setIsEditorChanged] = React.useState<boolean>(true);
  const [mentionsQueryParams, setMentionsQueryParams] =
    React.useState<string>("");

  const { mutateParticipants } = useParticipantState();
  const { setMutateDocument } = useDocumentStateMutators();
  const { setDocumentSettings } = useDocumentSettingsMutators();
  const { showSuccess } = useSuccessContext();
  const { showError } = useErrorContext();
  const { user, isExternalUser } = useAuth();
  const editorRef = useRef<any>(null);
  const { id: documentId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const buttonText = t('Save Changes');
  const isTextOverflowing = buttonText.length > 20;

  const {
    data: documentData,
    mutate,
    error: documentCommentsError,
  } = useFetchApi<Api.Document>(
    documentId ? `${URLS.document}/${documentId}` : undefined,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    postFormData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(`${URLS.document}`);

  const { data: documentComments, mutate: mutateDocumentComments } =
    useFetchApi<Api.DocumentComments[]>(
      documentId
        ? `${URLS.document_comments}?document_id=${documentId}`
        : undefined,
      undefined,
      { revalidateOnFocus: false }
    );

  const { data: documentUsersList, mutate: mutateDocumentUserList } = useFetchApi<Api.DocumentUser[]>(
    `${URLS.document_users}?document_id=${documentId}`
  );

  const isReadonly = useMemo(() => {
    if(!documentData?.status) return false;
    return documentData?.status === 'signed';
  }, [documentData])

  const hasSigned = useMemo(() => {
    return documentUsersList?.some(participant => participant.is_signed);
  }, [documentUsersList]);

  const hasUserSigned = useMemo(() => {
    return documentUsersList?.some(
      (docUser) => docUser.participant_detail.id === user?.id && docUser.is_signed
    );
  }, [documentUsersList, user]);

  const hasUserDeclined = useMemo(() => {
    return documentUsersList?.some(
      (docUser) => docUser.participant_detail.id === user?.id && docUser.is_declined
    );
  }, [documentUsersList, user]);

  const userRole = useMemo(() => {
    if(!documentUsersList && !user) return null;
    const documentUserRole = documentUsersList?.find(role => role.user_id === user?.id);
    return documentUserRole ? documentUserRole.role : undefined;
  }, [documentUsersList, user])

  const { data: mentionUsers, mutate: mutateMentions } = useFetchApi<
    Api.Mentions[]
  >(
    user?.workspace_id
      ? `${URLS.workspace_user_mentions}?workspace_id=${user.workspace_id}&${mentionsQueryParams}`
      : undefined,
    undefined,
    { revalidateOnFocus: false }
  );

  const { data: documentUrlData } = useFetchApi<{ public_url: string }>(
    `${URLS.document}/${documentId}/public-data`,
    undefined,
    { revalidateOnFocus: false }
  );

  const { postData: postDocumentComment, error: errorDocumentComment } =
    usePostApi(`${URLS.document_comments}`);

  const { postData: postDocumentMention, error: errorDocumentMention } =
    usePostApi(`${URLS.document_mentions}`);

  const { postData: postDocumentDecline, isLoading: isLoadingDecline, error: errorDocumentDecline } =
  usePostApi(`${URLS.document}/${documentId}/decline`);

  const { patchFormData, error: errorPatch } = usePatchApi(
    `${URLS.document}/${documentData?.id}/data`
  );

  const saveDocumentAsTemplate = useCallback(async () => {
    if(editorRef.current.getContent()) {
      const blob = new Blob([editorRef.current.getContent()], { type: "text/plain;charset=" + "UTF-8" });

      var reader = new FileReader();
    reader.onload = async (evt) => {
      const newFile = new File(
        [evt?.target?.result as any],
        `${documentData?.name}`,
        {
          type: "text/html",
        }
      );

      const response = await postFormData({
        name: documentData?.name,
        description: documentData?.description,
        workspace_id: user?.workspace_id,
        type: "template",
        file: newFile,
      });
      if (response) {
        showSuccess(t('Document successfully saved as template'));
      }
    };
    reader.readAsArrayBuffer(blob);
  
      
    } else {
      showError("t('Document is empty')");
    }

  }, [documentData, postFormData]);

  const readBlobAsText = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(new Error('Error reading blob as text'));
      reader.readAsText(blob);
    });
  };

  const handleDownloadDocx = async (text: string) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;
    const textContent = tempElement.innerText;

    const doc = new Document({
      sections: [{
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun(textContent),
            ],
          }),
        ],
      }],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${documentData?.name || 'agreemind'}.docx`);
  };

  const downloadFileCompletion = async () => {
    if (
      documentData?.content_type &&
      (documentData.content_type.includes("pdf") ||
        documentData.content_type.includes("document") || 
        documentData.content_type.includes("text/html"))
    ) {
      try {
        const response = await fetch(documentUrlData?.public_url ?? "");

        if (response.ok) {
          const blob = await response.blob();
          if (documentData.content_type.includes("text/html")) {
            const text = await readBlobAsText(blob);
            handleDownloadDocx(text);
          } else {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            const publicUrl = documentUrlData?.public_url;
            if (publicUrl) {
              const urlParts = publicUrl.split('?')[0].split('/');
              const fileName = documentData.content_type.includes("pdf") ? `${documentData.name}.pdf` : urlParts[urlParts.length - 1];
              link.setAttribute('download', fileName);
            } else {
              throw new Error(t('Document public URL is undefined'));
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } else {
          throw new Error(t('Failed to fetch document'));
        }
      } catch (error) {
        console.error("Error downloading document:", error);
      }
    }
  };

  const handleDeclineDocument = async() => {
    const res = await postDocumentDecline(null);
    if(res) mutate();
  };

  useEffect(() => {
    if(documentData?.status) return;
    setMutateDocument(documentData?.status || '');
  }, [documentData, setMutateDocument])

  useEffect(() => {
    if(documentData) {
      setDocumentSettings({
        name: documentData.name,
        description: documentData.description,
        type: documentData.type,
        market: documentData.market,
        tags: documentData.tags,
        amount: documentData.amount || '',
        currency: documentData.currency || '',
        termination_date: documentData.termination_date || '',
        renewal_date: documentData.renewal_date || '',
        deletion_date: documentData.deletion_date || '',
      })
    }
  }, [documentData]);


  useEffect(() => {
    if (documentData?.content_type?.includes("document")) {
      const fetchDocxFile = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(documentUrlData?.public_url ?? "");

          if (response) {
            const blob = await response.blob();

            const reader = new FileReader();
            reader.onload = async () => {
              const arrayBuffer = reader.result;
              const result = await mammoth.convertToHtml({ arrayBuffer });

              setDocHtml(result.value);
            };
            reader.readAsArrayBuffer(blob);
            setIsLoading(false);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchDocxFile();
    }
  }, [documentData, documentUrlData]);

  useEffect(() => {
    if (errorPatch) {
      showError(errorPatch.message);
    }
  }, [errorPatch]);

  useEffect(() => {
    if (
      documentData?.content_type?.includes("text/html") &&
      documentUrlData?.public_url
    ) {
      setIsLoading(true);
      fetch(documentUrlData?.public_url ?? "")
        .then((res) => res.text())
        .then(async (text) => {
          setDocHtml(text);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  }, [documentData, documentUrlData]);

  const stringToArrayBuffer = async (value: string) => {
    var blob = new Blob([value], { type: "text/plain;charset=" + "UTF-8" });
    var reader = new FileReader();
    reader.onload = async (evt) => {
      const newFile = new File(
        [evt?.target?.result as any],
        `${documentData?.name}`,
        {
          type: "text/html",
        }
      );

      const response = await patchFormData({
        file: newFile,
      });
      if (response) {
        mutate();
        mutateParticipants();
        if(documentData?.status === 'pending' && hasSigned) showSuccess(t('Document updated successfully and signatories are removed'));
        else showSuccess(t('Document updated successfully'));
      }
    };
    reader.readAsArrayBuffer(blob);
  };

  if (isLoading || !user?.first_name) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            flexShrink: 0,
            height: "4.5rem",
            padding: "0px 1rem",
            backgroundColor: "rgb(255, 255, 255)",
            flexDirection: "row",
            mt: 1,
            mb: 1,
          }}
        >
          <DocumentInformations
            isOpenDrawer={openDrawer}
            setIsOpenDrawer={setOpenDrawer}
            documentData={documentData}
            mutate={mutate}
            saveDocumentAsTemplate={saveDocumentAsTemplate}
            download={downloadFileCompletion}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 0%",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          {documentData?.content_type?.includes("pdf") ? (
            <iframe
              src={documentUrlData?.public_url}
              width="100%"
              height="100%"
              style={{ border: "none" }}
            />
          ) : (
            <>
              {user?.first_name && (
                <EditorStyled>
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_LICENSE_KEY}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    initialValue={docHtml ?? ""}
                    disabled={isReadonly || isExternalUser}
                    onEditorChange={(newValue, editor) => {
                      if(docHtml !== newValue) {
                        setIsEditorChanged(false);
                      } else {
                        setIsEditorChanged(true);
                      }
                    }}
                    init={{
                      width: "100%",
                      height: "100%",
                      plugins:
                        "preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker editimage help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable footnotes mergetags autocorrect typography advtemplate markdown export",
                      tinydrive_token_provider: "URL_TO_YOUR_TOKEN_PROVIDER",
                      tinydrive_dropbox_app_key: "YOUR_DROPBOX_APP_KEY",
                      tinydrive_google_drive_key: "YOUR_GOOGLE_DRIVE_KEY",
                      tinydrive_google_drive_client_id:
                        "YOUR_GOOGLE_DRIVE_CLIENT_ID",
                      mobile: {
                        plugins:
                          "preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable footnotes mergetags autocorrect typography advtemplate export",
                      },
                      menu: {
                        tc: {
                          title: "Comments",
                          items:
                            "addcomment showcomments deleteallconversations",
                        },
                      },
                      menubar:
                        "file edit view insert format tools table tc help",
                      toolbar:
                        "undo redo  | aidialog aishortcuts | blocks fontsizeinput | bold italic | align numlist bullist | link image | table media pageembed | lineheight  outdent indent | strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | code fullscreen preview | save print | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck | export ", // Note: if a toolbar item requires a plugin, the item will not present in the toolbar if the plugin is not also loaded.
                      autosave_ask_before_unload: true,
                      autosave_interval: "30s",
                      autosave_prefix: "{path}{query}-{id}-",
                      autosave_restore_when_empty: false,
                      autosave_retention: "2m",
                      image_advtab: true,
                      typography_rules: [
                        "common/punctuation/quote",
                        "en-US/dash/main",
                        "common/nbsp/afterParagraphMark",
                        "common/nbsp/afterSectionMark",
                        "common/nbsp/afterShortWord",
                        "common/nbsp/beforeShortLastNumber",
                        "common/nbsp/beforeShortLastWord",
                        "common/nbsp/dpi",
                        "common/punctuation/apostrophe",
                        "common/space/delBeforePunctuation",
                        "common/space/afterComma",
                        "common/space/afterColon",
                        "common/space/afterExclamationMark",
                        "common/space/afterQuestionMark",
                        "common/space/afterSemicolon",
                        "common/space/beforeBracket",
                        "common/space/bracket",
                        "common/space/delBeforeDot",
                        "common/space/squareBracket",
                        "common/number/mathSigns",
                        "common/number/times",
                        "common/number/fraction",
                        "common/symbols/arrow",
                        "common/symbols/cf",
                        "common/symbols/copy",
                        "common/punctuation/delDoublePunctuation",
                        "common/punctuation/hellip",
                      ],
                      typography_ignore: ["code"],
                      advtemplate_list: () => {
                        return Promise.resolve([
                          {
                            id: "1",
                            title: "Resolving tickets",
                            content:
                              "<p>As we have not heard back from you in over a week, we have gone ahead and resolved your ticket.</p>",
                          },
                          {
                            id: "2",
                            title: "Quick replies",
                            items: [
                              {
                                id: "3",
                                title: "Message received",
                                content:
                                  "<p>Just a quick note to say we have received your message, and will get back to you within 48 hours.</p>",
                              },
                              {
                                id: "4",
                                title: "Progress update",
                                content:
                                  "</p>Just a quick note to let you know we are still working on your case</p>",
                              },
                            ],
                          },
                        ]);
                      },
                      link_list: [
                        { title: "My page 1", value: "https://www.tiny.cloud" },
                        {
                          title: "My page 2",
                          value: "http://www.moxiecode.com",
                        },
                      ],
                      image_list: [
                        { title: "My page 1", value: "https://www.tiny.cloud" },
                        {
                          title: "My page 2",
                          value: "http://www.moxiecode.com",
                        },
                      ],
                      image_class_list: [
                        { title: "None", value: "" },
                        { title: "Some class", value: "class-name" },
                      ],
                      importcss_append: true,
                      image_caption: true,
                      quickbars_selection_toolbar:
                        "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                      noneditable_class: "mceNonEditable",
                      toolbar_mode: "sliding",
                      spellchecker_ignore_list: [
                        "Ephox",
                        "Moxiecode",
                        "tinymce",
                        "TinyMCE",
                      ],
                      tinycomments_mode: "embedded", //callback
                      // tinycomments_create: async (
                      //   req: any,
                      //   done: any,
                      //   fail: any
                      // ) => {
                      //   const { content, createdAt } = req;
                      //   const userId = 2;

                      //   const response = await postDocumentComment({
                      //     user_id: userId,
                      //     comment: content,
                      //     document_id: documentId,
                      //   });

                      //   if (response) {
                      //     done({ conversationUid: documentId });
                      //     showSuccess("Comment added successfully");
                      //   } else {
                      //     fail(errorDocumentComment?.message);
                      //     showError(
                      //       errorDocumentComment?.message ?? "An error occurred"
                      //     );
                      //   }
                      // },
                      // tinycomments_reply: (req: any, done: any, fail: any) => {
                      //   const { content, createdAt } = req;

                      //   console.log(req, done, fail, "reply");
                      // },
                      // tinycomments_edit_comment: (
                      //   req: any,
                      //   done: any,
                      //   fail: any
                      // ) => {
                      //   const { content, createdAt } = req;

                      //   console.log(req, done, fail, "edit_comment");
                      // },
                      // tinycomments_delete: (req: any, done: any, fail: any) => {
                      //   const { content, createdAt } = req;

                      //   console.log(req, done, fail, "delete");
                      // },
                      // tinycomments_delete_all: (
                      //   req: any,
                      //   done: any,
                      //   fail: any
                      // ) => {
                      //   const { content, createdAt } = req;

                      //   console.log(req, done, fail, "delete_all");
                      // },
                      // tinycomments_delete_comment: (
                      //   req: any,
                      //   done: any,
                      //   fail: any
                      // ) => {
                      //   const { content, createdAt } = req;

                      //   console.log(req, done, fail, "delete_comment");
                      // },
                      // tinycomments_lookup: (req: any, done: any, fail: any) => {
                      //   const lookup = async () => {
                      //     await mutateDocumentComments();
                      //     if (documentCommentsError) {
                      //       showError(documentCommentsError?.message ?? "");
                      //     }

                      //     return {
                      //       conversation: {
                      //         uid: documentId,
                      //         comments: documentComments?.map(
                      //           (documentComment) => ({
                      //             ...documentComment,
                      //             content: documentComment.comment,
                      //             authorName: `${documentComment?.participant_detail?.first_name} ${documentComment?.participant_detail?.last_name}`,
                      //           })
                      //         ),
                      //       },
                      //     };
                      //   };

                      //   lookup()
                      //     .then((data) => {
                      //       console.log("Lookup success " + documentId, data);
                      //       done(data);
                      //     })
                      //     .catch((err) => {
                      //       console.error("Lookup failure " + documentId, err);
                      //       fail(err);
                      //     });
                      // },
                      tinycomments_author_name: `${user?.first_name} ${user?.last_name}`,
                      contextmenu:
                        "link image editimage table configurepermanentpen",
                      a11y_advanced_options: true,
                      skin: "snow",
                      icons: "thin",
                      content_style: "body { margin: 2rem 10%; }",
                      mentions_selector: "span.mymention",
                      mentions_fetch: (query: any, success: any) => {
                        setMentionsQueryParams(`search=${query.term}`);

                        if (mentionUsers) {
                          success(
                            mentionUsers.map((item) => {
                              return { id: String(item.id), name: item.name };
                            })
                          );
                        }
                      },
                      // mentions_menu_hover: (userInfo: any, success: any) => {
                      //   console.log(userInfo.id);
                      // },
                      mentions_menu_complete: async (
                        editor: any,
                        userInfo: any
                      ) => {
                        console.log(userInfo.id, "userInfo");

                        const span = editor.getDoc().createElement("span");
                        span.className = "mymention";
                        // store the user id in the mention so it can be identified later
                        span.setAttribute("data-mention-id", userInfo.id);
                        span.appendChild(
                          editor.getDoc().createTextNode("@" + userInfo.name)
                        );

                        const response = await postDocumentMention({
                          document_id: documentId,
                          message: "",
                          user_id: userInfo.id,
                        });

                        if (response) {
                          showSuccess("Mention added successfully");
                        } else {
                          showError(
                            errorDocumentMention?.message ?? t('An error occurred')
                          );
                        }

                        return span;
                      },

                      autocorrect_capitalize: true,
                      mergetags_list: [
                        {
                          title: "Client",
                          menu: [
                            {
                              value: "Client.LastCallDate",
                              title: "Call date",
                            },
                            {
                              value: "Client.Name",
                              title: "Client name",
                            },
                          ],
                        },
                        {
                          title: "Proposal",
                          menu: [
                            {
                              value: "Proposal.SubmissionDate",
                              title: "Submission date",
                            },
                          ],
                        },
                        {
                          value: "Consultant",
                          title: "Consultant",
                        },
                        {
                          value: "Salutation",
                          title: "Salutation",
                        },
                      ],
                    }}
                  />
                </EditorStyled>
              )}
            </>
          )}
        </Box>
      </Box>
      <Drawer
        variant="permanent"
        open={openDrawer}
        sx={{
          height: "100vh",
          boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              flex: "1 1",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              borderBottom: "2px solid #d6dfe2",
              mb: 2,
            }}
          >
            <DocumentSettingsTabs />
          </Box>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          > 
            {
              ((documentData?.status === 'draft' || documentData?.status === 'pending') && userRole === 'influencer') && 
              <Tooltip title={t('Save Changes')} arrow>
                <Button
                  variant="contained"
                  sx={{
                    mr: 1,
                    fontSize: '13px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  fullWidth
                  size="large"
                  onClick={() => {
                    stringToArrayBuffer(editorRef.current.getContent());
                    setIsEditorChanged(true);
                  }}
                  disabled={isEditorChanged}
                >
                  <Typography
                    noWrap
                    sx={{
                      width: '100%', // Ensure Typography takes full width
                      display: 'inline-block', // Enable ellipsis
                    }}
                  >
                    {t('Save Changes')}
                  </Typography>
                </Button>
              </Tooltip>
            }
            
            {
              documentData?.status === 'draft' &&
              <Button
                variant="outlined"
                sx={{ fontSize: '13px' }}
                fullWidth
                size="large"
                onClick={() => {
                  setOpenSendtoSignModal(true);
                }}
              >
                {t('Send to Sign')}
                <SendIcon sx={{ ml: 1, fontSize: '13px' }} />
              </Button>
            }

            {
              ((documentData?.status === 'pending' && !hasUserSigned) || documentData?.status === 'declined') &&
              <>
                <Button
                  variant="outlined"
                  sx={{ mr: 1, fontSize: '13px' }}
                  fullWidth
                  size="large"
                  onClick={() => {
                    setOpenSignModal(true);
                  }}
                  disabled={hasUserDeclined}
                >
                  {t('Sign')}
                </Button>
                <Button
                  variant="outlined"
                  sx={{ fontSize: '13px' }}
                  fullWidth
                  size="large"
                  onClick={handleDeclineDocument}
                  disabled={hasUserDeclined || isLoadingDecline}
                >
                  {t('Decline')}
                </Button>
              </>
            }
          
          </Box>
        </Box>
      </Drawer>
      {!documentId && <CreateDocumentModal />}
      {openSendtoSignModal && (
        <SendToSignModal
          onClose={() => setOpenSendtoSignModal(false)}
          open={openSendtoSignModal}
          mutate={mutate}
        />
      )}
      {openSignModal && (
        <SignDocumentModal
          onClose={() => setOpenSignModal(false)}
          open={openSignModal}
          mutate={mutate}
        />
      )}
    </Box>
  );
};

const drawerWidth: number = 400;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: any) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(!open && {
    width: "0px !important",
  }),
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    padding: theme.spacing(2),
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderLeft: "1px solid #e0e0e0",
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      display: "none",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "0px !important",
      [theme.breakpoints.up("sm")]: {
        width: "0px !important",
      },
    }),
  },
}));

const EditorStyled = styled.div`
  display: flex;
  flex: 1;
  .tox-editor-header {
    box-shadow: -20px 4px 40px rgba(0, 0, 0, 0.15) !important;
  }
  .tox-tinymce {
    background: #e5e4e2;
  }
  .tox-sidebar-wrap {
    width: 75%;
    background: white;
    margin: 10px auto;
    border-radius: 5px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  }
  .tox-bottom-anchorbar {
    display: none !important;
  }
  .tox-statusbar {
    display: none !important;
  }
`;
