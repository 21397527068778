import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import { AddUpdateCompanyForm } from "./Forms/AddUpdateCompanyForm";
import { AddUpdateParticipantForm } from "./Forms/AddUpdateParticipantForm";
import { AddUpdateSignDetailsForm } from "./Forms/AddUpdateSignDetailsForm";
import { KeyedMutator } from "swr";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  selectedPartyId?: number;
  documentUserId?: number;
  selectedCompanyId?: number;
  mutate: KeyedMutator<any>;
  documentCompaniesMutate: KeyedMutator<any>;
};

export const CounterPartyWalkthrough: FC<Props> = ({
  open,
  onClose,
  selectedPartyId,
  selectedCompanyId,
  mutate,
  documentUserId,
  documentCompaniesMutate,
}) => {
  const { t } = useTranslation();
  const steps = [
    t('Create a company'),
    t('Create a Participant'),
    t('Complete the Sign Details')
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [value, setValue] = React.useState("company");
  const [partCompanyId, setPartCompanyId] = React.useState<number | undefined>(
    undefined
  );
  const [participantId, setParticipantId] = React.useState<number | undefined>(
    undefined
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "company") setActiveStep(0);
    if (event.target.value === "individual") setActiveStep(1);
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (selectedPartyId || selectedCompanyId) {
      setActiveStep(1);
    }
  }, [selectedPartyId, selectedCompanyId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!selectedPartyId && !selectedCompanyId && (
          <Box
            sx={{
              mb: 3,
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="company"
                  control={<Radio />}
                  label={t('Company')}
                />
                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label={t('Individual')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}

        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <AddUpdateCompanyForm
            setPartCompanyId={setPartCompanyId}
            setStep={setActiveStep}
            mutate={mutate}
            onClose={onClose}
            id={selectedCompanyId}
          />
        )}
        {activeStep === 1 && (
          <AddUpdateParticipantForm
            setParticipantId={setParticipantId}
            setStep={setActiveStep}
            partyCompanyId={partCompanyId ?? selectedCompanyId}
            id={selectedPartyId}
            documentUserId={documentUserId}
            onClose={onClose}
            mutate={mutate}
            documentCompaniesMutate={documentCompaniesMutate}
          />
        )}
        {activeStep === 2 && (
          <AddUpdateSignDetailsForm
            participantId={participantId}
            onClose={onClose}
            mutate={mutate}
            documentUserId={documentUserId}
            documentCompaniesMutate={documentCompaniesMutate}
          />
        )}
      </Box>
    </Modal>
  );
};
