import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const PasswordRequirementsChecklist = ({ password }: { password: string }) => {
  const requirements = [
    { label: "At least 8 characters", fulfilled: password.length >= 8 },
    {
      label: "At least one uppercase letter",
      fulfilled: /[A-Z]/.test(password),
    },
    {
      label: "At least one lowercase letter",
      fulfilled: /[a-z]/.test(password),
    },
    { label: "At least one digit", fulfilled: /[0-9]/.test(password) },
    { label: "No spaces", fulfilled: !/\s/.test(password) },
  ];

  return (
    <List>
      {requirements.map((requirement, index) => (
        <ListItem key={index} sx={{ p: 0 }}>
          <ListItemIcon>
            {requirement.fulfilled ? (
              <CheckIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon color="error" />
            )}
          </ListItemIcon>
          <ListItemText primary={requirement.label} />
        </ListItem>
      ))}
    </List>
  );
};

export default PasswordRequirementsChecklist;
