import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardHeader, Avatar } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useInsights } from "../../hooks/useInsights";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';
import FolderIcon from '@mui/icons-material/Folder';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../stores/i18n';

export const Insights: React.FC = () => {
  const CUSTOM_COLORS = ['#bbcdd9', '#87a6ba', '#708798', '#536771', '#314143'];
  const { insights: data } = useInsights();
  const { t } = useTranslation();
  const language = useLanguage();

  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey(prevKey => prevKey + 1);
  }, [language]);

  const renderLegend = (value: string) => (
    <span style={{ marginRight: 20 }}>{t(`${value}`)}</span>
  );

  const renderLegendType = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: '15px', alignItems: 'center', marginTop: '20px' }}>
      {data?.document_per_type.map((entry: any, index: number) => (
        <Box key={`legend-item-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '10px', height: '10px', backgroundColor: CUSTOM_COLORS[index % CUSTOM_COLORS.length], marginRight: '5px' }} />
          <Typography variant="body2">{entry.type}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderLegendStatus = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '15px', alignItems: 'center', marginTop: '20px' }}>
      {data?.document_per_status.map((entry: any, index: number) => (
        <Box key={`legend-item-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '10px', height: '10px', backgroundColor: CUSTOM_COLORS[index % CUSTOM_COLORS.length], marginRight: '5px' }} />
          <Typography variant="body2">{entry.status}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderDocumentPerType = () => {
    if (!data?.document_per_type) {
      return (
        <Box textAlign="center">
          <InfoIcon style={{ fontSize: 48 }} />
          <Typography variant="body1">{t('Nothing here')}</Typography>
          <Typography variant="body2">{t('There is no activity to report yet.')}</Typography>
        </Box>
      );
    }
    return (
      <Box>
        <ResponsiveContainer width="100%" height={400} aspect={1}>
          <PieChart>
            <Legend content={renderLegendType} verticalAlign="top" />
            <Pie
              data={data?.document_per_type}
              dataKey="Count"
              nameKey="type"
              cx="50%"
              cy="50%"
              outerRadius={100}
              animationBegin={500}
            >
              {data?.document_per_type.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CUSTOM_COLORS[index % CUSTOM_COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderDocumentPerStatus = () => {
    if (!data?.document_per_status) {
      return (
        <Box textAlign="center">
          <InfoIcon style={{ fontSize: 48 }} />
          <Typography variant="body1">{t('Nothing here')}</Typography>
          <Typography variant="body2">{t('There is no activity to report yet.')}</Typography>
        </Box>
      );
    }
    return (
      <Box>
        <ResponsiveContainer width="100%" height={400} aspect={1}>
          <PieChart>
            <Legend content={renderLegendStatus} verticalAlign="top" />
            <Pie
              data={data?.document_per_status}
              dataKey="Count"
              nameKey="status"
              cx="50%"
              cy="50%"
              outerRadius={100}
              animationBegin={500}
            >
              {data?.document_per_status.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CUSTOM_COLORS[index % CUSTOM_COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderMonthlyCreatedDocuments = () => {
    if (!data?.monthly_created_documents) {
      return (
        <Box textAlign="center">
          <InfoIcon style={{ fontSize: 48 }} />
          <Typography variant="body1">{t('Nothing here')}</Typography>
          <Typography variant="body2">{t('There is no activity to report yet.')}</Typography>
        </Box>
      );
    }
    return (
      <Box>
        <ResponsiveContainer width="100%" height={300} aspect={3}>
          <BarChart data={data?.monthly_created_documents}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip cursor={{ fill: "#7ca1b9" }}/>
            <Legend formatter={renderLegend} />
            <Bar dataKey="Count" fill={CUSTOM_COLORS[0]} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderDocumentPerUser = () => {
    if (!data?.document_per_user) {
      return (
        <Box textAlign="center">
          <InfoIcon style={{ fontSize: 48 }} />
          <Typography variant="body1">{t('Nothing here')}</Typography>
          <Typography variant="body2">{t('There is no activity to report yet.')}</Typography>
        </Box>
      );
    }
    return (
      <Box>
        <ResponsiveContainer width="100%" height={300} aspect={3}>
          <BarChart data={data?.document_per_user}>
            <XAxis dataKey="first_name" />
            <YAxis />
            <Tooltip cursor={{ fill: "#7ca1b9" }}/>
            <Legend formatter={renderLegend} />
            <Bar dataKey="Count" fill={CUSTOM_COLORS[0]} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  return (
    <Box key={renderKey}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', color: "#22272A" }}>
        <Card sx={{ marginTop: '20px', width: '49%' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: '#007ACC' }} aria-label="recipe">
                <Box sx={{ backgroundColor: '#434E54', borderRadius: '50%', padding: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <InsertChartIcon sx={{ color: 'white', alignSelf: 'center' }} />
                  </motion.div>
                </Box>
              </Avatar>
            }
            title={t('Monthly Created Documents')}
            sx={{ borderBottom: '1px solid #dfdfdf', "& .MuiCardHeader-title": { fontSize: '17px', fontWeight: 'medium' } }}
          />
          <CardContent sx={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {renderMonthlyCreatedDocuments()}
          </CardContent>
        </Card>
        <Card sx={{ marginTop: '20px', width: '49%' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: '#007ACC' }} aria-label="recipe">
                <Box sx={{ backgroundColor: '#434E54', borderRadius: '50%', padding: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <AssignmentIcon sx={{ color: 'white', alignSelf: 'center' }} />
                  </motion.div>
                </Box>
              </Avatar>
            }
            title={t('Document per User')}
            sx={{ borderBottom: '1px solid #dfdfdf', "& .MuiCardHeader-title": { fontSize: '17px', fontWeight: 'medium' } }}
          />
          <CardContent sx={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {renderDocumentPerUser()}
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <Card sx={{ marginTop: '20px', width: '49%' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: '#007ACC' }} aria-label="recipe">
                <Box sx={{ backgroundColor: '#434E54', borderRadius: '50%', padding: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <GroupIcon sx={{ color: 'white', alignSelf: 'center' }} />
                  </motion.div>
                </Box>
              </Avatar>
            }
            title={t('Document per Type')}
            sx={{ borderBottom: '1px solid #dfdfdf', "& .MuiCardHeader-title": { fontSize: '17px', fontWeight: 'medium' } }}
          />
          <CardContent sx={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {renderDocumentPerType()}
          </CardContent>
        </Card>
        <Card sx={{ marginTop: '20px', width: '49%' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: '#007ACC' }} aria-label="recipe">
                <Box sx={{ backgroundColor: '#434E54', borderRadius: '50%', padding: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <FolderIcon sx={{ color: 'white', alignSelf: 'center' }} />
                  </motion.div>
                </Box>
              </Avatar>
            }
            title={t('Document per Status')}
            sx={{ borderBottom: '1px solid #dfdfdf', "& .MuiCardHeader-title": { fontSize: '17px', fontWeight: 'medium' } }}
          />
          <CardContent sx={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {renderDocumentPerStatus()}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
