import {
  Avatar,
  Box,
  Button,
  Card,
  Drawer,
  IconButton,
  InputLabel,
  Select,
  Typography,
  FormControl,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../theme";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useUserInfoDrawer } from "../../hooks/useUserInfoDrawer";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  onClose: () => void;
};
export const UserInfoDrawer: FC<Props> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { userData, workSpaceData, workspace, handleSetWorkspace, logout } = useUserInfoDrawer();
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 1300,
        ".MuiDrawer-paper": {
          width: "20% !important",
          padding: 3,
        },

        "@media (max-width: 1200px)": {
          ".MuiDrawer-paper": {
            width: "100% !important",
          },
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">{t('User Info')}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box gap={3} display="flex" flexDirection="column">
        <Card
          sx={{
            padding: 2,
            border: "0.0625rem solid rgb(219, 219, 223)",
            borderRadius: "1rem",
            boxShadow: "none",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main, alignSelf: 'start' }}>
              {userData?.first_name?.charAt(0)}
            </Avatar>
            <Box display="flex" flexDirection="column">
              <Typography variant="body2">
                {userData?.first_name} {userData?.last_name}
              </Typography>
              <Typography variant="caption">{userData?.email}</Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="end" mt={2}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={logout}
            >
              {t('Sign out')}
            </Button>
          </Box>
        </Card>
        <Card
          sx={{
            padding: 2,
            border: "0.0625rem solid rgb(219, 219, 223)",
            borderRadius: "1rem",
            boxShadow: "none",
          }}
        >
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography variant="body2">{t('My Workspaces')}</Typography>
              <IconButton onClick={() => navigate("/workspace/create")}>
                <AddIcon />
              </IconButton>
            </Box>

            <FormControl fullWidth>
              <>
                <InputLabel htmlFor="demo-simple-select">{t('Workspace')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={workspace}
                  label={t('Workspace')}
                  onChange={handleSetWorkspace}
                >
                  {(workSpaceData || [])?.map((ws, index) => {
                    return (
                      <MenuItem key={index} value={ws.id}>
                        {ws.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            </FormControl>
          </Box>
        </Card>
      </Box>
    </Drawer>
  );
};
