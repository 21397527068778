import Joi from "joi";
import React, { FC, useEffect, useMemo } from "react";
import { useErrorContext } from "../../../../../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../../../../../context/SuccessContext";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../../../../context/AuthContext";
import {
  useDeleteApi,
  useFetchApi,
  usePatchApi,
  usePostApi,
} from "../../../../../../../../../hooks/useApi";
import { URLS } from "../../../../../../../../../constants/urls";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { KeyedMutator } from "swr";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { countries } from "../../../../../../../../../constants/countries";
import { useTranslation } from "react-i18next";

const schemaAddCounterParty = Joi.object({
  id: Joi.number().optional(),
  name: Joi.string().required().label("Company Name"),
  country: Joi.string().required().label("Country"),
  registration_number: Joi.string().required().label("Registration Number.message"),
}).unknown(true);

type Props = {
  setStep?: (step: number) => void;
  id?: number;
  setPartCompanyId?: (companyId: number) => void;
  onClose?: () => void;
  mutate?: KeyedMutator<any>;
};

type FormTypes = {
  id?: number;
  name: string;
  country: string;
  registration_number: string;
};

export const AddUpdateCompanyForm: FC<Props> = ({
  setPartCompanyId,
  setStep,
  id,
  onClose,
  mutate,
}) => {
  const [selectedCompany, setSelectedCompany] = React.useState<any>(null);
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { user } = useAuth();

  const { data: partyCompanyData } = useFetchApi<FormTypes>(
    id || selectedCompany
      ? `${URLS.party_company}/${id ? id : selectedCompany?.value}`
      : undefined,
    undefined
  );

  const { data: addressBookCompanies } = useFetchApi<
    { id: number; name: string }[]
  >(
    `${URLS.party_company_dropdown}?workspace_id=${user?.workspace_id}`,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    postData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(`${URLS.party_company}`);

  const {
    patchData,
    isLoading: isLoadingPatch,
    error: patchError,
  } = usePatchApi(`${URLS.party_company}/${id}`);

  const {
    deleteData: deleteItemData,
    isLoading: isLoadingDeleteItem,
    error: errorDeleteItem,
  } = useDeleteApi<any>(URLS.party_company);

  const { handleSubmit, control, reset } = useForm<FormTypes>({
    defaultValues: {
      id: id,
      name: "",
      country: "",
      registration_number: "",
    },
    resolver: joiResolver(schemaAddCounterParty),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (error || patchError || errorDeleteItem)
      showError(
        error?.message ||
          patchError?.message ||
          errorDeleteItem?.message ||
          t('An error occurred')
      );
  }, [error, patchError, errorDeleteItem]);

  useEffect(() => {
    if (partyCompanyData) {
      reset(partyCompanyData);
    }
  }, [partyCompanyData]);

  const onSubmit = async (data: FormTypes) => {
    if (!selectedCompany) {
      const newData = {
        ...(id && { id: Number(data.id) }),
        ...data,
        workspace_id: user?.workspace_id,
      };

      const response = await (id ? patchData : postData)(newData);

      if (response) {
        reset();
        showSuccess(
          !id ? t('Company added successfully') : t('Company updated successfully')
        );
        if (setStep && setPartCompanyId) {
          setStep(1);
          setPartCompanyId(response.id);
        }
        if (id) {
          onClose?.();
          mutate?.();
        }
      }
    } else {
      setStep?.(1);
      setPartCompanyId?.(selectedCompany?.value);
      mutate?.();
    }
  };

  const deleteItem = async () => {
    const response = await deleteItemData(String(id));
    if (response) {
      onClose?.();
      mutate?.();
      showSuccess(t('Company deleted successfully'));
    }
  };

  const defaultCountryValue = countries.find(
    (item) => item.label === partyCompanyData?.country
  );

  return (
    <Box sx={{ mt: 3 }}>
      {!id && (
        <Box sx={{ mb: 1 }}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={
              addressBookCompanies?.map((item) => {
                return { label: item.name, value: item.id };
              }) ?? []
            }
            onChange={(e, newValue) => setSelectedCompany(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Select a company')}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Box>
      )}
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ mb: 2 }}
      >
        {id ? t('Update CounterParty') : t('Add CounterParty')}
      </Typography>

      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Controller
            name="name"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="name"
                  fullWidth
                  label={t('Company Name')}
                  autoComplete="name"
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="country"
            control={control}
            defaultValue={defaultCountryValue?.label ?? ""}
            render={({ field, formState: { errors } }) => (
              <div>
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  autoHighlight
                  fullWidth
                  defaultValue={null}
                  inputValue={field.value ?? defaultCountryValue?.label}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, data) => {
                    field.onChange(data?.label);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...field}
                      {...params}
                      label={t('Choose a country')}
                      id="country"
                      name="country"
                      fullWidth
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      error={Boolean(errors.country)}
                      helperText={errors.country?.message}
                    />
                  )}
                />
              </div>
            )}
          />

          <Controller
            name="registration_number"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="registration_number"
                  fullWidth
                  label={t('Registration Number')}
                  autoComplete="registration_number"
                  error={Boolean(errors.registration_number)}
                  helperText={errors.registration_number?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Box>

        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: id ? "space-between" : "end",
            alignItems: "center",
          }}
        >
          {id && (
            <Box>
              <Button onClick={deleteItem} variant="outlined" color="error">
                <DeleteIcon />
                {t('Remove CounterParty')}
              </Button>
            </Box>
          )}
          <Box>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1 }}
              disabled={isLoadingPost || isLoadingPatch}
            >
              {id ? t('Update CounterParty') : t('Next')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
