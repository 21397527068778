import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  DataGridPro,
  GridRowParams,
  GridActionsCellItem,
  GridSortItem,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { Box, Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useDeleteApi, useFetchApi } from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { DeleteWorkspaceUserDialog } from "./components/DeleteWorkspaceUserDialog";
import { useErrorContext } from "../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../context/SuccessContext";
import { AddUserModal } from "./components/AddUserModal";
import { useTranslation } from "react-i18next";

type WorkSpaceUserList = {
  total_count: number;
  items: Array<Api.WorkspaceUser>;
};

const PAGE_SIZE_OPTIONS = [5, 10];

export const WorkspaceUsers = () => {
  const { id: workspaceId } = useParams<{ id: string }>();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [modifiedSortModel, setModifiedSortModel] = React.useState<
    GridSortItem[]
  >([]);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [rowId, setRowId] = React.useState<number | undefined>();
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);

  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { data: rows, mutate } = useFetchApi<WorkSpaceUserList>(
    URLS.workspace_user_list,
    {
      workspace_id: workspaceId ?? "",
      page: String(page),
      page_size: String(pageSize),
      search: searchValue,
      sort_field_list: JSON.stringify(modifiedSortModel),
    },
    { revalidateOnFocus: false }
  );

  const {
    deleteData: deleteData,
    isLoading: isLoadingDelete,
    error: errorDelete,
  } = useDeleteApi<any>(`${URLS.workspace_user}/${workspaceId}/users`);

  useEffect(() => {
    if (errorDelete) {
      showError(errorDelete.message);
    }
  }, [errorDelete]);

  const fetchData = useCallback(() => {
    mutate();
  }, [page, pageSize, searchValue, modifiedSortModel]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const deleteClick = (id: number) => {
    setDeleteDialog(true);
    setRowId(id);
  };

  const deleteWorkspaceUser = async () => {
    if (rowId) {
      const response = await deleteData(String(rowId));

      if (response) {
        mutate();
        setDeleteDialog(false);
        showSuccess("User removed to this workspace successfully");
      }
    }
  };

  const columns = React.useMemo<GridColDef<Api.WorkspaceUser>[]>(
    () => [
      {
        field: "id",
        headerName: t('ID'),
        disableColumnMenu: true,
        width: 5,
      },
      {
        field: "name",
        headerName: t('Name'),
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params) => {
          return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
        },
      },
      {
        field: "job_title",
        headerName: t('Job Title'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "email",
        headerName: t('Email'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "phone_number",
        headerName: t('Phone'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "role",
        headerName: t('Role'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        width: 5,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<RemoveCircleOutlineIcon />}
            onClick={() => deleteClick(Number(params.id))}
            label={t('Remove')}
            showInMenu
          />,
        ],
      },
    ],
    [deleteClick]
  );

  return (
    <Box sx={{ mt: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <TextField
            id="input-with-icon-textfield"
            label={t('Search')}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Box>
          <Button
            onClick={() => setIsAddModalOpen(true)}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add User
          </Button>
        </Box>
      </Box>

      <DataGridPro
        rows={rows?.items ?? []}
        rowCount={rows?.total_count ?? 0}
        columns={columns}
        rowSelection={false}
        initialState={{
          pagination: {
            paginationModel: { page: page, pageSize: pageSize },
          },
        }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        pagination
        onSortModelChange={(model) => {
          setModifiedSortModel(model);
        }}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
      <DeleteWorkspaceUserDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        deleteWorkspaceUser={deleteWorkspaceUser}
        isDeleteButtonDisable={isLoadingDelete}
      />
      <AddUserModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        mutate={mutate}
      />
    </Box>
  );
};
