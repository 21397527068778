import { Box, Card, CardContent, Grid, Typography, Avatar, CardHeader, Button, Tabs, Tab } from "@mui/material";
import React from "react";
import theme from "../../theme";
import { useDashboard } from "../../hooks/useDashboard";
import DashboardCard from "./DashboardCard";
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { Insights } from "../Insights";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from 'react-i18next';

export const Dashboard = () => {
  const {documentCount, companyData} = useDashboard();
  const { user } = useAuth();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Card>
        <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          <Avatar
            sx={{
              bgcolor: "primary.main",
              width: 65,
              height: 65
            }}
          >
            {user?.first_name?.charAt(0)}
          </Avatar>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="baseline"
          >
             <Typography variant="h5" color="#22272A">{t('Hi')}, {user?.first_name}</Typography>
             <Typography variant="h6" color="#22272A">{t('Welcome back!')}!</Typography>
             <Typography variant="h6" color="#22272A"></Typography>
          </Box>
        </Box>
        
        </CardContent>
      </Card>
      
      <Grid container spacing={theme.spacing(3)} sx={{ mt: theme.spacing(2) }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={t('Signature Status')}
              sx={{color: '#22272A'}}
            />
            <CardContent>
              <Grid container spacing={theme.spacing(2)}>
                <Grid item xs={12} sm={6} md={3} >
                  <DashboardCard
                    leftLabel={t('Pending')}
                    leftIcon={<InfoOutlinedIcon sx={{color: "#434E54" , fontSize: '18px'}} />}
                    rightIcon={<PendingActionsOutlinedIcon />}
                    documentCount={documentCount?.pending_count}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                  <DashboardCard
                    leftLabel={t('Declined')}
                    leftIcon={<InfoOutlinedIcon sx={{color: "#434E54" , fontSize: '18px'}} />}
                    rightIcon={<ThumbDownOffAltOutlinedIcon />}
                    documentCount={documentCount?.declined_count}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                  <DashboardCard
                    leftLabel={t('Overdue')}
                    leftIcon={<InfoOutlinedIcon sx={{color: "#434E54" , fontSize: '18px'}} />}
                    rightIcon={<AccessAlarmOutlinedIcon />}
                    documentCount={documentCount?.overdue_count}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                  <DashboardCard
                    leftLabel={t('Signed')}
                    leftIcon={<InfoOutlinedIcon sx={{color: "#434E54" , fontSize: '18px'}} />}
                    rightIcon={<TaskOutlinedIcon />}
                    documentCount={documentCount?.signed_count}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Insights/>
    </Box>
  );
};