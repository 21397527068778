import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export type ParticipantState = {
  mutateParticipants: () => void;
};
export type ParticipantMutators = {
  setMutateParticipants: (mutateParticipants: () => void) => void;
};

const participantState = atom<ParticipantState>({
  key: 'participantState',
  default: { mutateParticipants: () => {} },
});

export const useParticipantState = (): ParticipantState => {
  return useRecoilValue(participantState);
};

export const useParticipantStateMutators = (): ParticipantMutators => {
  const setState = useSetRecoilState(participantState);

  const setMutateParticipants = useCallback(
    (mutateParticipants: () => void) =>
      setState((previousState: ParticipantState) => {
        return {
          ...previousState,
          mutateParticipants,
        };
      }),
    [setState],
  );

  return {
    setMutateParticipants,
  };
};
