import { Box, Button, Card, Modal, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "../../../../../../../context/AuthContext";
import { useFetchApi } from "../../../../../../../hooks/useApi";
import { URLS } from "../../../../../../../constants/urls";
import { UserBox } from "../UserBox";
import theme from "../../../../../../../theme";
import { blue } from "@mui/material/colors";
import { AddColleagueModal } from "./AddColleagueModal";
import { useParams, useSearchParams } from "react-router-dom";
import { useDocumentState } from "../../../../../../../stores/document";
import { useTranslation } from "react-i18next";

export const ColleagueCard = () => {
  const { user, isExternalUser } = useAuth();
  const { status } = useDocumentState();
  const { id: documentId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = React.useState<
    number | undefined
  >();

  const [isAddColleagueModal, setIsAddColleagueModal] = React.useState(false);

  const { data: workspaceData } = useFetchApi<Api.Workspace>(
    !isExternalUser ? `${URLS.workspaces}/${user?.workspace_id}` : undefined
  );

  const { data: documentUsersList, mutate } = useFetchApi<Api.DocumentUser[]>(
    `${URLS.document_users}?document_id=${documentId}`
  );

  const rowClick = (value: boolean, id: number) => {
    setSelectedUserId(id);
    setIsAddColleagueModal(value);
  };

  return (
    <>
      <Card
        sx={{
          border: "0.5px solid #E5E4E2",
          borderRadius: 2,
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "200px",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                width: "100%",
                background: "#F5F5F5",
                height: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ padding: 2 }}
                color={theme.palette.primary.main}
              >
                {workspaceData?.name}
              </Typography>
            </Box>

            <Box>
              {documentUsersList
                ?.filter((item) => item.type === "internal")
                .map((user) => {
                  return (
                    <UserBox
                      key={user.id}
                      id={user.id}
                      name={`${user?.participant_detail?.first_name} ${user?.participant_detail?.last_name}`}
                      role={user.role}
                      documentUserId={user.id}
                      setOpenModal={rowClick}
                      signOrder={user.sign_order}
                      signed_time={user.signed_time}
                      declined_time={user.declined_time}
                      is_signed={user.is_signed}
                    />
                  );
                })}
            </Box>
          </Box>

          <Box>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
                borderRadius: 2,
                borderTopLeftRadius: "0 !important",
                borderTopRightRadius: "0 !important",
              }}
              disabled={isExternalUser || status === 'pending'}
              onClick={() => setIsAddColleagueModal(true)}
            >
              <AddIcon fontSize="small" />
              {t('Add Colleague')}
            </Button>
          </Box>
        </Box>
      </Card>
      {isAddColleagueModal && (
        <AddColleagueModal
          open={isAddColleagueModal}
          onClose={() => setIsAddColleagueModal(false)}
          mutate={mutate}
          id={selectedUserId}
        />
      )}
    </>
  );
};
