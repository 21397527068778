import React, { useEffect } from "react";
import { ColleagueCard } from "./components/ColleagueCard";
import { Box, Button } from "@mui/material";
import { CounterPartyCard } from "./components/CounterPartyCard";
import AddIcon from "@mui/icons-material/Add";
import { CounterPartyWalkthrough } from "./components/CounterPartyWalkthrough";
import { URLS } from "../../../../../constants/urls";
import { useParams } from "react-router-dom";
import { useFetchApi } from "../../../../../hooks/useApi";
import { UserBox } from "./components/UserBox";
import Edit from "@mui/icons-material/Edit";
import { EditCompanyModal } from "./components/EditCompanyModal";
import { useParticipantStateMutators } from "../../../../../stores/participant";
import { useAuth } from "../../../../../context/AuthContext";
import { useDocumentState } from "../../../../../stores/document";
import { useTranslation } from "react-i18next";

export const ParticipantSettings = () => {
  const { id: documentId } = useParams<{ id: string }>();
  const { isExternalUser } = useAuth()
  const { t } = useTranslation();
  const { setMutateParticipants } = useParticipantStateMutators();
  const { status } = useDocumentState();

  const [isAddCounterPartyModal, setIsAddCounterPartyModal] =
    React.useState(false);
  const [selectedPartyId, setSelectedPartyId] = React.useState<
    number | undefined
  >();
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<
    number | undefined
  >();
  const [documentUserId, setDocumentUserId] = React.useState<
    number | undefined
  >();
  const [editCompanyModal, setEditCompanyModal] = React.useState(false);

  const { data: documentUsersList, mutate } = useFetchApi<Api.DocumentUser[]>(
    `${URLS.document_users}?document_id=${documentId}`
  );

  const { data: documentCompaniesList, mutate: mutateDocumentCompaniesList } =
    useFetchApi<Api.DocumentUserCompanies[]>(
      `${URLS.document_users_companies}?document_id=${documentId}`
    );

  const rowClick = (value: boolean, id: number, documentUserId: number) => {
    setSelectedPartyId(id);
    setDocumentUserId(documentUserId);
    setIsAddCounterPartyModal(value);
  };

  const rowCompanyClick = (id: number) => {
    setSelectedCompanyId(id);
    setEditCompanyModal(true);
  };

  const addParticipantButtonClick = (companyId: number) => {
    setSelectedCompanyId(companyId);
    setIsAddCounterPartyModal(true);
  };

  useEffect(() => {
    setMutateParticipants(mutate);
  }, [mutate])

  return (
    <Box sx={{ mt: 2 }}>
      <ColleagueCard />
      <CounterPartyCard
        rowClick={rowClick}
        addParticipantButtonClick={addParticipantButtonClick}
        rowCompanyClick={rowCompanyClick}
        documentCompaniesList={documentCompaniesList}
      />
      <Box sx={{ mt: 3 }}>
        {documentUsersList
          ?.filter((item) => item.type === "external")
          ?.map((user) => {
            return (
              <UserBox
                key={user.id}
                id={user.party_id}
                documentUserId={user.id}
                name={`${user?.participant_detail?.first_name} ${user?.participant_detail?.last_name}`}
                role={user.role}
                setOpenModal={rowClick}
                signOrder={user.sign_order}
                signed_time={user.signed_time}
                declined_time={user.declined_time}
                is_signed={user.is_signed}
              />
            );
          })}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 3, mb: 3 }}>
        <Button
          variant="outlined"
          size="small"
          sx={{
            width: "100%",
            borderRadius: 2,
          }}
          disabled={isExternalUser || status === 'pending'}
          onClick={() => setIsAddCounterPartyModal(true)}
        >
          <AddIcon fontSize="small" />
          {t('Add CounterParty')}
        </Button>
      </Box>
      {isAddCounterPartyModal && (
        <CounterPartyWalkthrough
          onClose={() => {
            setIsAddCounterPartyModal(false);
            setSelectedPartyId(undefined);
            setSelectedCompanyId(undefined);
          }}
          open={isAddCounterPartyModal}
          selectedPartyId={selectedPartyId}
          selectedCompanyId={selectedCompanyId}
          mutate={mutate}
          documentUserId={documentUserId}
          documentCompaniesMutate={mutateDocumentCompaniesList}
        />
      )}
      {editCompanyModal && (
        <EditCompanyModal
          open={editCompanyModal}
          onClose={() => {
            setEditCompanyModal(false);
            setSelectedCompanyId(undefined);
          }}
          id={selectedCompanyId}
          mutate={mutateDocumentCompaniesList}
        />
      )}
    </Box>
  );
};
