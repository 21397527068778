import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import { usePostApi } from "../../../../../../../hooks/useApi";
import { URLS } from "../../../../../../../constants/urls";
import { useSuccessContext } from "../../../../../../../context/SuccessContext";
import { useErrorContext } from "../../../../../../../context/ErrorContext";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Label } from "@mui/icons-material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const Textarea = styled(BaseTextareaAutosize)<{ error?: boolean }>(
  ({ theme, error }) => `
    box-sizing: border-box;
    width: 100%;
  
    padding: 8px 12px;
    border-radius: 5px;
    border: ${error ? `1px solid red` : `0.5px solid ${grey[700]}`};

    &:hover {
      border-color: ${error ? `1px solid red` : `0.5px solid ${blue[400]}`};
    }

    &:focus {
      border-color: ${error ? `1px solid red` : `0.5px solid ${blue[400]}`};
      box-shadow: ${error ? `0 0 0 3px red` : `0 0 0 3px ${blue[200]}`};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  mutate: KeyedMutator<any>;
};

type FormTypes = {
  subject: string;
  message: string;
  expire_at?: null;
};

const schemaSendtoSign = Joi.object({
  subject: Joi.string().required().label("Subject"),
  message: Joi.string().required().label("Message"),
  expire_at: Joi.optional().label("Expire At"),
}).unknown(true);

export const SendToSignModal: FC<Props> = ({ open, onClose, mutate }) => {
  const { id: documentId } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<FormTypes>({
    defaultValues: {
      subject: "",
      message: "",
      expire_at: null,
    },
    resolver: joiResolver(schemaSendtoSign),
    mode: "onSubmit",
  });

  const { showSuccess } = useSuccessContext();
  const { showError } = useErrorContext();

  const {
    postData: postSendToSign,
    error: errorDocumentSendToSign,
    isLoading,
  } = usePostApi(`${URLS.document}/${documentId}/send-to-sign`);

  const onSubmit = async (data: any) => {
    const response = await postSendToSign({
      document_id: documentId,
      message: data.message,
      subject: data.subject,
      expire_at: data.expire_at,
    });

    if (response) {
      showSuccess(t('Sent to sign successfully'));
      mutate();
      onClose();
    } else {
      showError(errorDocumentSendToSign?.message ?? t('An error occurred'));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" align="center" sx={{ mb: 2 }}>
          Send to Sign
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="subject"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      size="small"
                      id="subject"
                      fullWidth
                      label={t('Subject')}
                      autoComplete="subject"
                      error={Boolean(errors.subject)}
                      helperText={errors.subject?.message}
                      {...field}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="message"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <FormControl defaultValue="" required fullWidth>
                      {!field.value && (
                        <InputLabel htmlFor="message-input">Message</InputLabel>
                      )}
                      <Textarea
                        required
                        id="message-input"
                        minRows={5}
                        error={Boolean(errors.message)}
                        aria-describedby="my-helper-text"
                        {...field}
                      />
                      <FormHelperText id="my-helper-text" sx={{ color: "red" }}>
                        {errors.message?.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="expire_at"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <FormControl defaultValue="" required>
                      <DatePicker
                        label={t('Expire At')}
                        {...field}
                        aria-describedby="my-helper-text"
                      />
                      <FormHelperText id="my-helper-text">
                        {errors.expire_at?.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
          </Grid>
          <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 1 }}
              disabled={isLoading}
            >
              {t('Send')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
