import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetchApi, usePatchApi } from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import { Box, TextField, MenuItem, Typography, Grid, Button } from "@mui/material";
import { useDocumentSettings, useDocumentSettingsMutators } from "../../../../../stores/document";
import { formatDate } from "../../../../../utils";
import { useErrorContext } from "../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../context/SuccessContext";
import { useTranslation } from "react-i18next";

export const DocumentSettings = () => {
  const { id: documentId } = useParams<{ id: string }>();
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { data: documentCurrencies, mutate } = useFetchApi<Api.DocumentCurrencies[]>(
    `${URLS.document_currencies}`,
    undefined
  );

  const { patchData, isLoading, error: errorPatch } = usePatchApi(
    `${URLS.document}/${documentId}`
  );

  const formData = useDocumentSettings();
  const { setDocumentSettings } = useDocumentSettingsMutators();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDocumentSettings({ [name]: value });
  };

  const handleSave = async() => {
    const payload = {
      ...formData,
      amount: parseFloat(formData.amount),
      termination_date: formData.termination_date ? new Date(formData.termination_date).toISOString() : null,
      renewal_date: formData.renewal_date ? new Date(formData.renewal_date).toISOString() : null,
      deletion_date: formData.deletion_date ? new Date(formData.deletion_date).toISOString() : null,
    };
    const response = await patchData(payload);
    if (response) {
      showSuccess("Document settings updated successfully");
    }
  };

  useEffect(() => {
    if (errorPatch) {
      showError(errorPatch.message);
    }
  }, [errorPatch]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%" }}>
      <Typography variant="h6" sx={{mt: 2}} gutterBottom component="div">
        {t('Document Settings')}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('Amount')}
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('Currency')}
              select
              name="currency"
              value={documentCurrencies?.find(currency => currency.value === formData.currency) ? formData.currency : ''}
              onChange={handleInputChange}
              fullWidth
              size="small"
              sx={{ mb: 2 }}
            >
              {documentCurrencies && documentCurrencies.length > 0 ? (
                documentCurrencies.map((data, index) => (
                  <MenuItem key={index} value={data.value}>{data.value}</MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>No currencies available</MenuItem>
              )}
            </TextField>
          </Grid>
        </Grid>
        
        <TextField
          label={t('Termination Date')}
          type="date"
          name="termination_date"
          value={formData.termination_date ? formatDate(formData.termination_date) : ''}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          size="small"
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('Renewal Date')}
          type="date"
          name="renewal_date"
          value={formData.renewal_date ? formatDate(formData.renewal_date) : ''}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          size="small"
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('Deletion Date')}
          type="date"
          name="deletion_date"
          value={formData.deletion_date ? formatDate(formData.deletion_date) : ''}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          size="small"
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ mt: "auto" }}>
        <Button variant="contained" sx={{mb: 2}} fullWidth color="primary" disabled={isLoading} onClick={handleSave}>
          {isLoading ? `${t('Saving')}...`: t('Save')}
        </Button>
      </Box>
    </Box>
  );
};
