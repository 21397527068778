import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { URLS } from "../../../../constants/urls";
import { useParams } from "react-router-dom";
import { usePatchApi } from "../../../../hooks/useApi";
import { useErrorContext } from "../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../context/SuccessContext";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  documentData: { name: string; description: string } | undefined;
  mutate: () => void;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

type FormTypes = {
  name: string;
  description: string;
};

const schemaUpdateDocumentForm = Joi.object({
  description: Joi.optional(),
  name: Joi.optional(),
}).unknown(true);

export const EditDocumentInformationsModal: FC<Props> = ({
  isOpen,
  onClose,
  documentData,
  mutate,
}) => {
  const { id: documentId } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();

  const {
    patchData,
    isLoading: isLoadingPatch,
    error,
  } = usePatchApi(`${URLS.document}/${documentId}`);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: joiResolver(schemaUpdateDocumentForm),
  });

  useEffect(() => {
    if (documentData) {
      reset({
        name: documentData.name,
        description: documentData.description,
      });
    }
  }, [documentData]);

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: FormTypes) => {
    const response = await patchData({
      ...data,
    });
    if (response) {
      reset();
      mutate();
      onClose();
      showSuccess(t('Document added successfully'));
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          component="form"
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('Edit Document Informations')}
          </Typography>
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column", mt: 2 }}>
            <Controller
              name="name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    size="medium"
                    id="name"
                    fullWidth
                    sx={{ width: "300px" }}
                    label={t('Document Name')}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                </div>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    size="medium"
                    sx={{ width: "300px" }}
                    id="description"
                    fullWidth
                    label={t('Document Description')}
                    {...field}
                  />
                </div>
              )}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Box>
              <Button
                onClick={() => {
                  reset({
                    name: documentData?.name,
                    description: documentData?.description,
                  });
                  onClose();
                }}
                size="large"
                sx={{ mr: 1 }}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                size="large"
                disabled={isLoadingPatch}
              >
                {t('Update')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
