import * as React from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SignIn from "./SignIn";
import { useEffect, useState } from "react";
import { PrivateRoute } from "../auth/PrivateRoute";
import { AiChat } from "./AiChat";
import { ReviewAgreements } from "./ReviewAgreements";
import { Dashboard } from "./Dashboard";
import { Process } from "./Process";
import { Insights } from "./Insights";
import { ErrorProvider, useErrorContext } from "../context/ErrorContext";
import ErrorSnackbar from "../components/ErrorSnackbar";
import { SignUp } from "./SignUp";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { SuccessProvider } from "../context/SuccessContext";
import SuccessSnackbar from "../components/SuccessSnackbar";
import { Walkthrough } from "./Walkthrough";
import NotFoundPage from "./NotFoundPage";
import HelpPage from "./Help";
import ErrorBoundary from "./ErrorBoundary";
import HealthCheck from "./HealthCheck";
import { AuthProvider, useAuth } from "../context/AuthContext";
import { URLS } from "../constants/urls";
import { useFetchApi } from "../hooks/useApi";
import { ROLES } from "../constants/roles";
import { CreateWorkspace } from "./CreateWorkspace";
import { Admin } from "./Admin";
import { LicenseInfo } from "@mui/x-license-pro";
import { InviteUser } from "./InviteUser";
import { Profile } from "./Profile";
import { Files } from "./Files";
import { Templates } from "./Templates";
import { DocumentPdf } from "./DocumentPdf";
import { DocumentDetail } from "./DocumentDetail";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [checkedCompanyWorkspace, setCheckedCompanyWorkspace] = useState(
    Boolean(localStorage.getItem("checkedCompanyWorkspace") === "true")
  );
  const navigate = useNavigate();
  const { showError } = useErrorContext();
  const { hasRole } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: workspaceData, error: workspaceError, mutate: mutateCheckCompanyWorkspace } =
    useFetchApi(
      isAuthenticated && hasRole([ROLES.COMPANY_ADMIN])
        ? URLS.check_company_workspace
        : undefined,
      undefined
    );

  useEffect(() => {
    setCheckedCompanyWorkspace(
      Boolean(localStorage.getItem("checkedCompanyWorkspace") === "true")
    );
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");

    const externalUser = searchParams.get("access_token");

    if (externalUser) {
      const redirectUrl = `${window.location.pathname}${window.location.search}`;

      navigate(redirectUrl);
    } else {
      if (accessToken) {
        setIsAuthenticated(true);
      } else if (
        ![
          "/sign-in",
          "/register",
          "/forgot-password",
          "/reset-password",
          "/health",
        ].includes(window.location.pathname)
      ) {
        setIsAuthenticated(false);
        navigate("/sign-in");
      } else {
        const redirectUrl = `${window.location.pathname}${window.location.search}`;
        navigate(redirectUrl);
      }
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    if (workspaceData && !workspaceError) {
      const { company_id, workspace_status, company_status } =
        workspaceData as Api.CheckWorkspaceCompanyResponse;
      if (workspace_status && company_status) {
        localStorage.setItem("checkedCompanyWorkspace", "true");
        setIsAuthenticated(true);
      } else {
        localStorage.setItem("checkedCompanyWorkspace", "false");
        if(!company_id && !workspace_status) {
          navigate("/walkthrough");
        }
      }
    }
  }, [workspaceData, navigate, isAuthenticated, workspaceError, checkedCompanyWorkspace]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/walkthrough"
          element={<Walkthrough mutate={mutateCheckCompanyWorkspace} />}
        />

        <Route
          path="/dashboard"
          element={<PrivateRoute component={<Dashboard />} />}
        />
        <Route
          path="/process"
          element={<PrivateRoute component={<Process />} />}
        />
        <Route
          path="/insights"
          element={<PrivateRoute component={<Insights />} />}
        />
        <Route
          path="/ai-chat"
          element={<PrivateRoute component={<AiChat />} />}
        />
        <Route
          path="/admin/*"
          element={<PrivateRoute component={<Admin />} />}
        />
        <Route
          path="/profile/*"
          element={<PrivateRoute component={<Profile />} />}
        />
        <Route
          path="/files/:id?"
          element={<PrivateRoute component={<Files />} />}
        />
        <Route
          path="/templates/:id?"
          element={<PrivateRoute component={<Templates />} />}
        />
        <Route
          path="/document-pdf/:id"
          element={
            <PrivateRoute
              isContainer={false}
              hasLayout={false}
              component={<DocumentPdf />}
            />
          }
        />
        <Route
          path="/document/:id?"
          element={
            <PrivateRoute
              isContainer={false}
              hasLayout={false}
              component={<DocumentDetail />}
            />
          }
        />
        <Route
          path="/review-agreement"
          element={
            <PrivateRoute
              isContainer={false}
              component={<ReviewAgreements />}
            />
          }
        />
        <Route
          path="/workspace/create"
          element={
            <PrivateRoute
              isContainer={false}
              hasLayout={false}
              component={<CreateWorkspace />}
            />
          }
        />
        <Route
          path="/user/invite"
          element={
            <PrivateRoute
              isContainer={false}
              hasLayout={false}
              component={<InviteUser />}
            />
          }
        />

        <Route path="*" element={<NotFoundPage />} />
        <Route path="/health" element={<HealthCheck />} />
        <Route path="/help" element={<HelpPage />} />
      </Routes>
    </>
  ); 
}