import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
} from "react";
import { jwtDecode } from "jwt-decode";
import { URLS } from "../constants/urls";
import { useFetchApi } from "../hooks/useApi";
import { useSearchParams } from "react-router-dom";

type AuthContextType = {
  user: Api.User | null;
  setUser: (user: Api.User | null) => void;
  hasRole: (roles: string[]) => boolean;
  isExternalUser: boolean;
  externalAccessToken: string | null;
  currentWorkspace: Api.Workspace | null;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<Api.User | null>(null);
  const workspaceIdFromLocalStorage = localStorage.getItem("wilma-workspaceId");
  const [searchParams] = useSearchParams();
  const token = localStorage.getItem("access_token") || searchParams.get("access_token");
  const externalAccessToken = searchParams.get("access_token")

  const isExternalUser = !!externalAccessToken;

  const { data: userData, error: userDataError } = useFetchApi<Api.User>(
    token ? URLS.auth : undefined,
    undefined,
    {
      revalidateOnFocus: false,
    }
  );

  const { data: workSpaceData, error: workspaceError } = useFetchApi<
    Api.Workspace[]
  >(
    (userData?.id && !externalAccessToken) ? URLS.user_workspace_list : undefined,
    { user_id: String(userData?.id) },
    {
      revalidateOnFocus: false,
    }
  );

  const currentWorkspace = useMemo(() => {
    if (!workSpaceData || workSpaceData.length === 0) {
      return null;
    }
    if (!workspaceIdFromLocalStorage) {
      return workSpaceData[0];
    }
    const workspaceId = parseInt(workspaceIdFromLocalStorage, 10);
    return workSpaceData.find(workspace => workspace.id === workspaceId) || workSpaceData[0];
  }, [workspaceIdFromLocalStorage, workSpaceData]);

  useEffect(() => {
    if (token && !externalAccessToken) {
      const decodedToken = jwtDecode<Api.User & { roles: string[] }>(token);
      setUser(decodedToken);
    }
  }, [token]);

  useEffect(() => {
    if (userData && workSpaceData) {
      const workspaceId: number | undefined = typeof workspaceIdFromLocalStorage === 'string' ? parseInt(workspaceIdFromLocalStorage, 10) : workSpaceData?.[0]?.id;
      const updatedUser = userData.workspace_id
        ? userData
        : { ...userData, workspace_id: workspaceId };
      setUser(updatedUser);
    } else {
      if(userData) setUser(userData);
    }
  }, [userData, workSpaceData, workspaceIdFromLocalStorage]);

  const hasRole = (roles: string[]) => {
    const token = localStorage.getItem("access_token");
    const decodedToken = token ? jwtDecode<{ roles: string[] }>(token) : null;
    return decodedToken
      ? roles.some((role) => decodedToken.roles.includes(role))
      : false;
  };

  return (
    <AuthContext.Provider value={{ user, hasRole, setUser, isExternalUser, externalAccessToken, currentWorkspace }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
