import React, { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSuccessContext } from "../../context/SuccessContext";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SuccessSnackbar = forwardRef((_, ref) => {
  const { successMessage, clearSuccess } = useSuccessContext();

  const handleClose = (
    event?: React.SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    clearSuccess();
  };

  return (
    <Snackbar
      open={!!successMessage}
      autoHideDuration={6000}
      onClose={handleClose as any}
    >
      <div>
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </div>
    </Snackbar>
  );
});

export default SuccessSnackbar;
