import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { WorkspaceEditForm } from "./WorkspaceEditForm";
import { WorkspaceUsers } from "./WorkspaceUsers";
import { useTranslation } from "react-i18next";

export const WorkspaceDetail = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">{t('Workspace Detail')}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ p: 3, height: 400, maxHeight: 400 }}>
            <Typography variant="subtitle2" sx={{ mb: 3 }}>
              {t('Edit Workspace')}
            </Typography>
            <WorkspaceEditForm />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ p: 3, height: 400, maxHeight: 400 }}>
            <Typography variant="subtitle2" sx={{ mb: 3 }}>
              {t('Workspace Users')}
            </Typography>
            <WorkspaceUsers />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
