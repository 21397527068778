import React from "react";
import { useParams } from "react-router-dom";
import { useFetchApi } from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import { Box, Chip } from "@mui/material";

export const AuditTrail = () => {
  const { id: documentId } = useParams<{ id: string }>();

  const { data: documentData, mutate } = useFetchApi<Api.AuditTrailResponse[]>(
    documentId ? `${URLS.document}/${documentId}/evidence-logs` : undefined,
    undefined
  );

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
      {documentData?.map((data, index) => {
        return (
          <Chip
            label={data.description}
            key={index}
            sx={{
              height: "100%",
              mb: 1,
              "& .MuiChip-label": { textWrap: "wrap", padding: "10px" },
            }}
          />
        );
      })}
    </Box>
  );
};
