import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Avatar from "@mui/material/Avatar";
import { ThemeProvider } from "@mui/material/styles";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import theme from "../../theme";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, Divider } from "@mui/material";
import { usePostApi } from "../../hooks/useApi";
import { useErrorContext } from "../../context/ErrorContext";
import { URLS } from "../../constants/urls";
import PasswordRequirementsChecklist from "../../components/PasswordCheckList";
import { MuiTelInput } from "mui-tel-input";
import OTP from "../../components/Otp";
import { CONFIRMATION_TYPES } from "../../constants/confirmationTypes";
import { useSuccessContext } from "../../context/SuccessContext";
import signInBg from "../../images/signin.jpg"
import { useTranslation } from "react-i18next";

const schema = Joi.object({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  job_title: Joi.string().required(),
  phone_number: Joi.string()
    .required()
    .regex(
      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "phone_number"
    ),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string()
    .min(8)
    .max(25)
    .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "password"),
});

export const SignUp = () => {
  const [searchParams] = useSearchParams();
  const [isConfirmationBox, setIsConfirmationBox] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const navigate = useNavigate();
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();
  const {
    postData: signUpPost,
    isLoading: isLoadingSignUp,
    error,
  } = usePostApi(URLS.sign_up);
  const {
    postData: confirmationPost,
    isLoading: isLoadingConfirmation,
    error: errorConfirmation,
  } = usePostApi(URLS.confirmation);
  const { handleSubmit, control, watch } = useForm({
    mode: "onSubmit",
    defaultValues: {
      first_name: "",
      last_name: "",
      job_title: "",
      phone_number: "",
      email: "",
      password: "",
    },
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (error || errorConfirmation) {
      showError((error?.message || errorConfirmation?.message) ?? "");
    }
  }, [error, errorConfirmation]);

  const onSubmit = useCallback(
    async (e: any) => {
      const response = await signUpPost({
        ...e,
        invite_token: searchParams.get("invite_token"),
      });

      if (response) {
        showSuccess(t('Please check your email for confirmation code'));
        setIsConfirmationBox(true);
      }
    },
    [error, signUpPost, navigate]
  );

  const onConfirmation = useCallback(async () => {
    const data = {
      email: watch("email") ?? "",
      confirmation_code: otp,
      confirmation_type: CONFIRMATION_TYPES.EMAIL_CONFIRM,
    };
    const response = await confirmationPost(data);

    if (response) {
      showSuccess(t('Confirmation successful'));
      navigate("/sign-in");
    }
  }, [otp, confirmationPost, navigate, showSuccess]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", backgroundImage: `url('${signInBg}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPositionX: 'left', backgroundPositionY: 'bottom'  }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box></Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            width: "100vw",

            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            padding: 5,
          }}
        >
          <Card
            sx={{
              borderRadius: 3,
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Box>
              <img
                src={require("../../images/logo.png")}
                width={130}
                height='auto'
              />
            </Box>
            <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main }}>
              <PermIdentityIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              {isConfirmationBox ? t('Confirmation') : t('Sign Up')}
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Divider sx={{ mb: 2, mt: 2 }} />
            </Box>
            {isConfirmationBox ? (
              <Box>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ justifyContent: "end", display: "flex" }}
                  >
                    <OTP
                      separator={<span>-</span>}
                      value={otp}
                      onChange={setOtp}
                      length={6}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ justifyContent: "end", display: "flex" }}
                  >
                    <Button
                      onClick={onConfirmation}
                      disabled={otp.length !== 6 || isLoadingConfirmation}
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 3 }}
                    >
                      {t('Continue')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <div>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="first_name"
                            label={t('First Name')}
                            autoComplete="first_name"
                            autoFocus
                            error={Boolean(errors.first_name)}
                            helperText={errors.first_name?.message}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <div>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="last_name"
                            label={t('Last Name')}
                            autoComplete="last_name"
                            error={Boolean(errors.last_name)}
                            helperText={errors.last_name?.message}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="job_title"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <div>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="job_title"
                            label={t('Job Title')}
                            autoComplete="job_title"
                            error={Boolean(errors.job_title)}
                            helperText={errors.job_title?.message}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <div>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('Email Address')}
                            autoComplete="email"
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <div>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 2,
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              type="password"
                              id="password"
                              label={t('Password')}
                              autoComplete="password"
                              error={Boolean(errors.password)}
                              helperText={errors.password?.message}
                              {...field}
                            />
                          </Box>
                          {field.value && (
                            <PasswordRequirementsChecklist
                              password={field.value}
                            />
                          )}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="phone_number"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <div>
                          <MuiTelInput
                            {...field}
                            margin="normal"
                            required
                            defaultCountry="SE"
                            fullWidth
                            error={Boolean(errors.phone_number)}
                            helperText={errors.phone_number?.message}
                            onChange={(e) => {
                              field.onChange(e.replace(/\s/g, ""));
                            }}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ justifyContent: "end", display: "flex" }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      size="large"
                      disabled={isLoadingSignUp}
                      variant="contained"
                      sx={{ mt: 3, mb: 3 }}
                    >
                      {t('Continue')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
