import React, { useEffect } from "react";
import { useFetchApi, usePostApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { useErrorContext } from "../../context/ErrorContext";
import { Controller, useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../../images/logo.png";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

const schemaInviteUserForm = Joi.object({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  workspace_ids: Joi.array().items(Joi.number()).min(1).required(),
  role: Joi.string().required(),
});

function getStyles(name: number, personName: number[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const InviteUser = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  const { postData, isLoading, error } = usePostApi(URLS.invite_user);
  const { data: workSpaceData, error: errorWorkspace } = useFetchApi<
    Api.Workspace[]
  >(
    user?.id ? URLS.user_workspace_list : undefined,
    { user_id: String(user?.id) },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: workspaceUserRoles } = useFetchApi<
    Api.GenericDropdownResponse[]
  >(URLS.workspace_user_roles);

  const { showError } = useErrorContext();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.InviteUserForm>({
    mode: "onSubmit",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      workspace_ids: [],
      role: "",
    },
    resolver: joiResolver(schemaInviteUserForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: Api.InviteUserForm) => {
    const response = await postData(data);
    if (response) {
      navigate("/admin/invited-users");
    }
  };

  const workspaceTypes = workSpaceData?.map((workspace) => ({
    value: workspace.id,
    label: workspace.name,
  }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          height: "60px",
          boxShadow: "none",
          borderBottom: "0.5px solid #e0e0e0",
        }}
      >
        <Box>
          <IconButton
            onClick={() => navigate("/admin/workspaces")}
            color="inherit"
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box>
          <img src={logo} alt="logo" width={130} height="auto" />
        </Box>
        <Box></Box>
      </AppBar>
      <Container sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Box
          component="form"
          noValidate
          sx={{ mt: 5, maxWidth: 500 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "flex-start" }}>
              <Typography variant="h5" align="left">
                {t('Invite a user')}
              </Typography>
              <Typography variant="caption" align="left" color="gray">
                {t('Please enter the requested information below.')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="first_name"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      fullWidth
                      id="first_name"
                      label={t('First Name')}
                      autoComplete="first_name"
                      autoFocus
                      error={Boolean(errors.first_name)}
                      helperText={errors.first_name?.message}
                      {...field}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="last_name"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      fullWidth
                      id="last_name"
                      label={t('Last Name')}
                      autoComplete="last_name"
                      error={Boolean(errors.last_name)}
                      helperText={errors.last_name?.message}
                      {...field}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="email"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label={t('Email Address')}
                      autoComplete="email"
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      {...field}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="workspace_ids"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <FormControl sx={{ width: "100%" }} error>
                      <InputLabel
                        id="demo-multiple-name-label"
                        error={Boolean(errors.workspace_ids)}
                      >
                        {t('Workspaces')}*
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        fullWidth
                        required
                        placeholder={t('Select Workspaces')}
                        label={t('Workspaces')}
                        aria-errormessage={errors.workspace_ids?.message}
                        input={<OutlinedInput label={t('Workspaces')} />}
                        error={Boolean(errors.workspace_ids)}
                        {...field}
                      >
                        {workspaceTypes?.map((type) => (
                          <MenuItem
                            key={type.value}
                            value={type.value}
                            style={getStyles(
                              Number(type.value),
                              field.value,
                              theme
                            )}
                          >
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.workspace_ids?.message && (
                        <FormHelperText>
                          {errors.workspace_ids?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="role"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      required
                      select
                      id="role"
                      fullWidth
                      label={t('Role')}
                      autoComplete="role"
                      error={Boolean(errors.role)}
                      helperText={errors.role?.message}
                      {...field}
                    >
                      {workspaceUserRoles?.map((role) => {
                        return (
                          <MenuItem value={role.value} key={role.value}>
                            {role.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, justifyContent: "end", display: "flex" }}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              disabled={isLoading}
            >
              {t('Invite User')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
