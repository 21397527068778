import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Joi from "joi";
import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDeleteApi,
  useFetchApi,
  usePatchApi,
  usePostApi,
} from "../../../../../../../../hooks/useApi";
import { URLS } from "../../../../../../../../constants/urls";
import { KeyedMutator } from "swr";
import { useErrorContext } from "../../../../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../../../../context/SuccessContext";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  id?: number;
  mutate: KeyedMutator<any>;
};

type FormTypes = {
  id?: number;
  colleague?: number | string;
  role: string;
  sign_method: string;
  sign_order: number | string;
};

const schemaAddColleagueForm = Joi.object({
  id: Joi.number().optional(),
  colleague: Joi.number().required().label("Colleague"),
  role: Joi.string().required().label("Role"),
  sign_method: Joi.string().required().label("Sign Method"),
  sign_order: Joi.number().required().label("Sign Order"),
}).unknown(true);

export const AddColleagueModal: FC<Props> = ({ open, onClose, id, mutate }) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { id: documentId } = useParams<{ id: string }>();
  const { user } = useAuth();

  const { data: documentUser } = useFetchApi<Api.DocumentUser>(
    id ? `${URLS.document_users}/${id}` : undefined,
    undefined
  );

  const { data: users, isLoading: isLoadingUser } = useFetchApi<
    Api.WorkspaceUsersDropdown[]
  >(
    user?.workspace_id
      ? `${URLS.workspace_users_dropdown}?workspace_id=${user?.workspace_id}`
      : undefined,
    undefined,
    { revalidateOnFocus: false }
  );
  const { data: roles, isLoading: isLoadingRoles } = useFetchApi<
    Api.GenericDropdownResponse[]
  >(URLS.document_user_roles, undefined, { revalidateOnFocus: false });
  const { data: signTypes, isLoading: isLoadingSignTypes } = useFetchApi<
    Api.GenericDropdownResponse[]
  >(URLS.document_user_sign_types, undefined, { revalidateOnFocus: false });

  const {
    postData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(`${URLS.document_users}`);

  const {
    patchData,
    isLoading: isLoadingPatch,
    error: patchError,
  } = usePatchApi(`${URLS.document_users}/${id}`);

  const {
    deleteData: deleteItemData,
    isLoading: isLoadingDeleteItem,
    error: errorDeleteItem,
  } = useDeleteApi<any>(URLS.document_users);

  useEffect(() => {
    if (errorDeleteItem) {
      showError(errorDeleteItem?.message);
    }
  }, [errorDeleteItem]);

  const { handleSubmit, control, reset } = useForm<FormTypes>({
    defaultValues: {
      id: id,
      colleague: documentUser?.user_id ?? "",
      role: documentUser?.role ?? "",
      sign_method: documentUser?.sign_method ?? "",
      sign_order: documentUser?.sign_order ?? "",
    },
    resolver: joiResolver(schemaAddColleagueForm),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (documentUser && signTypes && roles && users) {
      reset({
        id: id,
        colleague: documentUser.user_id,
        role: documentUser.role,
        sign_method: documentUser.sign_method,
        sign_order: documentUser.sign_order,
      });
    }
  }, [documentUser, signTypes, roles, users]);

  useEffect(() => {
    if (error || patchError) {
      showError(error?.message || patchError?.message || t('An error occurred'));
    }
  }, [error, patchError]);

  const onSubmit = async (data: FormTypes) => {
    const newData = {
      ...(id && { id: Number(data.id) }),
      document_id: Number(documentId),
      user_id: data.colleague,
      role: data.role,
      sign_method: data.sign_method,
      sign_order: data.sign_order,
    };

    const response = await (id ? patchData : postData)(newData);

    if (response) {
      reset();
      showSuccess(t('Colleague added successfully'));
      mutate();
      onClose();
    }
  };

  const deleteItem = async () => {
    const response = await deleteItemData(String(id));

    if (response) {
      onClose();
      mutate();
      showSuccess(t('Colleague deleted successfully'));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('Add Colleague')}
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <Controller
              name="colleague"
              defaultValue={documentUser?.user_id}
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    select
                    size="small"
                    id="colleague"
                    fullWidth
                    label={t('Colleague')}
                    autoComplete="colleague"
                    error={Boolean(errors.colleague)}
                    helperText={errors.colleague?.message}
                    {...field}
                  >
                    {(users ?? []).map((user) => (
                      <MenuItem value={user.id} key={user.id}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="body2">{user.name}</Typography>
                          <Typography variant="caption">
                            {user.email}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <div>
                      <TextField
                        required
                        select
                        size="small"
                        id="role"
                        fullWidth
                        label={t('Role')}
                        autoComplete="role"
                        error={Boolean(errors.role)}
                        helperText={errors.role?.message}
                        {...field}
                      >
                        {(roles ?? []).map((role) => (
                          <MenuItem value={role.value} key={role.value}>
                            {role.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="sign_method"
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <div>
                      <TextField
                        required
                        select
                        size="small"
                        id="sign_method"
                        fullWidth
                        label={t('Sign Method')}
                        autoComplete="sign_method"
                        error={Boolean(errors.sign_method)}
                        helperText={errors.sign_method?.message}
                        {...field}
                      >
                        {(signTypes ?? []).map((signType) => (
                          <MenuItem
                            value={signType.value}
                            key={signType.value}
                          >
                            {signType.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                />
              </Grid>
            </Grid>

            <Box>
              <Controller
                name="sign_order"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <div>
                    <TextField
                      {...field}
                      size="small"
                      id="sign_order"
                      type="number"
                      label={t('Sign Order')}
                      InputLabelProps={{
                        shrink: field?.value ? true : false,
                      }}
                    />
                  </div>
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: id ? "space-between" : "end",
              alignItems: "center",
            }}
          >
            {id && (
              <Box>
                <Button onClick={deleteItem} variant="outlined" color="error">
                  <DeleteIcon />
                  {t('Remove Colleague')}
                </Button>
              </Box>
            )}
            <Box>
              <Button onClick={onClose} sx={{ mr: 1 }}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 1 }}
                disabled={isLoadingPost || isLoadingPatch}
              >
                {t('Add Colleague')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
