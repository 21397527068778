import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Tabs, Tab, Card } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useFetchApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { Detail } from "./Detail";
import { useErrorContext } from "../../context/ErrorContext";
import { useTranslation } from "react-i18next";

export const Profile = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showError } = useErrorContext();
  const { setUser } = useAuth();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("/profile/detail");

  const { data: userData, error } = useFetchApi<Api.User>(
    URLS.auth,
    undefined,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (pathname === "/profile") {
      navigate("/profile/detail");
    }
  }, [pathname, navigate]);

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab
          label={t('Detail')}
          value="/profile/detail"
          to="/profile/detail"
          component={Link}
        />
      </Tabs>
      <Routes>
        <Route
          path={`/detail`}
          element={
            <Card sx={{ p: 3, mt: 2 }} elevation={3}>
              <Detail />
            </Card>
          }
        />
      </Routes>
    </>
  );
};
