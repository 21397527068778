import {
  Box,
  Button,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { FC } from "react";
import { KeyedMutator } from "swr";
import { useAuth } from "../../../context/AuthContext";
import CheckIcon from '@mui/icons-material/Check';
import { useSignDocument } from "../../../hooks/useSignDocument";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const instructionsStyle = {
  backgroundColor: "#ecf9f3",
  padding: "16px",
  borderRadius: "8px",
  marginBottom: "16px",
};

type Props = {
  open: boolean;
  onClose: () => void;
  mutate: KeyedMutator<any>;
};

export const SignDocumentModal: FC<Props> = ({ open, onClose, mutate }) => {
  const { user, isExternalUser } = useAuth();
  const { t } = useTranslation();
  const {
    handleSignDocument,
    isLoading: isLoadingPost,
    error,
    companyData} = useSignDocument(mutate, onClose)

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" align="center" sx={{ mb: 2 }}>
          {t('Document signing')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, textAlign: 'center' }}>
          {t('The document can be modified until all signatories have signed. If modified, you will need to sign again.')}
        </Typography>
        <Box sx={instructionsStyle}>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary={`${t('You are')} ${user?.first_name} ${user?.last_name} ${!isExternalUser ? `${t('from')} ${companyData?.name}` : ''}`} 
              />
            </ListItem>
            {!isExternalUser && (
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText 
                  primary={`${t('You are authorized to sign on behalf of')} ${companyData?.name}`} 
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={t('You have read and understood this document and intend to sign it.')} />
            </ListItem>
          </List>
        </Box>
        <Button
          variant="contained"
          sx={{ mt: 1, mx: 'auto', display: 'block' }}
          disabled={isLoadingPost}
          onClick={handleSignDocument}
        >
          {t('Send')}
        </Button>
      </Box>
    </Modal>
  );
};
