import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useFetchApi, usePatchApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useErrorContext } from "../../context/ErrorContext";
import { useSuccessContext } from "../../context/SuccessContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

type FormTypes = {
  name: string;
  description: string;
};

const schemaUpdateDocumentForm = Joi.object({
  description: Joi.optional(),
  name: Joi.optional(),
}).unknown(true);

export const DocumentPdf = () => {
  const { id: documentId } = useParams<{ id: string }>();
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();

  const { data: documentData, mutate } = useFetchApi<Api.Document>(
    `${URLS.document}/${documentId}`,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    patchData,
    isLoading: isLoadingPatch,
    error,
  } = usePatchApi(`${URLS.document}/${documentId}`);

  const { data: documentUrlData, isLoading: isLoadingDocumentUrl } =
    useFetchApi<{ public_url: string }>(
      `${URLS.document}/${documentId}/public-data`,
      undefined,
      { revalidateOnFocus: false }
    );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: joiResolver(schemaUpdateDocumentForm),
  });

  useEffect(() => {
    if (documentData) {
      reset({
        name: documentData.name,
        description: documentData.description,
      });
    }
  }, [documentData]);

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: FormTypes) => {
    const response = await patchData({
      ...data,
    });
    if (response) {
      reset();
      mutate();
      showSuccess(t('Document added successfully'));
    }
  };

  if (isLoadingDocumentUrl) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingDocumentUrl}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            flexShrink: 0,
            height: "4.5rem",
            padding: "0px 1rem",
            backgroundColor: "rgb(255, 255, 255)",
            flexDirection: "row",
            mt: 1,
            mb: 1,
          }}
        >
          <Box
            component="form"
            noValidate
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box>
                <IconButton onClick={() => window.history.back()}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Box sx={{ gap: 2, display: "flex" }}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <div>
                      <TextField
                        size="medium"
                        id="name"
                        fullWidth
                        sx={{ width: "300px" }}
                        label={t('Document Name')}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...field}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({ field, formState: { errors } }) => (
                    <div>
                      <TextField
                        size="medium"
                        sx={{ width: "300px" }}
                        id="description"
                        fullWidth
                        label={t('Document Description')}
                        {...field}
                      />
                    </div>
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  onClick={() =>
                    reset({
                      name: documentData?.name,
                      description: documentData?.description,
                    })
                  }
                  size="large"
                  sx={{ mr: 1 }}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  disabled={isLoadingPatch}
                >
                  {t('Update')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 0%",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          <iframe
            src={documentUrlData?.public_url}
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
