import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFetchApi } from './useApi';
import { URLS } from "../constants/urls";
import { useTranslation } from 'react-i18next';

type TransformedMonthlyCreatedDocument = {
  month: string;
  Count: number;
};

type TransformedDocumentPerType = {
  type: string;
  Count: number;
};

type TransformedDocumentPerStatus = {
  status: string;
  Count: number;
};

type TransformedDocumentPerUser = {
  user_id: number;
  Count: number;
  first_name: string;
  last_name: string;
  email: string;
  is_deleted: boolean;
};

type TransformedApiResponse = {
  monthly_created_documents: TransformedMonthlyCreatedDocument[];
  document_per_type: TransformedDocumentPerType[];
  document_per_status: TransformedDocumentPerStatus[];
  document_per_user: TransformedDocumentPerUser[];
};

interface UseDashboardData {
  isLoading: boolean;
  insights: TransformedApiResponse | undefined;
}

export const useInsights = (): UseDashboardData => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { data, isLoading, mutate } = useFetchApi<Api.DocumentInsights>(
    user?.workspace_id
      ? `${URLS.document_insights}?workspace_id=${user?.workspace_id}`
      : undefined,
    undefined,
    { revalidateOnFocus: false }
  );

  const insights: TransformedApiResponse | undefined = useMemo(() => {
    if (!data) return undefined;
  
    const capitalizeFirstLetter = (text: string) => {
      return text.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };
  
    return {
      monthly_created_documents: data.monthly_created_documents.map(doc => ({
        month: doc.month.substring(0, 7),
        Count: doc.count,
      })),
      document_per_type: data.document_per_type.map(doc => ({
        type: t(`${capitalizeFirstLetter(doc.type)}`),
        Count: doc.count,
      })),
      document_per_status: data.document_per_status.map(doc => ({
        status: t(`${capitalizeFirstLetter(doc.status)}`),
        Count: doc.count,
      })),
      document_per_user: data.document_per_user.map(doc => ({
        user_id: doc.user_id,
        Count: doc.count,
        first_name: doc.first_name,
        last_name: doc.last_name,
        email: doc.email,
        is_deleted: doc.is_deleted,
      })),
    };
  }, [data, t]);
  

  return {
    isLoading,
    insights,
  };
};
