import React from 'react';
import { Box, Card, CardContent, CardActionArea, Typography, Grid } from '@mui/material';

interface ProcessCardProps {
    leftLabel: string;
    leftIcon: React.ReactNode;
    hasRightIcon: boolean;
    onClick?: () => void;
}

const ProcessCard: React.FC<ProcessCardProps> = ({ leftLabel, leftIcon, hasRightIcon, onClick}) => {
    return (
        <Card sx={{backgroundColor: "#083F4D", width: '250px', borderRadius: '30px'}}>
            <CardActionArea onClick={onClick}>
                <CardContent>
                    <Box sx={{display: 'flex', justifyContent: 'center', }}>
                        {leftIcon}
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant="h5" color="white">
                                {leftLabel}
                            </Typography>
                            {hasRightIcon && <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid white', borderRadius: '50%', marginTop: '-18px', marginLeft: '5px', color: 'white', height: '18px', width: '18px', fontSize: '8px'}}>AI</Box>}
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default ProcessCard;
