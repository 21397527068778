import React, { FC, PropsWithChildren, useEffect, MouseEvent } from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CreateIcon from '@mui/icons-material/Create';
import GroupsIcon from '@mui/icons-material/Groups';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import theme from "../../theme";
import logo from "../../images/logo.png";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Container,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { UserInfoDrawer } from "../UserInfoDrawer";
import { useAuth } from "../../context/AuthContext";
import HelpIcon from "@mui/icons-material/Help";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import LanguageSwitcher from "../LayoutSwitcher";
import { capitalizeFirstLetter } from "../../utils";
import { useTranslation } from "react-i18next";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#ffffff",
    color: "#012749",
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
      "& .MuiListSubheader-root": {
        display: "none",
      },
      "& .new-document-button": {
        minWidth: "auto !important",
        justifyContent: "center",
      },
      "& .new-document-text": {
        display: "none",
      },
      "& img": {
        display: "none",
      },
      "& .MuiSvgIcon-root": {
        color: "#012749",
      },
    }),
  },
}));

type Language = "en" | "tr";

export const Layout: FC<PropsWithChildren & { isContainer?: boolean }> = ({
  children,
  isContainer,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [pathNameState, setPathNameState] = React.useState<any>();
  const [isMenu, setIsMenu] = React.useState(false);
  const [whichNav, setWhichNav] = React.useState<"top" | "bottom">("top");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, currentWorkspace } = useAuth();

  const firstNavItems = [
    {
      header: t('Discover'),
      items: [
        {
          name: t('Dashboard'),
          path: "/dashboard",
          icon: <DashboardIcon />,
        }
      ],
    },
    {
      header: t('Tools'),
      items: [
        {
          name: t('Legal Insight'),
          path: "/ai-chat",
          icon: <QuestionAnswer />,
        },
        {
          name: t('Contract Review'),
          path: "/review-agreement",
          icon: <FindInPageIcon />,
        },
        {
          name: t('Collaboration'),
          path: "/document",
          icon: <GroupsIcon />,
        },
        // {
        //   name: "E-sign (Upcoming)",
        //   path: "/e-sign",
        //   icon: <CreateIcon />,
        // },
      ]
    },
    // {
    //   header: "",
    //   items: [
    //     {
    //       name: "Insights",
    //       path: "/insights",
    //       icon: <BarChartOutlinedIcon />,
    //     },
    //   ],
    // },
    {
      header: t('Documents'),
      items: [
        {
          name: t('Files'),
          path: "/files",
          icon: <FolderIcon />,
        },
        {
          name: t('Templates'),
          path: "/templates",
          icon: <SummarizeIcon />,
        },
      ],
    },
  ];
  
  const bottomNavItems = [
    {
      header: t('Settings'),
      items: [
        {
          name: t('Users & Teams'),
          path: "/admin/account-overview",
          icon: <AdminPanelSettingsIcon />,
        },
        {
          name: t('Account'),
          path: "/profile/detail",
          icon: <SettingsIcon />,
        },
        {
          name: t('Help'),
          path: "/help",
          icon: <HelpIcon />,
        },
      ],
    },
  ];

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setPathNameState(pathname);
  }, [pathname]);

  return (
    (<ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          color="transparent"
          sx={{ boxShadow: "none" }}
        >
          <Toolbar
            sx={{
              pr: "24px",
              justifyContent: "flex-end"
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
                paddingLeft: open ? '' : '12px'
              }}
            >
              <MenuIcon sx={{ color: "#012749" }} />
            </IconButton>
      
            <div style={{ marginLeft: open ? "0" : "auto" }}>
              <LanguageSwitcher/>
              <Tooltip title={t('Account settings')}>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => setIsMenu(!isMenu)}
                  sx={{ textTransform: "none" }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "primary.main",
                      }}
                    >
                      {currentWorkspace?.name?.charAt(0)}
                    </Avatar>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Typography variant="subtitle2" sx={{marginRight: '10px'}}>
                        {currentWorkspace?.name}
                      </Typography>
                      <ExpandMoreIcon/>
                    </Box>
                  </Box>
                </Button>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            height: "100vh",
            boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              px: [1],
            }}
          >
            <IconButton
              onClick={toggleDrawer}
              sx={{ ...(!open && { display: "none" }) }}
            >
              <ChevronLeftIcon sx={{ color: "#012749" }} />
            </IconButton>
          </Toolbar>

          <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <img src={logo} alt="logo" width={open ? 150 : 40} height='auto' style={{cursor: 'pointer'}} onClick={() => navigate('/dashboard')} />
          </Box>

          {/* <Box
            sx={{
              p: 2,
              alignItems: "center",
              display: "flex",
              justifyContent: open ? "center" : "start",
              paddingLeft: open ? '' : '10px',
            }}
            className="new-document-box"
          >
            <Button
              onClick={() => navigate("/process")}
              variant="contained"
              className="new-document-button"
              size="medium"
              sx={{
                borderRadius: "20px",
                gap: 1,
                background: '#012749',
                color: 'white',
                marginLeft: open ? "0" : "auto",
                "&:hover": {
                  background: '#012749',
                  color: 'white',
                },
              }}
              fullWidth={open}
            >
              <AddIcon fontSize="small" />
              <Typography className="new-document-text">
                New Process
              </Typography>
            </Button>

          </Box> */}

          <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />

          <List component="nav">
            <React.Fragment>
              {firstNavItems.map((navItem, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListSubheader component="div" sx={{ background: '#ffffff', color: "#012749" }}>
                      {navItem.header}
                    </ListSubheader>
                    {navItem.items.map((item, index) => {
                      return (
                        <ListItemButton
                          onClick={() => {
                            navigate(item.path);
                            setWhichNav("top");
                          }}
                          disabled={item.name === 'E-sign (Upcoming)'}
                          selected={pathname.includes(item.path)}
                          key={index}
                          sx={{
                            pl: open ? 4 : '23px',
                            "&.Mui-selected": {
                              backgroundColor: "#f0f0f0",
                              "&:hover": {
                                backgroundColor: "primary.main",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "primary.main",
                              "& .MuiListItemIcon-root": {
                                color: "white",
                              },
                              "& .MuiTypography-body2": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <ListItemIcon sx={{ 
                            color: "#8598ad",
                            "&.Mui-selected": {
                              "&:hover": {
                                color: "white",
                              },
                            },
                            }}>{item.icon}</ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ 
                                color: "#8598ad", 
                                "&:hover": {
                                  color: "white",
                                }
                              }}>
                                {item.name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          </List>
          <List component="nav" sx={{ marginTop: "auto", marginBottom: 2 }}>
            <Divider sx={{ marginBottom: 3, borderColor: "rgba(255, 255, 255, 0.12)" }} />
            {bottomNavItems.map((navItem, index) => {
              return (
                <React.Fragment key={index}>
                  <ListSubheader component="div" sx={{ background: '#ffffff', color: "#012749" }}>
                    {navItem.header}
                  </ListSubheader>
                  {navItem.items.map((item, index) => {
                    return (
                      <ListItemButton
                        onClick={() => {
                          navigate(item.path);
                          setWhichNav("bottom");
                        }}
                        selected={pathname.includes(item.path)}
                        key={index}
                        sx={{
                          pl: open ? 4 : '23px',
                          "&.Mui-selected": {
                            backgroundColor: "primary.main",
                            "&:hover": {
                              backgroundColor: "#e0e0e0",
                            },
                          },
                          "&:hover": {
                            backgroundColor: "primary.main",
                            "& .MuiListItemIcon-root": {
                              color: "white",
                            },
                            "& .MuiTypography-body2": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <ListItemIcon sx={{ color: "#8598ad" }}>{item.icon}</ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2" sx={{ 
                              color: "#8598ad", 
                              "&:hover": {
                                color: "white",
                              }
                            }}>
                              {item.name}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            background: "#F8F8F8",
            flexGrow: 1,
            flex: 1,
            overflow: "auto",
            display: "flex",
            backgroundSize: "cover",
            flexDirection: "column",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl">
            <Box
              sx={{
                mb: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                {/* TODO: href değişecek. */}
                <Link underline="hover" color="inherit" href="/">
                  <Typography variant="subtitle2">Agreemind</Typography>
                </Link>
                {pathNameState
                  ?.replace(/^\/|\/$/g, "")
                  .split("/")
                  .map((path: any, index: number) => {
                    return (
                      (<Link
                        underline="hover"
                        color={
                          index ===
                          pathNameState?.replace(/^\/|\/$/g, "").split("/")
                            .length -
                            1
                            ? "black"
                            : "inherit"
                        }
                        sx={{
                          pointerEvents: "none",
                        }}
                        href={pathNameState}
                        key={index}
                      >
                        <Typography
                          variant="subtitle2"
                          textTransform="capitalize"
                        >
                          {t(`${capitalizeFirstLetter(path.replace(/-/g, " "))}`)}
                        </Typography>
                      </Link>)
                    );
                  })}
              </Breadcrumbs>
            </Box>
          </Container>
          {isContainer ? (
            <Container
              sx={{
                flexGrow: 1,
                flex: 1,
                overflow: "auto",
                height: "100%",
                paddingTop: "10px",
              }}
              maxWidth="xl"
            >
              {children}
            </Container>
          ) : (
            children
          )}
        </Box>
      </Box>
      <UserInfoDrawer open={isMenu} onClose={() => setIsMenu(false)} />
    </ThemeProvider>)
  );
};
