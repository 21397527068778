import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Joi from "joi";
import React, { FC, useEffect } from "react";
import { KeyedMutator } from "swr";
import { useErrorContext } from "../../../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../../../context/SuccessContext";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams } from "react-router-dom";
import { useFetchApi, usePostApi } from "../../../../../../../hooks/useApi";
import { URLS } from "../../../../../../../constants/urls";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  onClose: () => void;
  mutate: KeyedMutator<any>;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const schemaWorkspaceUserForm = Joi.object({
  workspace_id: Joi.optional(),
  user_id: Joi.number().required(),
  role: Joi.string().required(),
}).unknown(true);

export const AddUserModal: FC<Props> = ({ open, onClose, mutate }) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();
  const { id: workspaceId } = useParams<{ id: string }>();

  const { data: userRoles } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.workspace_user_roles,
    undefined,
    { revalidateOnFocus: false }
  );

  const { data: userDropdown } = useFetchApi<Api.GenericDropdownResponse[]>(
    URLS.company_user_dropwdown,
    undefined,
    { revalidateOnFocus: false }
  );

  const {
    postData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(URLS.workspace_user);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.WorkspaceUserForm>({
    mode: "onSubmit",
    defaultValues: {
      workspace_id: Number(workspaceId),
      user_id: undefined,
      role: "",
    },
    resolver: joiResolver(schemaWorkspaceUserForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const onSubmit = async (data: Api.WorkspaceUserForm) => {
    const response = await postData(data);
    if (response) {
      reset();
      mutate();
      showSuccess("User added successfully");
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add User
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="caption"
          sx={{ mt: 2 }}
        >
          Add user to this workspace.
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <Controller
              name="role"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    select
                    size="small"
                    id="role"
                    fullWidth
                    label={t('Role')}
                    autoComplete="role"
                    error={Boolean(errors.role)}
                    helperText={errors.role?.message}
                    {...field}
                  >
                    {userRoles?.map((role) => {
                      return (
                        <MenuItem value={role.value} key={role.value}>
                          {role.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            />
            <Controller
              name="user_id"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    select
                    size="small"
                    id="user_id"
                    fullWidth
                    label="User"
                    autoComplete="user_id"
                    error={Boolean(errors.user_id)}
                    helperText={errors.user_id?.message}
                    {...field}
                  >
                    {userDropdown?.map((user) => {
                      return (
                        <MenuItem value={user.value} key={user.value}>
                          {user.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Box>
              <Button onClick={onClose} sx={{ mt: 1, mr: 1 }}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 1 }}
                disabled={isLoadingPost}
              >
                {t('Add')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
