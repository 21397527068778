import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLanguage, useSetLanguage } from '../../stores/i18n';

export type Language = 'en' | 'tr';

const languageOptions: { [key in Language]: { flag: string; label: string } } = {
  en: { flag: '🇬🇧', label: 'English' },
  tr: { flag: '🇹🇷', label: 'Turkish' },
};

const LanguageSwitcher = () => {
  const language = useLanguage();
  const setLanguage = useSetLanguage();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLanguageMenu, setIsLanguageMenu] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsLanguageMenu(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsLanguageMenu(false);
  };

  const handleLanguageChange = (lang: Language) => {
    setLanguage(lang);
    handleMenuClose();
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        onClick={handleMenuOpen}
        sx={{ textTransform: 'none' }}
      >
        <Typography variant="subtitle2" sx={{ marginRight: '5px' }}>
          {languageOptions[language].flag}
        </Typography>
        <Typography variant="subtitle2" sx={{ marginRight: '5px' }}>
          {languageOptions[language].label}
        </Typography>
        <ExpandMoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={isLanguageMenu} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleLanguageChange('en')}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <Typography variant="subtitle2">🇬🇧</Typography>
          </ListItemIcon>
          English
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange('tr')}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <Typography variant="subtitle2">🇹🇷</Typography>
          </ListItemIcon>
          Turkish
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSwitcher;
