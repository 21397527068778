import React, { useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import ErrorPage from './ErrorPage';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <ErrorPage resetErrorBoundary={resetErrorBoundary} />
  );
};

const ErrorBoundary: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [key, setKey] = useState(0);

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        setKey(prevKey => prevKey + 1);
      }}
    >
      <React.Fragment key={key}>
        {children}
      </React.Fragment>
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
