import { useState, useEffect } from 'react';
import { useErrorContext } from '../context/ErrorContext';
import { useFetchApi } from '../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { URLS } from '../constants/urls';
import { SelectChangeEvent } from "@mui/material";


type UserInfoDrawerData = {
  userData: Api.User | undefined;
  workSpaceData: Api.Workspace[] | undefined;
  workspace: number | undefined;
  handleSetWorkspace: (e: SelectChangeEvent<number>) => void;
  logout: () => void;
};

export const useUserInfoDrawer = ():UserInfoDrawerData => {
  const { showError } = useErrorContext();
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [workspace, setWorkspace] = useState<number | undefined>();
  const workspaceIdFromLocalStorage = parseInt(localStorage.getItem('wilma-workspaceId') ?? '', 10);

  const { data: userData, error: userError } = useFetchApi<Api.User>(URLS.auth, undefined, {
    revalidateOnFocus: false,
  });

  const { data: workSpaceData, error: workspaceError } = useFetchApi<Api.Workspace[]>(
    userData?.id ? URLS.user_workspace_list : undefined,
    { user_id: String(userData?.id) },
    {
      revalidateOnFocus: false,
    }
  );

  const handleSetWorkspace = (e: SelectChangeEvent<number>) => {
    const workspaceValue: number = typeof e.target.value === 'string' ? parseInt(e.target.value, 10) : e.target.value;
    setWorkspace(workspaceValue);
    localStorage.setItem("wilma-workspaceId", JSON.stringify(workspaceValue));
    if (user) {
      setUser({
        ...user,
        workspace_id: workspaceValue,
      });
    }
  };

  useEffect(() => {
    if (userError || workspaceError) {
      showError(userError?.message || workspaceError?.message);
    }
  }, [userError, workspaceError]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (!workspace) {
      setWorkspace(workspaceIdFromLocalStorage || workSpaceData?.[0]?.id);
      if (userData) {
        setUser({ ...userData, workspace_id: workSpaceData?.[0]?.id });
      }
    }
  }, [workspace, workSpaceData, userData]);

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('wilma-workspaceId');
    localStorage.removeItem('checkedCompanyWorkspace');
    navigate('/sign-in');
  };

  return {
    userData,
    workSpaceData,
    workspace,
    handleSetWorkspace,
    logout,
  };
};
