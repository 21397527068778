import React from 'react';
import { Card, CardContent, Typography, Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { Message } from '../../../../../../types/message';
import { formatDateTime } from '../../../../../../utils';

interface MessageCardProps {
  message: Message;
}

const CardContainer = styled(Card)({
  width: '100%',
  marginBottom: '10px',
  borderRight: '1px solid #f2f2f2',
  borderLeft: '1px solid #f2f2f2',
  borderRadius: '8px',
});

const BadgeContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #ccc',
  borderRadius: '6px',
  padding: '2px 8px',
  marginBottom: '10px',
  width: 'fit-content'
});

const MessageCard: React.FC<MessageCardProps> = ({ message }) => {
  const initials = message.participant_detail?.first_name[0] + message.participant_detail?.last_name[0];

  return (
    <CardContainer>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ width: 32, height: 32, fontSize: '12px' }}>{initials}</Avatar>
          <Box ml={2}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{`${message.participant_detail.first_name} ${message.participant_detail.last_name}`}</Typography>
            </Box>
            <Typography variant="body2">{formatDateTime(message.created_at)}</Typography>
          </Box>
        </Box>
        {message.type === 'internal' ? (
          <BadgeContainer>
            <LockOutlinedIcon sx={{ color: '#204b5c', fontSize: '14px' }} />
            <Typography variant="caption" ml={1} color="#204b5c">Internal</Typography>
          </BadgeContainer>
        ) : (
          <BadgeContainer>
            <PublicIcon sx={{ color: '#204b5c', fontSize: '14px' }} />
            <Typography variant="caption" ml={1} color="#204b5c">Public</Typography>
          </BadgeContainer>
        )}
        <Typography variant="body1" gutterBottom>
          {message.message}
        </Typography>
      </CardContent>
    </CardContainer>
  );
};

export default MessageCard;
