import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Message } from '../types/message';
import { useFetchApi, usePostApi } from './useApi';
import { URLS } from "../constants/urls";

interface SuggestedUser {
  id: number;
  name: string;
  email: string;
}

interface UseCollaborationData {
  tabIndex: number;
  messages: Message[];
  newMessage: string;
  ws: WebSocket | null;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  handleNewMessageChange: (value: string) => void;
  handleSendMessage: () => void;
  handleKeyDown: (event: React.KeyboardEvent) => void;
  handleSelectUser: (user: SuggestedUser) => void;
  suggestions: SuggestedUser[];
  fetchSuggestions: (query: string) => void;
  suggestionsLoading: boolean;
  showSuggestions: boolean;
}

export const useCollaboration = (): UseCollaborationData => {
  const [tabIndex, setTabIndex] = useState(0);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [ws, setWs] = useState<WebSocket | null>(null);
  const { user, isExternalUser, externalAccessToken } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [query, setQuery] = useState<string | null>(null);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [hasSelectedUser, setHasSelectedUser] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<SuggestedUser[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<number | null>(null);

  const { data: suggestionsRaw = [], mutate: mutateMentions } = useFetchApi<
    Api.DocumentUserDropdown[]
  >(
    user?.workspace_id
      ? `${URLS.document_users_dropdown}?document_id=${id}&search=${query}`
      : undefined,
    undefined,
    { revalidateOnFocus: false }
  );

  const { postData: postDocumentMention, error: errorDocumentMention } =
    usePostApi(`${URLS.document_mentions}`);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const suggestions = useMemo(() => {
    return suggestionsRaw.map(participant => ({
      id: participant.participant_detail.id,
      name: `${participant.participant_detail.first_name} ${participant.participant_detail.last_name}`,
      email: participant.participant_detail.email
    }));
  }, [suggestionsRaw]);

  const handleSelectUser = (user: SuggestedUser) => {
    const messageParts = newMessage.split('@');
    const newMessageWithUser = `${messageParts.slice(0, -1).join('@')}@${user.name} `;
    setNewMessage(newMessageWithUser);
    setShowSuggestions(false);
    setHasSelectedUser(true);

    // Add user to selectedUsers array if not already included
    setSelectedUsers((prevSelectedUsers) => {
      if (!prevSelectedUsers.find((u) => u.id === user.id)) {
        return [...prevSelectedUsers, user];
      }
      return prevSelectedUsers;
    });
  };

  const fetchSuggestions = (query: string) => {
    setSuggestionsLoading(true);
    setQuery(query);
  };

  const removeNames = (str: string, array: SuggestedUser[]) => {
    return array.reduce((acc, { name: name }) => {
      const regex = new RegExp(`@${name}`, 'g');
      return acc.replace(regex, '');
    }, str).trim();
  };
  
  useEffect(() => {
    if (newMessage.includes('@') && !hasSelectedUser) {
      const checkIfWithAt = removeNames(newMessage, selectedUsers);
      const query = newMessage.split('@').pop();
      if (query && checkIfWithAt.includes('@')) {
        fetchSuggestions(query);
        setShowSuggestions(true);
        setHasSelectedUser(false);
      }
    } else {
      setShowSuggestions(false);
    }
  }, [newMessage]);

  useEffect(() => {
    if(suggestionsRaw.length > 0) {
      setSuggestionsLoading(false);
    }
    else {
      setSuggestionsLoading(true);
    }
  }, [suggestionsRaw])

  useEffect(() => {
    if (query) {
      const debounceTimeout = setTimeout(() => {
        mutateMentions().then(() => {
          setSuggestionsLoading(false);
        });
      }, 1000);
      return () => clearTimeout(debounceTimeout);
    }
  }, [query, mutateMentions]);

  const handleNewMessageChange = (value: string) => {
    setNewMessage(value);
    setHasSelectedUser(false);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = window.setTimeout(() => {
      if (value.includes('@')) {
        const query = value.split('@').pop();
        if (query) {
          fetchSuggestions(query);
        }
      }
    }, 500);

    setTypingTimeout(newTypingTimeout);
  };

  const messagesMemo = useMemo(() => {
    return (messages ?? []).slice().sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  }, [messages]);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const wsUrl = `${process.env.REACT_APP_SOCKET_API_URL}/document-chats/ws/${id}?token=${accessToken || externalAccessToken}&is_external_user=${isExternalUser}`;

    const websocket = new WebSocket(wsUrl);

    websocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    websocket.onmessage = (event) => {
      try {
        const dataResponse = JSON.parse(event.data);
        const { data } = dataResponse;
        if (Array.isArray(data)) setMessages(data);
        else setMessages((prevMessages) => [...prevMessages, data]);
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    websocket.onclose = (event) => {
      if (event.wasClean) {
        console.log(`WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`);
      } else {
        console.error('WebSocket connection closed unexpectedly');
      }
    };

    setWs(websocket);

    return () => {
      websocket.close();
    };
  }, [id]);

  const handleSendMessage = async() => {
    if (!newMessage || newMessage.trim() === '') {
      console.error('Message cannot be empty.');
      return;
    }

    if (ws && ws.readyState === WebSocket.OPEN) {
      const payload = {
        document_id: id,
        user_id: user?.id,
        message: newMessage,
      };

      ws.send(JSON.stringify(payload));

      // Send document mention requests to each selected user
      await Promise.all(selectedUsers.map(async (selectedUser) => {
        await postDocumentMention({
          document_id: id,
          mentioned_user_id: selectedUser.id,
          message: newMessage,
          user_id: user?.id,
        });
      }));

      setNewMessage('');
      setSelectedUsers([]); // Clear selected users after sending message
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return {
    tabIndex,
    messages: messagesMemo,
    newMessage,
    ws,
    handleTabChange,
    handleNewMessageChange,
    handleSendMessage,
    handleKeyDown,
    suggestions,
    fetchSuggestions,
    suggestionsLoading,
    handleSelectUser,
    showSuggestions
  };
};

