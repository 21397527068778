import React from 'react';
import { List, ListItem } from '@mui/material';
import MessageCard from './MessageCard';
import { Message } from '../../../../../../types/message';

interface ChatsTabProps {
  messages: Message[];
}

const ChatsTab: React.FC<ChatsTabProps> = ({ messages }) => {

  return (
    <List>
      {messages.map((message) => (
        <ListItem sx={{padding: 0}} key={message.id}>
          <MessageCard
            message={message}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ChatsTab;
