import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SettingIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import Tooltip from "@mui/material/Tooltip";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import HistoryIcon from "@mui/icons-material/History";
import { Box } from "@mui/material";
import { AuditTrail } from "./Tabs/AuditTrail";
import { ParticipantSettings } from "./Tabs/ParticipantSettings";
import { DocumentSettings } from "./Tabs/DocumentSettings";
import Collaboration  from "./Tabs/Collaboration";
import { useTranslation } from "react-i18next";

export const DocumentSettingsTabs = () => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          borderColor: "divider",
          display: "flex",
          flexShrink: "0",
          justifyContent: "space-evenly",
          height: "60px",
          alignItems: "flex-end",
          borderBottom: "1px solid #d6dfe2",
          marginTop: "0",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon tabs example"
          sx={{ justifyContent: "space-between" }}
        >
          <Tab
            icon={
              <Tooltip title={t('Participants')}>
                <GroupIcon />
              </Tooltip>
            }
            aria-label="participants"
          />
          <Tab
            icon={
              <Tooltip title={t('Collaboration')}>
                <QuestionAnswerIcon />
              </Tooltip>
            }
            aria-label="collaboration"
          />
          <Tab
            icon={
              <Tooltip title={t('Audit Trail')}>
                <HistoryIcon />
              </Tooltip>
            }
            value={2}
            aria-label="audit trail"
          />
          <Tab
            icon={
              <Tooltip title={t('Setting')}>
                <SettingIcon />
              </Tooltip>
            }
            aria-label="setting"
          />
        </Tabs>
      </Box>
      {value === 0 && (
        <Box sx={{ flex: "1 1", overflowY: "auto", height: "100%" }}>
          <ParticipantSettings />
        </Box>
      )}
      {value === 1 && (
        <Box sx={{ flex: "1 1", overflowY: "auto", height: "100%" }}>
          <Collaboration />
        </Box>
      )}
      {value === 2 && (
        <Box sx={{ flex: "1 1", overflowY: "auto", height: "100%" }}>
          <AuditTrail />
        </Box>
      )}
      {value === 3 && (
        <Box sx={{ flex: "1 1", overflowY: "auto", height: "100%" }}>
          <DocumentSettings />
        </Box>
      )}
    </>
  );
};
