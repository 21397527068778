import React, { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useErrorContext } from "../../context/ErrorContext";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ErrorSnackbar = forwardRef((_, ref) => {
  const { errorMessage, clearError } = useErrorContext();

  const handleClose = (
    event?: React.SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    clearError();
  };

  return (
    <Snackbar
      open={!!errorMessage}
      autoHideDuration={6000}
      onClose={handleClose as any}
    >
      <div>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </div>
    </Snackbar>
  );
});

export default ErrorSnackbar;
