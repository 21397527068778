import React, { useCallback, useEffect, useMemo } from "react";
import {
  DataGridPro,
  GridRowParams,
  GridActionsCellItem,
  GridSortItem,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { Box, Button, Container, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useDeleteApi, useFetchApi } from "../../../hooks/useApi";
import { URLS } from "../../../constants/urls";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { DeleteWorkspaceDialog } from "./components/DeleteWorkspaceDialog";
import { useErrorContext } from "../../../context/ErrorContext";
import { useSuccessContext } from "../../../context/SuccessContext";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const PAGE_SIZE_OPTIONS = [5, 10];

type WorkSpaceList = {
  total_count: number;
  items: Array<Api.Workspace>;
};

export const Workspaces = () => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [modifiedSortModel, setModifiedSortModel] = React.useState<
    GridSortItem[]
  >([]);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [rowId, setRowId] = React.useState<number | undefined>();

  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: checkWalkthroughData, mutate: mutateCheckCompanyWorkspace } =
    useFetchApi<Api.CheckWorkspaceCompanyResponse>(
      URLS.check_company_workspace,
      undefined,
      { revalidateOnFocus: false }
    );

  const {
    data: rows,
    isLoading,
    mutate,
  } = useFetchApi<WorkSpaceList>(
    URLS.workspace_list,
    {
      page: String(page),
      page_size: String(pageSize),
      search: searchValue,
      sort_field_list: JSON.stringify(modifiedSortModel),
    },
    { revalidateOnFocus: false }
  );

  const {
    deleteData: deleteData,
    isLoading: isLoadingDelete,
    error: errorDelete,
  } = useDeleteApi<any>(URLS.workspaces);

  useEffect(() => {
    if (errorDelete) {
      showError(errorDelete.message);
    }
  }, [errorDelete]);

  useEffect(() => {
    if (!checkWalkthroughData?.workspace_status) {
      localStorage.setItem("checkedCompanyWorkspace", "false");
      navigate("/walkthrough");
    }
  }, [checkWalkthroughData]);

  const fetchData = useCallback(() => {
    mutate();
  }, [page, pageSize, searchValue, modifiedSortModel]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const editClick = (id: number) => {
    navigate(`/admin/workspaces/${id}`);
  };
  const deleteClick = (id: number) => {
    setDeleteDialog(true);
    setRowId(id);
  };

  const deleteWorkspace = async () => {
    if (rowId) {
      const response = await deleteData(String(rowId));

      if (response) {
        mutate();
        mutateCheckCompanyWorkspace();
        setDeleteDialog(false);
        showSuccess(t('Workspace deleted successfully'));
      }
    }
  };

  const columns = React.useMemo<GridColDef<Api.Workspace>[]>(
    () => [
      { field: "id", headerName: t('ID'), disableColumnMenu: true, flex: 1 },
      { field: "name", headerName: t('Name'), disableColumnMenu: true, flex: 1 },
      {
        field: "description",
        headerName: t('Description'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "market",
        headerName: t('Market'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "type",
        headerName: t('Type'),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => editClick(Number(params.id))}
            label={t('Edit')}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => deleteClick(Number(params.id))}
            label={t('Delete')}
            showInMenu
          />,
        ],
      },
    ],
    [editClick, deleteClick]
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <TextField
            id="input-with-icon-textfield"
            label={t('Search')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2, maxWidth: 300 }}
            fullWidth
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Box>
          <Button
            onClick={() => navigate("/workspace/create")}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddIcon />}
          >
            {t('Add Workspace')}
          </Button>
        </Box>
      </Box>

      <DataGridPro
        rows={rows?.items ?? []}
        rowCount={rows?.total_count ?? 0}
        columns={columns}
        rowSelection={false}
        initialState={{
          pagination: {
            paginationModel: { page: page, pageSize: pageSize },
          },
        }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        pagination
        onSortModelChange={(model) => {
          setModifiedSortModel(model);
        }}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
      <DeleteWorkspaceDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        deleteWorkspace={deleteWorkspace}
        isDeleteButtonDisable={isLoadingDelete}
      />
    </Box>
  );
};
