import { Box, Modal } from "@mui/material";
import React, { FC } from "react";
import { KeyedMutator } from "swr";
import { AddUpdateCompanyForm } from "../CounterPartyWalkthrough/Forms/AddUpdateCompanyForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  id?: number;
  mutate: KeyedMutator<any>;
};

export const EditCompanyModal: FC<Props> = ({ id, open, onClose, mutate }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <AddUpdateCompanyForm id={id} mutate={mutate} onClose={onClose} />
      </Box>
    </Modal>
  );
};
