import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { FC } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import theme from "../../../../../../../theme";
import { grey, red, green } from "@mui/material/colors";
import { formatDate } from "../../../../../../../utils";
import { useAuth } from "../../../../../../../context/AuthContext";
import { useDocumentState } from "../../../../../../../stores/document";
import { useTranslation } from "react-i18next";

type Props = {
  id: number;
  name: string;
  role: string;
  documentUserId: number;
  setOpenModal: (value: boolean, id: number, documentUserId: number) => void;
  signOrder?: number;
  is_signed?: boolean;
  signed_time?: string;
  declined_time?: string;
};

export const UserBox: FC<Props> = ({
  id,
  name,
  role,
  setOpenModal,
  documentUserId,
  signOrder,
  is_signed = false,
  signed_time,
  declined_time
}) => {
  const { isExternalUser } = useAuth();
  const { status } = useDocumentState();
  const { t } = useTranslation();

  return (
    <Box sx={{ minHeight: "50px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: theme.spacing(2),
          "&:hover": {
            background: grey[50],
            cursor: "pointer",
          },
        }}
        onClick={() => (!isExternalUser && status !== 'pending') && setOpenModal(true, id, documentUserId)}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2">{name}</Typography>
          <Typography variant="caption" color="GrayText">
            {role}
          </Typography>
          {signed_time && (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography variant="caption" color="GrayText" sx={{alignSelf: 'center', lineHeight: 0}}>
                {t('Signed with E-sign')} {formatDate(signed_time)}
              </Typography>
              <CheckCircleIcon sx={{ color: green[500], fontSize: '13.6px', marginRight: theme.spacing(1), marginLeft: theme.spacing(1) }} />
            </Box>
          )}
          {declined_time && (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography variant="caption" color="GrayText" sx={{alignSelf: 'center', lineHeight: 0}}>
                {t('Declined')} {formatDate(declined_time)}
              </Typography>
              <CancelIcon sx={{ color: red[500], fontSize: '13.6px', marginRight: theme.spacing(1), marginLeft: theme.spacing(1) }} />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="caption" color="GrayText">
            {`(${t('Sign Order')}: ${signOrder})`}
          </Typography>
          <IconButton size="small" disabled={isExternalUser || status === 'pending'}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ m: 0, p: 0 }} />
    </Box>
  );
};
