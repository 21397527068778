import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import theme from "../../../theme";
import PostAddIcon from "@mui/icons-material/PostAdd";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

interface CreateDocumentModalProps {
  onFileUpload: (file: File) => void;
}

export const CreateDocumentModal: React.FC<CreateDocumentModalProps> = ({
  onFileUpload,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [fileUploaded, setFileUploaded] = useState(false);
  const { t } = useTranslation();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileUpload(file);
      setFileUploaded(true); // Set fileUploaded to true after successful upload
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isDialogOpen && !fileUploaded} // Only open if dialog is open and file is not uploaded
      disableEscapeKeyDown
    >
      <DialogTitle id="responsive-dialog-title" sx={{ textAlign: "center", position: "relative" }}>
        {t('Create a new document')}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
          {t('Create your custom document from scratch or upload a DOCX')}
        </Typography>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Card
            sx={{
              p: 3,
              cursor: "pointer",
              width: "200px",
              "&:hover": {
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              },
            }}
            elevation={2}
            onClick={handleCloseDialog} // Close dialog when clicked
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PostAddIcon fontSize="large" />
              <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                {t('Copy paste your text and click review')}
              </Typography>
            </Box>
          </Card>
          <Card
            sx={{
              p: 3,
              cursor: "pointer",
              width: "175px",
              backgroundColor: "#E5E4E2",
              "&:hover": {
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              },
              border: "1px dashed gray",
            }}
            elevation={3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UploadFileIcon fontSize="large" />
              <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                {t('Upload')} (Docx*)
                <br />
              </Typography>
              <Button
                variant="contained"
                component="label"
                sx={{
                  mt: 2,
                  display: "block",
                }}
              >
                {t('Choose File')}
                <input
                  type="file"
                  accept=".docx"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
          </Card>
        </Box>
        <Typography variant="caption">
          {t('To reuse a document')}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDocumentModal;
