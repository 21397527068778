import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  duplicateDocument: () => void;
  isDeleteButtonDisable?: boolean;
};

export const DuplicateDocumentDialog: FC<Props> = ({
  open,
  setOpen,
  duplicateDocument,
  isDeleteButtonDisable,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('Duplicate this document?')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('This action cannot be undone.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>
        <Button
          onClick={() => {
            setOpen(false);
            duplicateDocument();
          }}
          color="warning"
          autoFocus
          disabled={isDeleteButtonDisable}
        >
          {t('Duplicate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
