import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { countries } from "../../constants/countries";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useFetchApi, usePostApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { useErrorContext } from "../../context/ErrorContext";
import { useTranslation } from "react-i18next";

type Props = {
  setStep: (step: number) => void;
};

const schemaCompanyForm = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().required(),
  registration_number: Joi.string().required(),
  country: Joi.string().required(),
});

export const CompanyForm: FC<Props> = ({ setStep }) => {
  const { data: fetchData } = useFetchApi<Api.CheckWorkspaceCompanyResponse>(
    URLS.check_company_workspace
  );

  const { postData, isLoading, error } = usePostApi(URLS.companies);
  const { showError } = useErrorContext();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.CompanyForm>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
      registration_number: "",
      country: "",
    },

    resolver: joiResolver(schemaCompanyForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (fetchData?.company_id) {
      setStep(1);
    }
  }, [fetchData]);

  const onSubmit = async (data: Api.CompanyForm) => {
    const response = await postData(data);
    if (response) {
      setStep(1);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{ mt: 1 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="name"
                  fullWidth
                  label={t('Company Name')}
                  autoComplete="name"
                  autoFocus
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="description"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  size="small"
                  id="description"
                  label={t('Description')}
                  autoComplete="description"
                  fullWidth
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="registration_number"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextField
                  required
                  id="registration_number"
                  size="small"
                  label={t('Registration Number')}
                  autoComplete="registration_number"
                  fullWidth
                  error={Boolean(errors.registration_number)}
                  helperText={errors.registration_number?.message}
                  {...field}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="country"
            control={control}
            render={({ field, formState: { errors } }) => (
              <div>
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  onChange={(e, data) => {
                    field.onChange(data?.label);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...field}
                      {...params}
                      label={t('Choose a country')}
                      id="country"
                      name="country"
                      fullWidth
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      error={Boolean(errors.country)}
                      helperText={errors.country?.message}
                    />
                  )}
                />
              </div>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <div>
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 1, mr: 1 }}
            disabled={isLoading}
          >
            {t('Continue')}
          </Button>
          <Button disabled={true} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </div>
      </Box>
    </Box>
  );
};
