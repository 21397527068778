import React, { useEffect, useMemo } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useFetchApi, usePatchApi } from "../../../hooks/useApi";
import { URLS } from "../../../constants/urls";
import { useErrorContext } from "../../../context/ErrorContext";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { countries } from "../../../constants/countries";
import { useSuccessContext } from "../../../context/SuccessContext";
import { useTranslation } from "react-i18next";

const schemaCompanyForm = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().required(),
  registration_number: Joi.string().required(),
  country: Joi.string().required(),
  id: Joi.optional(),
  logo_url: Joi.optional(),
  subscription_type: Joi.optional(),
}).unknown(true);

export const AccountOverview = () => {
  const { user } = useAuth();
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const { t } = useTranslation();
  const {
    data: companyData,
    mutate,
    isLoading,
    error,
  } = useFetchApi<Api.Company>(
    user?.company_id ? `${URLS.companies}/${user?.company_id}` : undefined,
    undefined,
    {
      revalidateOnFocus: false,
    }
  );

  const {
    patchData,
    isLoading: isLoadingPatch,
    error: errorPatch,
  } = usePatchApi(`${URLS.companies}/${user?.company_id}`);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Api.CompanyForm>({
    mode: "onSubmit",
    defaultValues: companyData,
    resolver: joiResolver(schemaCompanyForm),
  });

  useEffect(() => {
    if (companyData) {
      reset(companyData);
    }
  }, [companyData]);

  useEffect(() => {
    if (error || errorPatch) {
      showError(error.message ?? errorPatch?.message);
    }
  }, [error]);

  const onSubmit = async (data: Api.CompanyForm) => {
    const response = await patchData(data);
    if (response) {
      await mutate();
      showSuccess("Company updated successfully");
    }
  };

  if (isLoading || isLoadingPatch)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || isLoadingPatch}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1, mb: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          item={true}
          container
          spacing={1}
          rowSpacing={3}
          columnSpacing={3}
          sm={6}
          xs={12}
        >
          <Grid item={true} xs={12}>
            <Typography variant="h6" gutterBottom component="div">
              {t('Account')}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Controller
              name="name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="name"
                    fullWidth
                    label={t('Company Name')}
                    autoComplete="name"
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Controller
              name="description"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="description"
                    label={t('Description')}
                    autoComplete="description"
                    fullWidth
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Controller
              name="registration_number"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    id="registration_number"
                    size="small"
                    label={t('Registration Number')}
                    autoComplete="registration_number"
                    fullWidth
                    error={Boolean(errors.registration_number)}
                    helperText={errors.registration_number?.message}
                    {...field}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Controller
              name="country"
              control={control}
              defaultValue={"sd" ?? ""}
              render={({ field, formState: { errors } }) => (
                <div>
                  <Autocomplete
                    id="country-select-demo"
                    options={countries}
                    autoHighlight
                    defaultValue={countries.find(
                      (item) => item.label === companyData?.country
                    )}
                    inputValue={field.value ?? ""}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, data) => {
                      field.onChange(data?.label);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        label={t('Choose a country')}
                        id="country"
                        name="country"
                        fullWidth
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        error={Boolean(errors.country)}
                        helperText={errors.country?.message}
                      />
                    )}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} ml="auto">
            <Box sx={{ justifyContent: "end", display: "flex", mt: 1, mb: 1 }}>
              <Button sx={{ mr: 1 }} onClick={() => reset()}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoadingPatch}
              >
                {t('Update')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ mt: 1, mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom component="div">
          {t('Account plan')}
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ textTransform: "capitalize" }}
        >
          {t(`${companyData?.subscription_type}`)}
        </Typography>
        <Typography variant="caption" gutterBottom component="div">
          {t('Upgrade your plan to create truly digital documents and workflows')}{" "}
        </Typography>
        <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
          {t('Compare Plans')}
        </Button>
      </Box>
    </>
  );
};
