import {
  Avatar,
  ChatContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { Box, Paper } from "@mui/material";
import chatAgreemind from "../../images/logo.png";
import React, { useEffect, useState } from "react";
import { usePostApi } from "../../hooks/useApi";
import { URLS } from "../../constants/urls";
import { useErrorContext } from "../../context/ErrorContext";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useTranslation } from "react-i18next";

export const AiChat = () => {
  const { t } = useTranslation();
  const [chatMessages, setChatMessages] = useState([
    {
      message: t('Hello, I am Maya. How can i help you?'),
      sender: "ChatGPT",
      direction: "incoming",
      position: "single",
    },
  ]);

  const { showError } = useErrorContext();

  const { postData: postAiChat, isLoading, error } = usePostApi(URLS.ai_chat);

  useEffect(() => {
    if (error) {
      showError(error?.message ?? "");
    }
  }, [error]);

  const handleUserMessage = async (userMessage: string) => {
    // Get the chat history
    var chatHistory = [];
    for (const element of chatMessages) {
      if (element.sender === "user") {
        chatHistory.push({ role: "user", message: element.message });
      } else if (element.sender === "ChatGPT") {
        chatHistory.push({ role: "assistant", message: element.message });
      }
    }

    const sendData = {
      message: userMessage,
      chat_history: chatHistory,
    };

    // Create a new user message object
    const newUserMessage = {
      message: userMessage,
      sender: "user",
      direction: "outgoing",
      position: "single",
    };

    // Update chat messages state with the new user message
    const updatedChatMessages = [...chatMessages, newUserMessage];
    setChatMessages(updatedChatMessages);

    const response = await postAiChat(sendData);

    if (response) {
      setChatMessages([
        ...updatedChatMessages,
        {
          message: response.reply,
          sender: "ChatGPT",
          direction: "incoming",
          position: "single",
        },
      ]);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 5,
        borderRadius: 3,
        height: "99%",
      }}
    >
      <ChatContainer style={{ maxHeight: "90%", overflow: "auto" }}>
        <MessageList
          typingIndicator={
            isLoading ? <TypingIndicator content="Maya is typing" /> : null
          }
        >
          {chatMessages.map((message, i) => {
            return (
              <Message
                key={i}
                model={message as any}
                style={
                  message.sender === "ChatGPT" ? { textAlign: "left" } : {}
                }
              >
                {message.sender === "ChatGPT" ? (
                  <Avatar 
                    src={chatAgreemind}
                    name={message.sender}
                    style={{
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      objectFit: 'contain'
                    }}
                  >
                    <img
                      src={chatAgreemind}
                      alt={message.sender}
                      style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    />
                  </Avatar>
                ) : (
                  ""
                )}
              </Message>
            );
          })}
        </MessageList>
        <MessageInput attachButton={false} onSend={handleUserMessage} />
      </ChatContainer>
    </Paper>
  );
};
