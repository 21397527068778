import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useErrorContext } from "../../../../../context/ErrorContext";
import { useSuccessContext } from "../../../../../context/SuccessContext";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { usePostApi } from "../../../../../hooks/useApi";
import { URLS } from "../../../../../constants/urls";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import styled from "@emotion/styled";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../../../../../theme";
import { useAuth } from "../../../../../context/AuthContext";
import { useTranslation } from "react-i18next";

var mammoth = require("mammoth/mammoth.browser");


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.5px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const StyledFileUploader = styled("div")({
  width: "100%",
  height: "100%",
  minHeight: "200px",
  maxHeight: "%100",
  maxWidth: "%100 !important",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderStyle: "dashed",
  borderColor: "lightgray",
  boxShadow: "none",
  borderRadius: "5px",
  backgroundColor: "rgb(243, 243, 245)",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgb(230, 230, 230)",
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  isBlank?: boolean;
};

type FormTypes = {
  name: string;
  description: string;
};

const schemaAddDocumentForm = Joi.object({
  description: Joi.optional(),
  name: Joi.optional(),
}).unknown(true);

export const NewDocumentModal: FC<Props> = ({ open, onClose, isBlank }) => {
  const { showError } = useErrorContext();
  const { showSuccess } = useSuccessContext();
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string>("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      setFile(file);
      setFileName(file.name);

      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { "application/pdf": [], "application/docx": [".docx", ".doc"] },
  });

  const {
    postFormData,
    isLoading: isLoadingPost,
    error,
  } = usePostApi(`${URLS.document}`);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: joiResolver(schemaAddDocumentForm),
  });

  useEffect(() => {
    if (error) {
      showError(error.message);
    }
  }, [error]);

  const convertDocxToHtml = async (file: File): Promise<string> => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.convertToHtml({ arrayBuffer });
    return result.value;
  };

  const onSubmit = async (data: FormTypes) => {
    let fileToUpload;
    
    if (!isBlank) {
      const fileNew = acceptedFiles[0];
      const htmlContent = await convertDocxToHtml(fileNew);
  
      // Convert the HTML content to a Blob and then to a File
      const htmlBlob = new Blob([htmlContent], { type: "text/html" });
      fileToUpload = new File([htmlBlob], `${data.name}.html`, { type: "text/html" });
    } else {
      fileToUpload = new File([], data.name, { type: "text/html" });
    }
  
    const response = await postFormData({
      ...data,
      workspace_id: user?.workspace_id,
      type: "collaboration",
      file: fileToUpload,
    });
  
    if (response) {
      reset();
      showSuccess(t('Document added successfully'));
      onClose();
      navigate(`/document/${response.id}`, { state: { isFromCreateNewDocument: true } });
    }
  };
  

  if (isLoadingPost) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingPost}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {isBlank ? t('Save Document') : t('Upload Document')}
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <Controller
              name="name"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    required
                    size="small"
                    id="name"
                    fullWidth
                    label={t('Document Name')}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    {...field}
                  />
                </div>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field, formState: { errors } }) => (
                <div>
                  <TextField
                    size="small"
                    id="description"
                    fullWidth
                    label={t('Document Description')}
                    {...field}
                  />
                </div>
              )}
            />
            {!isBlank &&
              (file ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "0.5px solid lightgray",
                    borderRadius: "5px",
                    paddingLeft: 2,
                  }}
                >
                  <Typography variant="subtitle2" color="primary">
                    {fileName}
                  </Typography>
                  <Button
                    onClick={() => {
                      setFile(undefined);
                      setFileName("");
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              ) : (
                <StyledFileUploader {...getRootProps({ refKey: "innerRef" })}>
                  <input {...getInputProps()} />
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <CloudUploadIcon
                      fontSize="large"
                      color="primary"
                      sx={{ minWidth: "44px", minHeight: "44px" }}
                    />
                    <Typography
                      variant="subtitle2"
                      color={theme.palette.primary.main}
                    >
                      {t('Drag and Drop or Click to Upload')}
                    </Typography>
                  </Box>
                </StyledFileUploader>
              ))}
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Box>
              <Button onClick={onClose} sx={{ mt: 1, mr: 1 }}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 1 }}
                disabled={isLoadingPost || (!isBlank && !file)}
              >
                {isBlank ? t('Save Document') : t('Upload')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
